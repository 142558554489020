import React, { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Drawer, Button, DatePicker } from 'antd';
import PaginatedList from './PaginatedList';
import LockClosure from './LockClosure';

dayjs.extend(customParse).extend(utc);

export default function LockClosures(props) {
    const {
        fetchData,
        locks,
        marina,
        hasPermission,
        location: { search = {} },
    } = props;
    const [full, page, term] = search.match(/page=(\d+)(?:&query=([\w/-]+))?/) || ['', 1, ''];
    const [selectedDate, setSelectedDate] = useState(null);
    const [query, setQuery] = useState('');
    const [editVisible, setEditVisible] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const isMilford = useMemo(() => {
        if (!marina) return false;
        return marina.canonical_marina_name === 'milford';
    }, [marina]);

    const showEdit = (record) => (e) => {
        e.preventDefault();
        setCurrentRecord(record);
        setEditVisible(true);
        setDrawerVisible(true);
    };
    const closeDrawer = (refresh = false) => {
        setDrawerVisible(false);
        if (editVisible) {
            setEditVisible(false);
            setCurrentRecord(null);
        }
        if (refresh) {
            fetchData(+page, term);
        }
    };
    const showNew = (e) => {
        e.preventDefault();
        setDrawerVisible(true);
    };
    const updateQuery = (val) => {
        setSelectedDate(val);
        setQuery(val ? val.format('DD/MM/YYYY') : '');
    };

    const columns = [
        {
            title: 'Day',
            dataIndex: 'day',
        },
        {
            title: 'Date',
            dataIndex: 'lock_date',
            render: (text) => dayjs(text).format('DD/MM/YYYY'),
        },
        {
            title: !isMilford ? 'Lock Open' : 'Lock In',
            dataIndex: !isMilford ? 'lock_open' : 'lock_in',
        },
        {
            title: !isMilford ? 'Lock Close' : 'Lock Out',
            dataIndex: !isMilford ? 'lock_close' : 'lock_out',
        },
        {
            title: !isMilford ? 'Stop Gate Open' : 'Free flow start',
            dataIndex: !isMilford ? 'lock_1_open' : 'free_flow_start',
        },
        {
            title: !isMilford ? 'Stop Gate Close' : 'Free flow end',
            dataIndex: !isMilford ? 'lock_1_close' : 'free_flow_end',
        },
    ];

    if (!isMilford) {
        columns.push({
            title: 'Notes',
            dataIndex: 'notes',
            render: (text) => text || '-',
        });
    } else
        columns.push({
            title: 'High water',
            dataIndex: 'high_water',
        });

    if (hasPermission('edit.locks') && !isMilford) {
        columns.push({
            title: (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <span>Actions</span>
                </div>
            ),
            key: 'actions',
            render: (text, record) => (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <a onClick={showEdit({ ...record })}>
                        <EyeOutlined />
                    </a>
                </div>
            ),
        });
    }

    return (
        <>
            <div
                style={{
                    float: 'right',
                    marginBottom: 8,
                    position: 'absolute',
                    top: 16,
                    right: 24,
                }}
            >
                <DatePicker
                    format="DD/MM/YYYY"
                    onChange={updateQuery}
                    style={{ marginRight: 8 }}
                    placeholder="View individual rates"
                />
                {hasPermission('add.locks') && !isMilford ? (
                    <Button type="primary" onClick={showNew}>
                        <PlusOutlined /> Amend Lock Closure
                    </Button>
                ) : null}
            </div>
            <PaginatedList
                fetchData={fetchData}
                columns={columns}
                source={locks.data}
                meta={locks.meta}
                loading={locks.isFetching}
                query={query}
                {...props}
            />
            {(hasPermission('edit.locks') || hasPermission('add.locks')) && !isMilford ? (
                <Drawer
                    title={currentRecord ? `Edit ${currentRecord.lock_date} Lock info` : 'Amend New Lock Closure'}
                    width={500}
                    onClose={closeDrawer}
                    visible={drawerVisible}
                    maskClosable={false}
                    destroyOnClose
                >
                    <LockClosure
                        selectedDate={selectedDate}
                        onFinish={closeDrawer}
                        isEdit={editVisible}
                        currentRecord={currentRecord}
                        onCancel={closeDrawer}
                        hasPermission={hasPermission}
                    />
                </Drawer>
            ) : null}
        </>
    );
}
