import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchLocks, fetchMarina } from '../../actions';
import Locks from '../shared/LockClosures';
import { permissionChecker } from '../../utils/permission';

function LocksContainer(props) {
    const {
        setExtras,
        setTitle,
        locks,
        fetchLocks,
        fetchMarina,
        role,
        scope,
        permissions,
        match: {
            params: { marina: marinaId },
        },
        shouldFetch,
        id,
        marina,
    } = props;

    useEffect(() => {
        setExtras([null]);
    }, [setExtras]);
    useEffect(() => {
        setTitle('Lock Closures');
    }, [setTitle]);

    useEffect(() => {
        if (shouldFetch && id) {
            fetchMarina(id);
        }
    }, [shouldFetch, id]);

    const fetchData = (page, query) => {
        fetchLocks(page, query, marinaId);
    };
    const hasPermission = permissionChecker(role, permissions, scope);

    const prosToPass = {
        marina,
        locks,
        fetchData,
        headerRequired: false,
        hasPermission,
        ...props,
    };

    return <Locks {...prosToPass} />;
}

const mapStateToProps = (state, ownProps) => {
    const { marina: marinaId } = ownProps.match.params;
    const {
        locks,
        me: { role, permissions, scope },
        marina: { profile: marina },
    } = state;

    return {
        ...ownProps,
        locks,
        role,
        scope,
        permissions,
        id: +marinaId,
        shouldFetch: !marina.id || marina.id !== +marinaId,
        marina,
    };
};

export default connect(mapStateToProps, {
    fetchLocks,
    fetchMarina,
})(LocksContainer);
