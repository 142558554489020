/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Drawer, Button, Row, Col, PageHeader,
} from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import Rates from '../shared/Rates';
import { permissionChecker } from '../../utils/permission';

import {
    fetchRateFieldCategories,
    updateRateField,
    fetchRateFields,
    fetchMarina,
    computeRate,
} from '../../actions';
import DefaultCalculator from './CalculateRate';
import BristolCalculator from './CalculateBristolRate';
import SwanseaCalculator from './CalculateSwanseaRate';

function RatesContainer(props) {
    const [drawerVisible, setDrawerVisible] = useState(false);

    const {
        marina: {
            id,
            canonical_marina_name,
            isFetching,
            rates_freezed: ratesFreezed,
            rate_prompt_definition: promptDef,
        },
        computed,
        computeRate,
        fetchMarina,
        marinaId,
        fetchRateFieldCategories,
        fetchRateFields,
        permissions,
        role,
        history,
    } = props;
    const hasPermission = permissionChecker(role, permissions);

    useEffect(() => {
        if (!id) fetchMarina(marinaId);
        if (
            hasPermission('view.rates')
            || hasPermission('edit.rates')
            || role === 'admin'
        ) {
            fetchRateFields();
            fetchRateFieldCategories();
        }
    }, []);

    const calculateRate = (fields) => {
        computeRate(fields);
    };

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const onCancel = () => {
        setDrawerVisible(false);
    };

    const onRequestComplete = () => {
        fetchRateFields();
    };

    if (!id || isFetching) return null;

    const extra = [];
    // eslint-disable-next-line no-constant-condition
    if (ratesFreezed && hasPermission('calculate.rates') && false) {
        extra.push(
            <Button key="rate" type="primary" onClick={showDrawer}>
                <CalculatorOutlined />
                {' '}
                Calculate Rates
            </Button>,
        );
    }

    let Calculator = null;
    switch (canonical_marina_name) {
    case 'bristol':
        Calculator = BristolCalculator;
        break;
    case 'swansea':
        Calculator = SwanseaCalculator;
        break;
    default:
        Calculator = DefaultCalculator;
    }

    if (hasPermission('view.rates') || hasPermission('edit.rates')) {
        return (
            <Rates
                {...props}
                {...{ extra, onRequestComplete, hasPermission }}
            >
                {hasPermission('calculate.rates') ? (
                    <Drawer
                        title="Calculate Rates"
                        width="45%"
                        onClose={onCancel}
                        visible={drawerVisible}
                        maskClosable={false}
                        destroyOnClose
                    >
                        {ratesFreezed ? (
                            <Calculator
                                {...props}
                                def={promptDef}
                                onFinish={calculateRate}
                                onCancel={onCancel}
                                computed={computed}
                            />
                        ) : null}
                    </Drawer>
                ) : null}
            </Rates>
        );
    }

    // just show the rate calculation form if the user only has calculation permission
    if (hasPermission('calculate.rates')) {
        return (
            <PageHeader
                title="Calculate Rates"
                className="rates-page-header"
                onBack={() => history.goBack()}
            >
                <Row gutter={[16, 16]}>
                    <Col span={10} offset={4}>
                        <Calculator
                            {...props}
                            def={promptDef}
                            onFinish={calculateRate}
                            onCancel={onCancel}
                            computed={computed}
                        />
                    </Col>
                </Row>
            </PageHeader>
        );
    }

    return null;
}

function mapStateToProps(state) {
    const {
        rate: {
            categories, fields, field, category, calculate,
        },
    } = state;
    const { me: { role, permissions, marina_id } } = state;

    return {
        marinaId: marina_id,
        role,
        permissions,
        fields,
        categories,
        category,
        field,
        marina: state.marina.profile,
        computed: calculate,
        canAddFields: false,
    };
}
export default connect(mapStateToProps, {
    fetchRateFields,
    fetchMarina,
    fetchRateFieldCategories,
    updateRateField,
    computeRate,
})(RatesContainer);
