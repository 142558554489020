import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Drawer, Button, DatePicker, Form, Row, Col, Input, InputNumber, Divider, Checkbox, message, Switch, Typography } from 'antd';
import { BoldOutlined, PlusOutlined, SaveOutlined, EyeOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
const { Text } = Typography;

class Swings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            day: '',
            name: '',
            swings: [],
            events: [],
            drawerVisible: false,
            drawerBookingVisible: false,
            editVisible: false,
            selectedDate: new Date(),
            swingTimeSlots: [],
            swingBookings: [],
            doesSlotAssigned: false,
            selectAll: false,
            currentDate: new Date(),
            currentView: 'month',
            displayedDateRange: {},

            airDraft: null,
            isSaving: false,
        };
        // bind
        // this.handleChange = this.handleChange.bind(this);
        // this.handleDayChange = this.handleDayChange.bind(this);

        // bind handleSubmit method
        // this.handleSubmit = this.handleSubmit.bind(this);

        // this.renderEvents = this.renderEvents.bind(this);

        this.getSwings = this.getSwings.bind(this);

        // this.setCurrentDate = this.setCurrentDate.bind(this);

        this.showEdit = this.showEdit.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.openDrawer = this.openDrawer.bind(this);
        this.openEventDrawer = this.openEventDrawer.bind(this);

        this.closeBookingDrawer = this.closeBookingDrawer.bind(this);
        this.openBookingDrawer = this.openBookingDrawer.bind(this);

        this.clickSwingTimeSlot = this.clickSwingTimeSlot.bind(this);
        this.updateSwingTimeSlots = this.updateSwingTimeSlots.bind(this);

        this.setCurrentDate = this.setCurrentDate.bind(this);
        this.setCurrentView = this.setCurrentView.bind(this);

        this.saveAirDraft = this.saveAirDraft.bind(this);
        this.getAirDraft = this.getAirDraft.bind(this);

        this.cancelSwingBooking = this.cancelSwingBooking.bind(this);
    }

    showEdit(e) {
        e.preventDefault();
        this.setState({
            editVisible: true,
            drawerVisible: true
        });
    };
    closeDrawer() {
        this.setState({
            drawerVisible: false
        });
        if (this.state.editVisible) {
            this.setState({
                editVisible: false,
                drawerVisible: false
            });
        }
    };
    openDrawer(e) {
        this.setState({
            drawerVisible: true,
            selectAll: false,
            selectedDate: moment(e.start).format("YYYY-MM-DD")

        });
        console.log(this.state.selectedDate);
        this.getSwingTimeSlots();
    };
    openEventDrawer(val) {
        let start = val.start;
        console.log(moment(start).format("YYYY-MM-DD"));
        this.setState({
            drawerVisible: true,
            selectAll: false,
            selectedDate: moment(start).format("YYYY-MM-DD")

        });
        console.log(this.state.selectedDate);
        this.getSwingTimeSlots();
    };

    closeBookingDrawer() {
        this.setState({
            drawerBookingVisible: false
        });
    };
    openBookingDrawer(e) {
        this.setState({
            drawerBookingVisible: true,
        });
        this.getSwingBookings();
    };

    saveAirDraft(val) {
        this.setState({
            isSaving: true,
        });
        axios
            .post('/api/rates/save_prince_street_air_draft', val)
            .then(response => {
                response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);
                this.getAirDraft();
                this.setState({
                    isSaving: false,
                });
            });
    }

    getAirDraft() {
        return axios.get('/api/rates/get_prince_street_air_draft').then((
            response
        ) => {
            if (response.data) {
                this.setState({
                    airDraft: parseFloat(response.data.value)
                });
                console.log(this.state.airDraft + 'mmm')
            }
        });
    }

    // clickDate(e) {
    //     let selectedDate = moment(e.start).format("YYYY-MM-DD");
    //     console.log(selectedDate)

    //     axios
    //         .post('/api/swings/update_swing_open', {
    //             day: selectedDate,
    //             is_opened: true,
    //         })
    //         .then(response => {
    //             console.log('from handle submit', response);
    //         });
    // }


    // handle change
    // handleChange(e) {
    //     this.setState({
    //         name: e.target.value
    //     });
    //     console.log('onChange', this.state.name);
    // }
    // handleDayChange(e) {
    //     console.log(e)
    //     this.setState({
    //         day: e
    //     });
    //     console.log('onChange', this.state.day);
    // }

    // create handleSubmit method right after handleChange method
    // handleSubmit(e) {
    //     // stop browser's default behaviour of reloading on form submit
    //     e.preventDefault();
    //     axios
    //         .post('/api/swings/store', {
    //             name: this.state.name,
    //             day: this.state.day,
    //         })
    //         .then(response => {
    //             console.log('from handle submit', response);
    //             // set state
    //             this.setState({
    //                 events: [response.data, ...this.state.events]
    //             });
    //             // then clear the value of textarea
    //             this.setState({
    //                 name: '',
    //                 day: null,
    //             });
    //         });
    // }

    // render events
    // renderEvents() {
    //     return this.state.events.map(event => (
    //         <div key={event.id} className="media">
    //             <div className="media-body">
    //                 <p>{event.day}</p>
    //             </div>
    //         </div>
    //     ));
    // }

    getSwingTimeSlots() {
        let doesSlotAssigned = false;
        let swingTimeSlots = [
            { 'day': null, 'opened_time': '08:30', 'is_opened': false },
            { 'day': null, 'opened_time': '09:30', 'is_opened': false },
            { 'day': null, 'opened_time': '10:30', 'is_opened': false },
            { 'day': null, 'opened_time': '11:30', 'is_opened': false },
            { 'day': null, 'opened_time': '12:30', 'is_opened': false },
            { 'day': null, 'opened_time': '13:30', 'is_opened': false },
            { 'day': null, 'opened_time': '14:30', 'is_opened': false },
            { 'day': null, 'opened_time': '15:30', 'is_opened': false },
            { 'day': null, 'opened_time': '16:30', 'is_opened': false },
            { 'day': null, 'opened_time': '17:30', 'is_opened': false },
            { 'day': null, 'opened_time': '18:30', 'is_opened': false },
            { 'day': null, 'opened_time': '19:30', 'is_opened': false },
            { 'day': null, 'opened_time': '20:30', 'is_opened': false },
            { 'day': null, 'opened_time': '21:30', 'is_opened': false }
        ];
        return axios.post('/api/swings/get_swing_time_slots', {
            day: moment(this.state.selectedDate).format("YYYY-MM-DD")
        }).then((
            response
        ) => {
            if (response.data.length) {
                swingTimeSlots = response.data;
                doesSlotAssigned = true;
            }
            this.setState({
                swingTimeSlots: swingTimeSlots,
                doesSlotAssigned: doesSlotAssigned
            });
            // console.log(this.state.swingTimeSlots)
        });
    }

    // navigateCalendar() {
    //     console.log(this.state.selectedDate);
    // }

    getSwings() {

        let start = moment(this.state.currentDate).startOf(this.state.currentView);
        let end = moment(this.state.currentDate).endOf(this.state.currentView);
        if (this.state.currentView == 'month') {
            start = start.startOf('week');
            end = end.endOf('week');
        }
        start = start.format("YYYY-MM-DD");
        end = end.format("YYYY-MM-DD");
        console.log(this.state.currentDate);
        console.log(start);

        return axios.post('/api/swings/get_swings', {
            // selectedMonth: moment(this.state.selectedDate).format("YYYY-MM")
            start: start,
            end: end
        }).then((
            response
        ) => {
            let swings = [];
            let i = 0;

            for (var key in response.data) {
                swings[i] = {};
                // swings[i].title = response.data[i].is_opened == 1 ? 'Opened' : 'Closed';
                let x = 0;
                let title = '';
                for (var subKey in response.data[key]) {
                    if (response.data[key][subKey].is_opened && response.data[key][subKey].opened_time) {
                        title += (response.data[key][subKey].opened_time.substring(0, 5) + '\n');
                        x++;
                    }
                }
                swings[i].title = (x + ' slots opened: \n\n' + title);
                swings[i].allDay = false;
                swings[i].start = new Date(key);
                swings[i].end = new Date(key);
                // swings[i].desc = '';
                i++;
            }
            // for (var i = 0; i < response.data.length; i++) {
            //     swings[i] = {};
            //     swings[i].title = response.data[i].is_opened == 1 ? 'Opened' : 'Closed';
            //     swings[i].allDay = true;
            //     swings[i].start = new Date(response.data[i].day);
            //     swings[i].end = new Date(response.data[i].day);
            // }
            this.setState({
                swings: [...swings]
            });
            // console.log(this.state.swings)
        });
    }

    clickSwingTimeSlot(index, isChecked) {
        // console.log(e)
        // var isChecked = e.target.checked;
        this.state.swingTimeSlots[index].is_opened = isChecked;
        this.setState({
            swingTimeSlots: this.state.swingTimeSlots
        });
    }

    clickAllSwingTimeSlot(isChecked) {
        this.state.selectAll = isChecked;
        for (var i in this.state.swingTimeSlots) {
            this.state.swingTimeSlots[i].is_opened = isChecked;
        }
        this.setState({
            swingTimeSlots: this.state.swingTimeSlots
        });
    }


    updateSwingTimeSlots(e) {
        // stop browser's default behaviour of reloading on form submit
        e.preventDefault();
        console.log(this.state.swingTimeSlots)

        for (var i = 0; i < this.state.swingTimeSlots.length; i++) {
            this.state.swingTimeSlots[i].day = this.state.selectedDate;
        }

        axios
            .post('/api/swings/update_swing_time_slots', {
                swing_time_slots: this.state.swingTimeSlots
            })
            .then(response => {
                console.log('from handle submit', response);
                response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                this.getSwings();
                this.closeDrawer();
            });
    }


    getSwingBookings() {
        return axios.post('/api/swings/get_swing_bookings', {
            day: moment(this.state.selectedDate).format("YYYY-MM-DD")
        }).then((
            response
        ) => {
            if (response.data.length) {
                this.setState({
                    swingBookings: response.data
                });
            }
        });
    }

    cancelSwingBooking(val) {
        if (confirm('Are you sure you want to cancel swing booking?')) {
            axios
                .post('/api/swings/cancel_swing_booking', { id: val })
                .then(response => {
                    response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                    this.setState({
                        drawerBookingVisible: false,
                        drawerVisible: false
                    });
                    this.getSwings();
                    this.getSwingBookings();
                });
        }
    }



    eventStyleGetter(event, start, end, isSelected) {
        // console.log(event);
        // var backgroundColor = '#' + event.hexColor;
        var style = {
            backgroundColor: '#fff',
            borderRadius: '0px',
            color: '#1890FF',
            border: '0px',
            display: 'block',
            fontSize: 12,
            whiteSpace: 'pre-line',
            marginTop: 15,
            cursor: "auto"
        };
        return {
            style: style
        };
    }

    /** for navigation **/
    async setCurrentDate(date) {
        await this.setState({ currentDate: date });
        this.computeDisplayedDateRange();
        this.getSwings();
    }
    async setCurrentView(view) {
        await this.setState({ currentView: view });
        this.computeDisplayedDateRange();
    }

    computeDisplayedDateRange() {
        const { currentDate, currentView } = this.state;
        let start = moment(currentDate).startOf(currentView);
        let end = moment(currentDate).endOf(currentView);
        if (currentView == 'month') {
            start = start.startOf('week');
            end = end.endOf('week');
        }
        this.setState({ displayedDateRange: { start: start.toString(), end: end.toString() } })
    }
    /** end for navigation **/

    componentDidMount() {
        this.computeDisplayedDateRange();
        this.getSwings();
        this.getAirDraft();
    }

    render() {
        // const {form} = this.props;
        const { currentDate, currentView, displayedDateRange } = this.state;
        return (

            <div style={{
                marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10
            }}
            >
                <div>
                    <div style={{ position: 'absolute', top: 10, right: 10 }}>

                        <Form
                            name="air-draft-form"
                            layout="inline"
                            onFinish={this.saveAirDraft}
                            labelCol={{ span: 16 }}
                            wrapperCol={{ span: 8 }}
                            initialValues={{
                                ["air_draft"]: this.state.airDraft ? this.state.airDraft : '',
                            }}
                        >

                            <Form.Item label={"Prince Street Air Draft " + this.state.airDraft} name="air_draft"
                                rules={[{ required: true, message: 'required' }]} >
                                <InputNumber defaultValue={this.state.airDraft} />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={this.state.isSaving}>
                                    <CheckOutlined />
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" >
                                    <Link to={"/swing-booking"}><PlusOutlined />  Book Swing</Link>
                                </Button>
                            </Form.Item>

                        </Form>

                    </div>
                    <Calendar
                        localizer={localizer}
                        events={this.state.swings}
                        style={{ height: 600, fontSize: 16, whiteSpace: "pre-line", cursor: "pointer" }}
                        // defaultDate={this.state.selectedDate}
                        // date={this.state.selectedDate}
                        // defaultDate={new Date(2015, 3, 1)}
                        views={['month']}
                        // views={['month', 'day', 'agenda']}
                        onSelectSlot={this.openDrawer}
                        // onSelectEvent={this.openEventDrawer}
                        selectable={true}
                        eventPropGetter={(this.eventStyleGetter)}
                        startAccessor="start"
                        endAccessor="end"
                        date={currentDate}
                        view={currentView}
                        onNavigate={this.setCurrentDate}
                        onView={this.setCurrentView}
                    // onNavigate={defaultDate => {
                    //     this.setState({ selectedDate: defaultDate }),
                    //     this.setCurrentDate,
                    //     console.log(this.state.selectedDate)  
                    // }}

                    />
                </div>

                <Drawer
                    title={'Swing Availability: ' + this.state.selectedDate}
                    width={500}
                    onClose={this.closeDrawer}
                    visible={this.state.drawerVisible}
                    maskClosable={true}
                    destroyOnClose>

                    <form onSubmit={this.updateSwingTimeSlots}>
                        <div style={{ position: 'absolute', top: '10px', right: '50px' }}>
                            <Button type="text" style={{ color: '#1890FF', backgroundColor: '#F8F8F8' }} onClick={this.openBookingDrawer}>
                                <EyeOutlined />  View Bookings
                            </Button>
                        </div>
                        {this.state.swingTimeSlots.length ? (
                            <Row gutter={[16, 16]}>
                                <Col span={14} >
                                    {this.state.doesSlotAssigned ? <Text type="success">Slots Already Assigned</Text>
                                        : <Text type="danger">Slots Not Yet Assigned</Text>}
                                    {this.state.swingTimeSlots.map((slot, index) => {
                                        return <div key={index} style={{ paddingTop: 15 }}>
                                            <Switch value={slot.is_opened} name={index} checked={slot.is_opened}
                                                onChange={() => { this.clickSwingTimeSlot(index, !slot.is_opened) }}>
                                            </Switch>
                                            &nbsp;&nbsp;{slot.opened_time && slot.opened_time.substring(0, 5)}
                                            {slot.number_of_bookings ? <small style={{ color: '#1890FF', fontStyle: 'italic', fontSize: 12, marginLeft: 30 }}>{slot.number_of_bookings} Reserved</small> : ''}
                                        </div>
                                    })}
                                </Col>
                                <Col span={10}>
                                    <Text type="secondary"> &nbsp;&nbsp; Select All Slots</Text>
                                    <Switch value={this.state.selectAll} checked={this.state.selectAll}
                                        onChange={() => { this.clickAllSwingTimeSlot(!this.state.selectAll) }}>
                                    </Switch>
                                </Col>
                            </Row>
                        ) : <Text type="secondary">There are no slots yet</Text>}
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button type="primary" htmlType="submit" loading="">
                                <SaveOutlined /> Update Slots
                            </Button>
                        </div>
                        {/* 
                        <div className="form-group">
                            <textarea
                                onChange={this.handleChange}
                                value={this.state.name}
                                className="form-control"
                                rows="5"
                                maxLength="255"
                                placeholder="Create a new event"
                                required
                            />
                        </div>
                        <DatePicker format="DD/MM/YYYY" value={this.state.day} style={{ marginRight: 8 }}
                            onChange={this.handleDayChange} />
                        <button type="submit" className="btn btn-primary">
                            Create Schedule
                        </button>

                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="card">
                                    <div className="card-header">Create Task</div>
                                    <div className="card-body">


                                        <hr />

                                        {this.renderEvents()}
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </form>

                </Drawer>

                <Drawer
                    title={'Swing Bookings: ' + this.state.selectedDate}
                    width={500}
                    onClose={this.closeBookingDrawer}
                    visible={this.state.drawerBookingVisible}
                    maskClosable={true}
                    destroyOnClose>
                    {this.state.swingBookings.length ? (
                        <Row gutter={[16, 16]}>

                            {this.state.swingBookings.map((booking, index) => {
                                return <Col span={12} key={index}>
                                    <div style={{ backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.45)', padding: 10 }}>
                                        <span style={{ color: booking.is_approved ? '#1890FF' : '#FF4D4F' }}>Time: {booking.opened_time}
                                            <Button type="text" danger style={{ float: 'right', }}
                                                icon={<CloseOutlined />} onClick={() => this.cancelSwingBooking(booking.id)} ></Button>
                                            <br />
                                        </span>
                                        <hr />
                                        <span>Vessel Name: {booking.vessel_name}</span><br />
                                        <span>Phone: {booking.phone}</span><br />
                                        <span>Email: {booking.email}</span><br />
                                        <span>Travel Direction: {booking.travel_direction}</span><br />
                                        <span>{booking.is_approved ? <Text type="success">Approved</Text>
                                            : <Text type="danger">Cancelled</Text>}</span>
                                    </div>
                                </Col>
                            })}

                        </Row>
                    ) : <Text type="secondary">There are no swing bookigs for this day</Text>}
                </Drawer>
            </div>

        );
    }
}
export default Swings;