/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
    Descriptions,
    Row,
    Col, Avatar, Button, Modal, message, Divider, Tabs, Card,
    Typography, Alert,
    Checkbox,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    CheckOutlined,
    TagOutlined,
    ProfileOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';
import {
    fetchMarina, deleteMarina, approveMarina, processLock,
} from '../../actions';
import Upload from '../shared/Upload';

const { TabPane } = Tabs;

dayjs.extend(customParse);

const baseURL = process.env.APP_URL;

const { confirm } = Modal;
let onConfirmSuccess;
let onConfirmFailed;
const getPromise = () => new Promise((resolve, reject) => {
    onConfirmSuccess = resolve;
    onConfirmFailed = reject;
});

function MarinaProfile(props) {
    const [confirmed, setConfirmed] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [lockFilePath, setLockFilePath] = useState('');
    const [lockProcessingStarted, setLockProcessingStarted] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);
    const {
        match,
        history,
        marina: {
            id: marina_id,
            station,
            app_name,
            welcome_text,
            marina_name,
            address_1,
            address_2,
            address_3,
            postcode,
            contact_name,
            phone,
            phone_label,
            phone_1,
            phone_1_label,
            phone_2,
            phone_2_label,
            email,
            web,
            facebook_link,
            flickr_link,
            twitter_link,
            app_store_link,
            play_store_link,
            open_time_weekdays,
            open_time_saturday,
            open_time_sunday,
            location,
            loc_lat,
            loc_long,
            channel_name,
            channel_number,
            channel_name_1,
            channel_number_1,
            position,
            approved,
            icon_location_app,
            icon_location_marina,
            file_location_tides_locks,
            error,
            isFetching,
            fcm_credentials,
            rate_prompt_definition,
        },
        setTitle,
        shouldFetch,
        fetchMarina,
        deleteMarina,
        approveMarina,
        setExtras,
        processLock,
        id,
    } = props;

    const locationName = location ? location.location_name : '-';
    const formatTimeRange = (val) => {
        if (typeof val !== 'string') return null;
        return val.split(',').join(' TO ');
    };
    const formatAddress = (...vals) => vals.filter((v) => !!v).join(', ');

    const approve = (id) => approveMarina(id, sendEmail);
    const showConfirm = (action, title, isDelete = true) => (e) => {
        e.preventDefault();

        confirm({
            title,
            icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
            onOk() {
                setConfirmed(true);
                setIsDelete(isDelete);
                action(marina_id);
                return getPromise();
            },
            onCancel(close) {
                setIsDelete(false);
                setConfirmed(false);
                close();
            },
        });
    };

    useEffect(() => {
        if (!confirmed || isFetching) return;
        setConfirmed(false);
        if (!error) {
            onConfirmSuccess();
            if (isDelete) {
                setIsDelete(false);
                setTimeout(() => history.goBack(), 100);
            }
        }
        if (error) {
            const { res: { message: msg } } = error;
            message.error(msg);
            onConfirmFailed();
        }
    }, [isFetching]);

    useEffect(() => {
        if (!lockProcessingStarted || isFetching) return;
        setLockProcessingStarted(false);
        if (!error) {
            message.success('Successfully imported Lock closures.');
        }
        if (error) {
            const { res: { message: msg } } = error;
            message.error(msg);
        }
    }, [isFetching]);

    useEffect(() => {
        if (!marina_name) return;
        setExtras([
            <Button key="1" type="primary">
                <Link to={`${match.url}/edit`}>
                    <EditOutlined />
                    {' '}
                    Edit
                </Link>
            </Button>,
            <Button key="2" type="primary" danger onClick={showConfirm(deleteMarina, `Do you want to delete ${marina_name}?`)}>
                <DeleteOutlined />
                {' '}
                Delete
            </Button>,
        ]);
    }, [setExtras, marina_name]);
    useEffect(() => {
        setTitle(
            <span>
                {marina_name}
                {' '}
                {station ? (
                    <Link to={`/stations/${station.id}/tides`}>
                        <TagOutlined />
                        {' '}
                        {station.name}
                    </Link>
                ) : null}
            </span>,
        );
    }, [setTitle, marina_name]);

    useEffect(() => {
        if (shouldFetch && id && !confirmed) {
            fetchMarina(id);
        }
    }, [shouldFetch, id]);

    useEffect(() => {
        if (!lockFilePath) return;

        processLock(id, lockFilePath);
        setLockProcessingStarted(true);
    }, [lockFilePath]);

    const handleFileUploadComplete = ({ url, path }, isInitial) => {
        if (!isInitial) {
            setLockFilePath(url);
        }
    };

    if (!marina_id) return null;

    return (
        <Tabs defaultActiveKey="1">
            <TabPane
                tab={(
                    <span>
                        <ProfileOutlined />
                        Profile
                    </span>
                )}
                key="1"
            >
                <Row gutter={[16, 16]}>
                    <Col xl={3} lg={4}>
                        <Descriptions layout="vertical" column={1} size="small">
                            <Descriptions.Item label="Marina Icon">
                                <Avatar size={128} src={icon_location_marina} shape="square" alt="Marina Icon" style={{ marginBottom: 10, padding: 5, marginLeft: -5 }} />
                            </Descriptions.Item>
                            <Descriptions.Item label="App Icon">
                                <Avatar size={128} src={icon_location_app} shape="square" alt="App Icon" style={{ marginBottom: 10, padding: 5, marginLeft: -5 }} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Upload Lock Closures">
                                <Upload
                                    url={file_location_tides_locks}
                                    type="other"
                                    onUploadComplete={handleFileUploadComplete}
                                    showUploadList
                                    extra={{
                                        marina: marina_id,
                                        name: 'lock_closures',
                                    }}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col xl={21} lg={20}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Descriptions layout="vertical" column={4} size="small">
                                    <Descriptions.Item label="Marina Name">{marina_name}</Descriptions.Item>
                                    <Descriptions.Item label="Address">{formatAddress(address_1, address_2, address_3, postcode)}</Descriptions.Item>
                                    <Descriptions.Item label="App Name">{app_name}</Descriptions.Item>
                                    <Descriptions.Item label="Welcome To Text">{welcome_text}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col>
                                <Descriptions layout="vertical" column={4} size="small">
                                    <Descriptions.Item label="Weather Location">{locationName}</Descriptions.Item>
                                    <Descriptions.Item label="Location latitude">{loc_lat}</Descriptions.Item>
                                    <Descriptions.Item label="Location longitude">{loc_long}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col lg={24}>
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <Descriptions title="Opening Hours" layout="vertical" column={4} size="small">
                                            <Descriptions.Item label="Moday to Friday">{formatTimeRange(open_time_weekdays)}</Descriptions.Item>
                                            <Descriptions.Item label="Saturday">{formatTimeRange(open_time_saturday)}</Descriptions.Item>
                                            <Descriptions.Item label="Sunday">{formatTimeRange(open_time_sunday)}</Descriptions.Item>
                                        </Descriptions>

                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <Descriptions title="Contact Details" layout="vertical" column={4} size="small">
                                            <Descriptions.Item label="Contact Name">{contact_name}</Descriptions.Item>
                                            <Descriptions.Item label={`Contact Phone No (${phone_label})`}>{phone}</Descriptions.Item>
                                            {phone_1 ? (<Descriptions.Item label={`Contact Phone No (${phone_1_label})`}>{phone_1}</Descriptions.Item>) : null}
                                            {phone_2 ? (<Descriptions.Item label={`Contact Phone No (${phone_2_label})`}>{phone_2}</Descriptions.Item>) : null}
                                            <Descriptions.Item label="Contact Email">{email}</Descriptions.Item>
                                            <Descriptions.Item label="Website">{web}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <Descriptions title="Other Details" layout="vertical" column={4} size="small">
                                            <Descriptions.Item label="VHF Channel 1">
                                                {channel_name}
                                                {' '}
                                                {channel_number}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="VHF Channel 2">
                                                {channel_name_1}
                                                {' '}
                                                {channel_number_1}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Waypoint position">{position}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>

                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <Descriptions title="Social Media" layout="vertical" column={4} size="small">
                                            <Descriptions.Item label="Facebook">{facebook_link}</Descriptions.Item>
                                            <Descriptions.Item label="Flickr">{flickr_link}</Descriptions.Item>
                                            <Descriptions.Item label="Twitter">{twitter_link}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        <Descriptions title="Apps" layout="vertical" column={2} size="small">
                                            <Descriptions.Item label="App store">{app_store_link}</Descriptions.Item>
                                            <Descriptions.Item label="Play store">{play_store_link}</Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Divider style={{ marginTop: 0 }} />
                                {approved ? (
                                    <Button type="primary" style={{ float: 'right' }} disabled>
                                        <CheckOutlined />
                                        {' '}
                                        Approved at
                                        {' '}
                                        {dayjs(approved, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </Button>
                                ) : (
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <Checkbox checked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)}>Send Signup Email</Checkbox>
                                        <Button type="primary" onClick={showConfirm(approve, `Confirm ${marina_name} approval?`, false)}>
                                            <CheckOutlined />
                                            {' '}
                                            Approve
                                        </Button>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </TabPane>
            <TabPane
                tab={(
                    <span>
                        <SettingOutlined />
                        Configs
                    </span>
                )}
                key="2"
            >
                <Row gutter={[16, 16]}>
                    <Col lg={24} md={24}>
                        <Alert
                            style={{ marginBottom: 10 }}
                            message="API endpoint"
                            description={(
                                <Typography.Text code copyable>
                                    {`${baseURL}/api`}
                                </Typography.Text>
                            )}
                            type="info"
                            showIcon
                        />
                    </Col>
                    <Col lg={12} md={24}>
                        <Card title="FCM Credentials" bodyStyle={{ maxHeight: 300, overflowY: 'auto' }}>
                            <pre>
                                <code>{fcm_credentials}</code>
                            </pre>
                        </Card>
                    </Col>
                    <Col lg={12} md={24}>
                        <Card title="Rate Prompt" bodyStyle={{ maxHeight: 300, overflowY: 'auto' }}>
                            <pre>
                                <code>{rate_prompt_definition}</code>
                            </pre>
                        </Card>
                    </Col>
                </Row>
            </TabPane>
        </Tabs>
    );
}

function mapStateToProps(state, ownProps) {
    const { marina: marinaId } = ownProps.match.params;
    const { marina: { profile: marina } } = state;

    return {
        marina,
        shouldFetch: !marina.id || (marina.id !== +marinaId),
        id: +marinaId,
    };
}
export default connect(mapStateToProps, {
    fetchMarina,
    deleteMarina,
    approveMarina,
    processLock,
})(MarinaProfile);
