import React, { useEffect, useLayoutEffect, useRef, useState, useMemo, useCallback } from 'react';
import { Alert, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { verifyCustomer, authenticateCustomer } from '../actions';
import DDC from './DDC';
import Challenge from './Challenge';

const checkoutScriptUrl = process.env.WP_SCRIPT_SRC;
const merchant = process.env.WP_CHECKOUT_ID;

const loadingIcon = <LoadingOutlined style={{ fontSize: '1rem', color: '#bbb' }} spin />;

function scriptAlreadyLoaded(src) {
    return document.querySelector(`script[src="${src}"]`);
}

function loadCheckoutScript(src) {
    return new Promise((resolve, reject) => {
        if (scriptAlreadyLoaded(src)) {
            resolve();
            return;
        }

        const checkoutScript = document.createElement('script');
        checkoutScript.src = src;
        checkoutScript.onload = resolve;
        checkoutScript.onerror = reject;
        document.head.appendChild(checkoutScript);
    });
}

function addWorldpayCheckoutToPage() {
    return new Promise((resolve, reject) => {
        (function() {
            window.Worldpay.checkout.init(
                {
                    id: merchant,
                    form: '#wp-checkout',
                    acceptedCardBrands: ['amex', 'visa', 'mastercard'],
                    fields: {
                        pan: {
                            selector: '#card-pan',
                        },
                        expiry: {
                            selector: '#card-expiry',
                        },
                        cvv: {
                            selector: '#card-cvv',
                        },
                    },
                    styles: {
                        'input.is-valid': {
                            color: 'green',
                        },
                        'input.is-invalid': {
                            color: 'red',
                        },
                        'input.is-onfocus': {
                            color: 'black',
                        },
                    },
                    enablePanFormatting: true,
                },
                (error, checkout) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(checkout);
                    }
                },
            );
        })();
    });
}

function Checkout(props) {
    const {
        verifyCustomer,
        authenticateCustomer,
        transactionRef,
        amount,
        verification,
        authentication,
    } = props;
    const [error, setError] = useState();
    const [isSuccess, setIsSuccess] = useState(false);
    const [initDDC, setInitDDC] = useState(false);
    const [initChallenge, setInitChallenge] = useState(false);
    const customerVerificationInitiated = useRef();
    const customerAuthenticationInitiated = useRef();
    const checkoutInstance = useRef();

    function clearForm() {
        checkoutInstance.current.clearForm();
    }

    useEffect(() => {
        if (!customerVerificationInitiated.current) return;
        if (verification.isFetching || verification.error) return;

        customerVerificationInitiated.current = false;

        setInitDDC(true);
    }, [verification.error, verification.isFetching]);

    useEffect(() => {
        if (!customerAuthenticationInitiated.current) return;
        if (authentication.isFetching || authentication.error) return;

        customerAuthenticationInitiated.current = false;

        if (authentication.authorized) {
            setIsSuccess(true);
            clearForm();
            return;
        }

        if (authentication.challenged) {
            console.log(authentication.challenge);
            setInitChallenge(true);
            clearForm();
        }
    }, [authentication.error, authentication.isFetching]);

    function generateSession() {
        checkoutInstance.current.generateSessionState((error, session) => {
            if (error) {
                setError(error);
                console.warn(`Failed to generate session: ${error}`);
                return;
            }

            customerVerificationInitiated.current = true;
            setInitDDC(false);
            verifyCustomer({ session, transaction_ref: transactionRef });
        });
    }

    useEffect(() => {
        loadCheckoutScript(checkoutScriptUrl)
            .then(() => {
                addWorldpayCheckoutToPage()
                    .then((instance) => {
                        checkoutInstance.current = instance;
                    })
                    .catch(console.warn);
            })
            .catch(console.warn);
    }, []);

    // Make sure to call the remove method (once) in order to deallocate the SDK from memory
    useLayoutEffect(() => () => checkoutInstance.current.remove(), []);

    const inProgress = useMemo(
        () => verification.isFetching || authentication.isFetching || initDDC || initChallenge,
        [verification, authentication, initDDC],
    );
    const isError = useMemo(() => error || verification.error || authentication.error, [
        error,
        verification,
        authentication,
    ]);
    const errorMsg = useMemo(
        () =>
            error ||
            (verification.error && verification.error.message) ||
            (authentication.error && authentication.error.message),
        [error, verification, authentication],
    );

    const onDDCMessage = useCallback(
        (data) => {
            customerAuthenticationInitiated.current = true;
            let parsed = {};
            try {
                parsed = JSON.parse(data);
            } catch (e) {
                console.error(e);
            }

            authenticateCustomer({
                session_id: parsed.SessionId || null,
                token: verification.token,
                transaction_ref: transactionRef,
            });
            setInitDDC(false);
        },
        [verification],
    );

    return (
        <>
            {initChallenge ? null : (
                <section className="container" id="wp-checkout">
                    <section className="logo">
                        <img src="/img/bristol-logo.jpeg" alt="bristol logo" />
                    </section>
                    <section className="card">
                        <section className="amount">
                            <span>£ {amount}</span>
                        </section>
                        <section id="card-pan" className="field" />
                        <section className="columns">
                            <section id="card-expiry" className="field" />
                            <section id="card-cvv" className="field" />
                        </section>
                        <section className="buttons">
                            <button className="clear" type="button" onClick={clearForm}>
                                Clear
                            </button>
                            <button
                                className="submit"
                                type="button"
                                onClick={generateSession}
                                disabled={inProgress}
                            >
                                {inProgress ? (
                                    <Spin indicator={loadingIcon} style={{ paddingRight: 5 }} />
                                ) : null}{' '}
                                Pay now
                            </button>
                        </section>
                    </section>
                    <div id="info" className="info">
                        {isError ? (
                            <Alert
                                message="Error in processing the payment"
                                description={errorMsg}
                                type="warning"
                                showIcon
                                closable
                            />
                        ) : null}
                        {isSuccess ? (
                            <Alert
                                message="Payment success"
                                description="Payment request processed successfully"
                                type="success"
                                showIcon
                                closable
                            />
                        ) : null}
                    </div>
                </section>
            )}

            {initDDC ? (
                <DDC
                    bin={verification.bin}
                    jwt={verification.jwt}
                    action={verification.url}
                    onMessage={onDDCMessage}
                />
            ) : null}

            {initChallenge ? (
                <section
                    style={{
                        display: 'flex',
                        border: 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh',
                        flexDirection: 'column',
                    }}
                >
                    <Challenge
                        token={verification.token}
                        transactionRef={transactionRef}
                        jwt={authentication.challenge.jwt}
                        action={authentication.challenge.url}
                    />
                </section>
            ) : null}
        </>
    );
}

const mapStateToProps = (state, ownProps) => {
    const encoded = ownProps.match.params.ref;
    let transactionRef;
    let amount;
    try {
        ({ ref: transactionRef, amount } = JSON.parse(atob(decodeURIComponent(encoded))));
    } catch (e) {
        console.error(e);
    }
    return {
        verification: state.verification,
        authentication: state.authentication,
        transactionRef,
        amount,
    };
};

export default withRouter(
    connect(mapStateToProps, {
        verifyCustomer,
        authenticateCustomer,
    })(Checkout),
);
