import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Drawer, Button, DatePicker, Form, Row, Col, Input, InputNumber, Select, Divider, Checkbox, Radio, Space, message, Switch, Typography, Collapse, TimePicker } from 'antd';
import { BoldOutlined, PlusOutlined, SaveOutlined, EyeOutlined, DownloadOutlined, EditOutlined, ForkOutlined, FormOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Link } from 'react-router-dom';

const localizer = momentLocalizer(moment);
const { Text } = Typography;
const { Panel } = Collapse;

class HarbourMasterExemptions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            day: '',
            name: '',
            exemptionValues: [],
            exemptions: [],
            countExemptions: [],
            events: [],
            drawerVisible: false,
            editVisible: false,
            drawerReportVisible: false,
            selectedDate: new Date(),
            currentDate: new Date(),
            startDate: moment().subtract(1, 'months'),
            endDate: new Date(),
            currentView: 'month',
            displayedDateRange: {},

            drawerNewExemptionVisible: false,
            isSaving: false,
            exemption: {},
            arrivalLockSchedules: [],
            departureLockSchedules: [],

            arrival_lock: null,
            departure_lock: null
        };

        this.getExemptionValues = this.getExemptionValues.bind(this);
        this.getExemptions = this.getExemptions.bind(this);
        this.getCountExemptions = this.getCountExemptions.bind(this);

        this.showEdit = this.showEdit.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.openDrawer = this.openDrawer.bind(this);

        this.closeNewExemptionDrawer = this.closeNewExemptionDrawer.bind(this);
        this.openNewExemptionDrawer = this.openNewExemptionDrawer.bind(this);
        this.saveExemption = this.saveExemption.bind(this);
        this.cancelExemption = this.cancelExemption.bind(this);

        this.setCurrentDate = this.setCurrentDate.bind(this);
        this.setCurrentView = this.setCurrentView.bind(this);

        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);

        this.getTides = this.getTides.bind(this);
        this.getVisitLockScehdules = this.getVisitLockScehdules.bind(this);

    }

    setStartDate(e) {
        this.setState({
            startDate: e,
        });
    };
    setEndDate(e) {
        this.setState({
            endDate: e,
        });
    };

    updateField(name, e) {
        this.setState({ [name]: e });
    }

    showEdit(e) {
        e.preventDefault();
        this.setState({
            editVisible: true,
            drawerVisible: true
        });
    };
    closeDrawer() {
        this.setState({
            drawerVisible: false
        });
        if (this.state.editVisible) {
            this.setState({
                editVisible: false,
                drawerVisible: false
            });
        }
    };
    openDrawer(e) {
        this.setState({
            drawerVisible: true,
            selectAll: false,
            selectedDate: moment(e.start).format("YYYY-MM-DD")
        }, () => {
            console.log(this.state.selectedDate);
            this.getExemptions();
        });

    };

    getExemptionValues() {
        return axios.get('/api/harbour_master/get_harbour_master_exemption_values').then((
            response
        ) => {
            if (response.data.length) {
                this.setState({
                    exemptionValues: response.data
                });
            }
        });
    }

    getCountExemptions() {

        let start = moment(this.state.currentDate).startOf(this.state.currentView);
        let end = moment(this.state.currentDate).endOf(this.state.currentView);
        let group_by = null;
        if (this.state.currentView == 'month') {
            start = start.startOf('week');
            end = end.endOf('week');
        }
        start = start.format("YYYY-MM-DD");
        end = end.format("YYYY-MM-DD");
        console.log(this.state.currentDate);
        console.log(start);

        return axios.post('/api/harbour_master/count_harbour_master_exemptions', {
            start: start,
            end: end
        }).then((
            response
        ) => {
            let countExemptions = [];
            let i = 0;

            for (var key in response.data) {
                countExemptions[i] = {};
                countExemptions[i].title = (response.data[key] ? response.data[key] + ' exemptions' : '');
                countExemptions[i].allDay = false;
                countExemptions[i].start = new Date(key);
                countExemptions[i].end = new Date(key);
                i++;
            }
            this.setState({
                countExemptions: [...countExemptions]
            });
        });
    }

    getExemptions() {
        let exemptions = [];
        return axios.post('/api/harbour_master/get_harbour_master_exemptions', {
            day: moment(this.state.selectedDate).format("YYYY-MM-DD")
        }).then((
            response
        ) => {
            if (response.data.length) {
                exemptions = response.data;
            }
            this.setState({
                exemptions: exemptions
            });
        });
    }

    closeNewExemptionDrawer() {
        this.setState({
            drawerNewExemptionVisible: false,
            exemption: {},
            arrivalLockSchedules: [],
            departureLockSchedules: [],
        });
    };
    openNewExemptionDrawer(e) {
        this.setState({
            drawerNewExemptionVisible: true,
            exemption: {},
            arrivalLockSchedules: [],
            departureLockSchedules: [],
            arrival_lock: null,
            departure_lock: null,
            selectedDate: moment(e.start).format("YYYY-MM-DD")
        }, () => {
            console.log(this.state.selectedDate + 'mmm')
            this.getTides(this.state.selectedDate, 1);
            this.getTides(this.state.selectedDate, 2);
        });
    };

    getTides(date, type) {
        let tides = [];
        return axios.get('/api/house_diary/get_tides?date=' + (moment(date).format("YYYY-MM-DD"))).then((
            response
        ) => {
            if (response.data.length) {
                tides = response.data;
                this.getVisitLockScehdules(tides, type);
            }
        });
    }

    getVisitLockScehdules(tides, type) {
        let lockSchedules = [];
        return axios.post('/api/house_diary/get_book_visit_lock_scehdules', {
            tides: tides,
            does_visit_count: false
        }).then((
            response
        ) => {
            if (response.data.length) {
                lockSchedules = response.data;
            }
            if (type == 1) {
                this.setState({ arrivalLockSchedules: lockSchedules });
            } else {
                this.setState({ departureLockSchedules: lockSchedules });
            }


        });
    }

    saveExemption(val) {
        // val.date = moment(val.date).format("YYYY-MM-DD");
        val.date = moment(this.state.selectedDate).format("YYYY-MM-DD");
        console.log(val);

        this.setState({
            isSaving: true,
        });

        axios
            .post('/api/harbour_master/save_harbour_master_exemption', val)
            .then(response => {
                response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                this.setState({
                    isSaving: false,
                    drawerNewExemptionVisible: false,
                });
                this.getCountExemptions();
            });
    }

    cancelExemption(val) {
        if (confirm('Are you sure you want to cancel exemption?')) {
            axios
                .post('/api/harbour_master/cancel_harbour_master_exemption', { id: val })
                .then(response => {
                    response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                    this.setState({
                        drawerVisible: false,
                    });
                    this.getExemptions();
                    this.getCountExemptions();
                });
        }
    }


    eventStyleGetter(event, start, end, isSelected) {
        var style = {
            background: 'rgba(255,255,255,0)',
            borderRadius: '0px',
            // color: '#1890FF',
            color: '#0166bf',
            border: '0px',
            display: 'block',
            fontSize: 12,
            // whiteSpace: 'pre-line',
            whiteSpace: 'pre',
            marginTop: 15,
            cursor: "auto"
        };
        return {
            style: style
        };
    }

    /** for navigation **/
    async setCurrentDate(date) {
        await this.setState({ currentDate: date });
        this.computeDisplayedDateRange();
        this.getCountExemptions();
    }
    async setCurrentView(view) {
        await this.setState({ currentView: view });
        this.computeDisplayedDateRange();
    }

    computeDisplayedDateRange() {
        const { currentDate, currentView } = this.state;
        let start = moment(currentDate).startOf(currentView);
        let end = moment(currentDate).endOf(currentView);
        if (currentView == 'month') {
            start = start.startOf('week');
            end = end.endOf('week');
        }
        this.setState({ displayedDateRange: { start: start.toString(), end: end.toString() } })
    }
    /** end for navigation **/

    componentDidMount() {
        this.computeDisplayedDateRange();
        this.getCountExemptions();
        this.getExemptionValues();
    }

    render() {
        const { currentDate, currentView, displayedDateRange } = this.state;
        return (

            <div style={{
                marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10
            }}
            >
                <div>
                    <div style={{ position: 'absolute', top: 10, right: 10 }}>
                        <Button type="primary" onClick={this.openNewExemptionDrawer}><PlusOutlined />  New HM Exemption</Button>
                    </div>
                    <Calendar
                        localizer={localizer}
                        events={this.state.countExemptions}
                        style={{ height: 600, fontSize: 16, whiteSpace: "pre-line", cursor: "pointer" }}
                        views={['month']}
                        onSelectSlot={this.openNewExemptionDrawer}
                        onSelectEvent={this.openDrawer}
                        selectable={true}
                        eventPropGetter={(this.eventStyleGetter)}
                        startAccessor="start"
                        endAccessor="end"
                        date={currentDate}
                        view={currentView}
                        onNavigate={this.setCurrentDate}
                        onView={this.setCurrentView}
                        html={true}

                    />
                </div>

                <Drawer
                    title={'Harbour Master Exemptions on ' + this.state.selectedDate}
                    width={650}
                    onClose={this.closeDrawer}
                    visible={this.state.drawerVisible}
                    maskClosable={true}
                    destroyOnClose>
                    {this.state.exemptions.length ? (
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Collapse bordered={false} className="site-collapse-custom-collapse " >
                                    {this.state.exemptions.map((exemption, index) => {
                                        return <Panel header={(exemption.boat_name ? exemption.boat_name + ' - ' : '')
                                            + (exemption.contact_name ? exemption.contact_name + ' - ' : '') + (exemption.value ? exemption.value : '')
                                            + (exemption.is_approved ? ' - Approved' : ' - Not Approved')
                                        }
                                            key={index} style={{
                                                backgroundColor: '#f8f8f8', borderBottom: '3px solid #fff', color: '#000', padding: 0, margin: 0,
                                                borderLeft: exemption.is_approved ? '3px solid #1890FF' : '3px solid #FF4D4F'
                                            }}>
                                            <div style={{
                                                display: 'block', color: '#888', padding: 3, margin: 2, backgroundColor: '#fff'
                                            }}>

                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>Boat Name: </th>
                                                            <td >{exemption.boat_name ? exemption.boat_name : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th >Contact Name: </th>
                                                            <td >{exemption.contact_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Phone: </th>
                                                            <td>{exemption.phone}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Arrival Lock: </th>
                                                            <td>{exemption.arrival_lock ? exemption.arrival_lock : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Departure Lock: </th>
                                                            <td>{exemption.departure_lock ? exemption.departure_lock : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Exemption Value: </th>
                                                            <td>{exemption.value}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Air Draft: </th>
                                                            <td>{exemption.air_draft}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Approved: </th>
                                                            <td>{exemption.is_approved ? <Text type="success">Yes</Text> : <Text type="danger">No</Text>}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>


                                                {exemption.is_approved ? (
                                                    <div style={{ display: 'block', float: 'right', marginTop: '15px' }}>
                                                        <Button type="text"
                                                            icon={<CloseOutlined />} onClick={() => this.cancelExemption(exemption.id)} > Cancel</Button>
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </Panel>
                                    })}
                                </Collapse>
                            </Col>



                        </Row>
                    ) : <Text type="danger">There are no harbour master exemptions yet</Text>}
                    <Divider />

                </Drawer>

                <Drawer
                    title={this.state.selectedDate ? ('New Harbour Master Exemption - ' + this.state.selectedDate) : 'New Harbour Master Exemption'}
                    width={500}
                    onClose={this.closeNewExemptionDrawer}
                    visible={this.state.drawerNewExemptionVisible}
                    destroyOnClose>

                    <Form
                        name="new-exemption-form"
                        layout="horizontal"
                        onFinish={this.saveExemption}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{
                            // ["date"]: this.state.selectedDate ? moment(this.state.selectedDate).format("YYYY-MM-DD") : null,
                            // ["arrival_lock"]: this.state.exemption ? this.state.exemption.arrival_lock : '00:00',
                            // ["departure_lock"]: this.state.exemption ? this.state.exemption.departure_lock : '00:00',

                        }}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={24}>

                                {!this.state.selectedDate ? (
                                    <Form.Item label="Date" name="date" rules={[{ required: true, message: 'Date is required' }]} >
                                        <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} onChange={(e) => { this.getTides(e, 1), this.getTides(e, 2) }} />
                                    </Form.Item>
                                ) : ''}

                                <Form.Item label="Arrival Lock" name="arrival_lock">
                                    <Radio.Group disabled={this.state.departure_lock ? true : false} onChange={(e) => this.updateField("arrival_lock", e.target.value)}>
                                        {this.state.arrivalLockSchedules.map((schedule, index) => {
                                            return <Space direction="vertical" key={index} >
                                                <Radio value={schedule.lock_date1.arrival_time}>{schedule.lock_date1.arrival_time}</Radio>
                                                <Radio value={schedule.lock_date2.arrival_time}>{schedule.lock_date2.arrival_time}</Radio>
                                                <Radio value={schedule.lock_date3.arrival_time}>{schedule.lock_date3.arrival_time}</Radio>
                                            </Space>
                                        })}
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item label="Departure Lock" name="departure_lock" onChange={(e) => this.updateField("departure_lock", e.target.value)}>
                                    <Radio.Group disabled={this.state.arrival_lock ? true : false} >
                                        {this.state.departureLockSchedules.map((schedule, index) => {
                                            return <Space direction="vertical" key={index} >
                                                <Radio value={schedule.lock_date1.departure_time}>{schedule.lock_date1.departure_time}</Radio>
                                                <Radio value={schedule.lock_date2.departure_time}>{schedule.lock_date2.departure_time}</Radio>
                                                <Radio value={schedule.lock_date3.departure_time}>{schedule.lock_date3.departure_time}</Radio>
                                            </Space>
                                        })}
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item label="Boat Name" name="boat_name" >
                                    <Input placeholder="Please enter boat name" />
                                </Form.Item>

                                <Form.Item label="Contact Name" name="contact_name" >
                                    <Input placeholder="Please enter contact name" />
                                </Form.Item>

                                <Form.Item label="Phone No" name="phone" rules={[{ required: true, message: 'Phone no is required' }]} >
                                    <Input placeholder="Please enter phone no" />
                                </Form.Item>

                                <Form.Item label="Air Draft" name="air_draft" rules={[{ required: true, message: 'Air draft is required' }]} >
                                    <InputNumber placeholder="Please enter air draft" style={{ width: '100%' }} />
                                </Form.Item>

                                <Form.Item label="Exemption Value" name="exemption_value_id" rules={[{ required: true, message: 'Exemption value is required' }]} >
                                    <Select placeholder="Please select exemption value ">
                                        {this.state.exemptionValues.map((exemptionValue, index) => {
                                            return <Select.Option value={exemptionValue.id} key={index}>{exemptionValue.value}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <div style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit" loading={this.state.isSaving}>
                                <SaveOutlined /> Save
                            </Button>
                        </div>
                    </Form>
                </Drawer>


            </div >

        );
    }
}
export default HarbourMasterExemptions;