import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { PageHeader, Drawer, Button, DatePicker, Form, Row, Col, Input, Select, Option, Divider, Badge, Checkbox, Modal, message, Switch, Typography, Collapse, InputNumber } from 'antd';
import { BoldOutlined, PlusOutlined, SaveOutlined, EyeOutlined, DownloadOutlined, EyeFilled, SearchOutlined, CheckCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
const { Text } = Typography;
const { Panel } = Collapse;

class BerthHolder extends Component {

    constructor(props) {
        super(props);
        console.log(props)
        console.log(props.me.marina_name)

        this.state = {
            berthHolder: {},
            berthHolders: [],
            drawerVisible: false,
            isSaving: false,
            isNeyland: props.me.marina_name == 'neyland' ? true : false,
            metreReadingsDrawerVisible: false,
            metreReadingsModalVisible: false,
            metreReadings: [],

            startDate: moment().subtract(1, 'weeks'),
            endDate: new Date(),
            search: null,
        };

        this.getBerthHolders = this.getBerthHolders.bind(this);
        this.saveBerthHolder = this.saveBerthHolder.bind(this);
        this.updateBerthHolderStatus = this.updateBerthHolderStatus.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.openDrawer = this.openDrawer.bind(this);
        this.openMetreReadingsDrawer = this.openMetreReadingsDrawer.bind(this);
        this.closeMetreReadingsDrawer = this.closeMetreReadingsDrawer.bind(this);
        this.openMetreReadingsModal = this.openMetreReadingsModal.bind(this);
        this.closeMetreReadingsModal = this.closeMetreReadingsModal.bind(this);

        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.setSearch = this.setSearch.bind(this);
        this.filterMetreReadings = this.filterMetreReadings.bind(this);

    }

    closeDrawer() {
        this.setState({
            drawerVisible: false
        });
    };
    openDrawer(e) {
        this.setState({
            drawerVisible: true,
        });
    };

    openMetreReadingsDrawer(berthHolder) {
        this.setState({
            metreReadingsDrawerVisible: true,
        });
        this.getMetreReadings(berthHolder.id);
    };
    closeMetreReadingsDrawer() {
        this.setState({
            metreReadingsDrawerVisible: false
        });
    };

    openMetreReadingsModal() {
        this.setState({
            metreReadingsModalVisible: true,
        });
    };
    closeMetreReadingsModal() {
        this.setState({
            metreReadingsModalVisible: false
        });
    };

    saveBerthHolder(val) {
        if (this.state.isNeyland) {//only for neyland marina
            val.last_metre_reading_date = moment(val.last_metre_reading_date).format("YYYY-MM-DD");
        } else {
            val.licence_expiry_date = val.licence_expiry_date ? moment(val.licence_expiry_date).format("YYYY-MM-DD") : null;
        }
        this.setState({
            berthHolder: val,
            isSaving: true,
        });
        console.log(val)
        axios
            .post('/api/berth_holder/save_berth_holder', val)
            .then(response => {
                console.log('from handle submit', response);
                response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                this.setState({
                    isSaving: false,
                });
                this.getBerthHolders();
                this.closeDrawer();
            });
    }

    getBerthHolders() {
        return axios.get('/api/berth_holder/get_berth_holders').then((
            response
        ) => {
            if (response.data.length) {
                this.setState({
                    berthHolders: response.data
                });
            }
        });
    }

    updateBerthHolderStatus(id, val) {
        if (confirm("Are you sure want to " + (val ? "activate" : "disable") + " berth holder") == true) {
            axios
                .post('/api/berth_holder/update_berth_holder_status', { 'id': id, 'is_approved': val })
                .then(response => {
                    response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);
                    this.getBerthHolders();
                });
        }
    }

    getMetreReadings(id) {
        this.setState({
            metreReadings: []
        });
        return axios.get('/api/berth_holder/get_metre_readings/' + id).then((
            response
        ) => {
            if (response.data.length) {
                this.setState({
                    metreReadings: response.data
                });
            }
        });
    }

    setStartDate(e) {
        this.setState({
            startDate: e,
        });
    };
    setEndDate(e) {
        this.setState({
            endDate: e,
        });
    };
    setSearch(e) {
        this.setState({
            search: e,
        });
    };

    filterMetreReadings() {
        if (this.state.startDate || this.state.endDate || this.state.search) {
            this.setState({
                metreReadings: [],
                metreReadingsModalVisible: true,
            });

            axios
                .post('/api/berth_holder/get_metre_readings', {
                    start_date: moment(this.state.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.state.endDate).format("YYYY-MM-DD"),
                    search: this.state.search
                })
                .then(response => {
                    if (response.data.length) {
                        this.setState({
                            metreReadings: response.data
                        });
                    }
                });
        } else {
            message.error("Please enter start date, end date or search");
        }
    }

    componentDidMount() {
        this.getBerthHolders();
    }

    render() {
        return (

            <PageHeader
                title="Berth Holders"
                className="locks-page-header"
                onBack={() => window.history.back()}>
                <div style={{ float: 'right', marginBottom: 8, position: 'absolute', top: 16, right: 24, }}>
                    {this.state.isNeyland ?
                        <>
                            <DatePicker value={moment(this.state.startDate)} format="DD/MM/YYYY" onChange={this.setStartDate} />&nbsp;
                            <DatePicker value={moment(this.state.endDate)} format="DD/MM/YYYY" onChange={this.setEndDate} />&nbsp;
                            <Input placeholder="Customer Name, Boat name" value={this.state.search}
                                onChange={(e) => { this.setSearch(e.target.value) }}
                                style={{ width: '240px' }} title="Customer Name, Boat name" />&nbsp;
                            <Button type="primary" onClick={this.filterMetreReadings}> <SearchOutlined /> Filter Meter Readings</Button>&nbsp;
                        </> : ''}
                    <Button type="primary" onClick={this.openDrawer}><PlusOutlined /> New Berth Holder</Button>
                </div>

                <div>

                    {/* {display list of berth holders} */}
                    <div className='ant-table-content'>
                        {this.state.berthHolders.length ?
                            <table style={{ width: '100%' }}>
                                <thead className='ant-table-thead'>
                                    <tr>
                                        <th>Boat Name</th>
                                        <th>Berth Holder</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        {this.state.isNeyland ? (
                                            <>
                                                <th style={{ textAlign: 'center' }}>Last Meter Reading (m)</th>
                                                <th>Reading Date</th>
                                            </>
                                        ) :
                                            <th style={{ textAlign: 'center' }}>Air Draft (m)</th>
                                        }
                                        <th style={{ textAlign: 'center' }}>Length (m)</th>
                                        <th>Pin</th>
                                        {!this.state.isNeyland ?
                                            <th>Licence Expiry</th>
                                            :
                                            <>
                                                <th>Active</th>
                                                <th></th>
                                            </>}
                                    </tr>
                                </thead>
                                <tbody className='ant-table-tbody'>
                                    {this.state.berthHolders.map((berthHolder, index) => {
                                        return <tr key={index}>
                                            <td>{berthHolder.boat_name}</td>
                                            <td>{berthHolder.title}. {berthHolder.first_name} {berthHolder.surname}</td>
                                            <td style={{ color: '#1890ff' }}>{berthHolder.email}</td>
                                            <td>{berthHolder.phone}</td>
                                            {this.state.isNeyland ? (
                                                <>
                                                    <td style={{ textAlign: 'center' }}>
                                                        {berthHolder.last_metre_reading}
                                                        {berthHolder.last_metre_reading ?
                                                            <EyeFilled title='View Meter Readings History' style={{ padding: '10px 10px 0px 10px', marginLeft: '5px', fontSize: '18px', color: '#1890ff', cursor: 'pointer' }}
                                                                onClick={() => this.openMetreReadingsDrawer(berthHolder)} />
                                                            : ''}
                                                    </td>
                                                    <td>{berthHolder.last_metre_reading_date ? moment(berthHolder.last_metre_reading_date).format('DD/MM/YYYY') : ''}</td>
                                                </>
                                            ) :
                                                <td style={{ textAlign: 'center' }}>{berthHolder.air_draft}</td>
                                            }
                                            <td style={{ textAlign: 'center' }}>{berthHolder.length}</td>
                                            <td>{berthHolder.unique_code}</td>
                                            {!this.state.isNeyland ?
                                                <td>{berthHolder.licence_expiry_date ? moment(berthHolder.licence_expiry_date).format('DD/MM/YYYY') : ''}</td>
                                                :
                                                <>
                                                    <td>{berthHolder.is_approved == 1 ?
                                                        <Text type='success'>Yes</Text>
                                                        :
                                                        <Text type='danger'>No</Text>}</td>
                                                    <td><Button type="link" onClick={() => this.updateBerthHolderStatus(berthHolder.id, (berthHolder.is_approved == 1 ? 0 : 1))}>
                                                        {berthHolder.is_approved == 1 ?
                                                            <div ><CloseOutlined /> Disable</div>
                                                            :
                                                            <div ><CheckOutlined /> Activate</div>}
                                                    </Button></td>
                                                </>}
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                            : <Text type="danger">No berth holders available</Text>}
                    </div>
                    {/* {end display list of berth holders} */}

                    <Drawer
                        title={'Add New Berth Holder'}
                        width={500}
                        onClose={this.closeDrawer}
                        visible={this.state.drawerVisible}
                        maskClosable={true}
                        destroyOnClose>

                        <Form
                            name="berth-holder-form"
                            layout="horizontal"
                            onFinish={this.saveBerthHolder}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.Item label="Boat Name" name="boat_name"
                                        rules={[{ required: true, message: 'Boat name is required' }]} hasFeedback>
                                        <Input placeholder="Please enter boat name" value={this.state.berthHolder.boat_name} />
                                    </Form.Item>
                                    <Form.Item label="Berth Holder Title" name="title"
                                        rules={[{ required: true, message: 'Title is required' }]} hasFeedback>
                                        <Select placeholder="Please select title" value={this.state.berthHolder.title}>
                                            <Select.Option value='Mr' key='0'>Mr</Select.Option>
                                            <Select.Option value='Mrs' key='1'>Mrs</Select.Option>
                                            <Select.Option value='Ms' key='2'>Ms</Select.Option>
                                            <Select.Option value='Dr' key='3'>Dr</Select.Option>
                                            <Select.Option value='Rev' key='4'>Rev</Select.Option>
                                            <Select.Option value='Sir' key='5'>Sir</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="First Name" name="first_name"
                                        rules={[{ required: true, message: 'First name is required' }]} hasFeedback>
                                        <Input placeholder="Please enter first name" value={this.state.berthHolder.first_name} />
                                    </Form.Item>
                                    <Form.Item label="Surname" name="surname"
                                        rules={[{ required: true, message: 'Surname is required' }]} hasFeedback>
                                        <Input placeholder="Please enter surname" value={this.state.berthHolder.surname} />
                                    </Form.Item>
                                    <Form.Item label="Email" name="email"
                                        rules={[{ type: 'email', required: true, message: 'Email is required' }]} hasFeedback>
                                        <Input placeholder="Please enter email" value={this.state.berthHolder.email} />
                                    </Form.Item>
                                    <Form.Item label="Phone" name="phone"
                                        rules={[{ required: true, message: 'Phone is required' }]} hasFeedback>
                                        <Input placeholder="Please enter phone number" value={this.state.berthHolder.phone} />
                                    </Form.Item>
                                    {!this.state.isNeyland ? (
                                        <Form.Item label="Air Draft (m)" name="air_draft"
                                            rules={[{ required: true, message: 'Air draft is required' }]} hasFeedback>
                                            <InputNumber placeholder="Please enter air draft" value={this.state.berthHolder.air_draft} style={{ width: '100%' }} />
                                        </Form.Item>
                                    ) : ''}
                                    <Form.Item label="Length (m)" name="length"
                                        rules={[{ required: true, message: 'Length is required' }]} hasFeedback>
                                        <InputNumber placeholder="Please enter length" value={this.state.berthHolder.length} style={{ width: '100%' }} />
                                    </Form.Item>
                                    {this.state.isNeyland ? (
                                        <div>
                                            <Form.Item label="Last Meter Reading (m)" name="last_metre_reading"
                                                rules={[{ required: true, message: 'Last meter reading is required' }]}>
                                                <InputNumber placeholder="Please enter last meter reading" value={this.state.berthHolder.last_metre_reading} style={{ width: '100%' }} />
                                            </Form.Item>
                                            <Form.Item label="Reading Date" name="last_metre_reading_date"
                                                rules={[{ required: true, message: 'Last meter reading date is required' }]}>
                                                <DatePicker placeholder="Please select last meter reading date" format="DD/MM/YYYY" value={moment(this.state.berthHolder.last_metre_reading_date)} style={{ width: '100%' }} />
                                            </Form.Item>
                                        </div>
                                    ) :
                                        <Form.Item label="Licence Expiry" name="licence_expiry_date"
                                            rules={[{ required: true, message: 'Length is required' }]} hasFeedback>
                                            <DatePicker placeholder="Please select licence expiry date" format="DD/MM/YYYY" value={moment(this.state.berthHolder.licence_expiry_date)} style={{ width: '100%' }} />
                                        </Form.Item>
                                    }
                                </Col>
                            </Row>

                            <div style={{ textAlign: 'right' }}>
                                <Button type="primary" htmlType="submit" loading={this.state.isSaving}>
                                    <SaveOutlined /> Save
                                </Button>
                            </div>
                        </Form>
                    </Drawer>

                    <Drawer
                        title='Meter Readings'
                        width={500}
                        onClose={this.closeMetreReadingsDrawer}
                        visible={this.state.metreReadingsDrawerVisible}
                        maskClosable={true}
                        destroyOnClose>
                        {this.state.metreReadings.length ? (
                            <Row gutter={[16, 16]}>

                                {this.state.metreReadings.map((reading, index) => {
                                    return <Col span={12} key={index}>
                                        <div style={{
                                            backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.45)', padding: 10
                                        }}>
                                            <span>Meter Reading: {reading.metre_reading}</span><br />
                                            <span>Date: {reading.reading_date ? moment(reading.reading_date).format('DD/MM/YYYY') : ''}</span>
                                        </div>
                                    </Col>
                                })}

                            </Row>
                        ) : <Text type="secondary">There are no meter readings for this berth holder</Text>}
                    </Drawer>

                    <Modal
                        title={"Meter Reading for : " + (this.state.startDate ? (moment(this.state.startDate).format('DD/MM/YYYY') + ' : ') : ' ')
                            + (this.state.endDate ? (moment(this.state.endDate).format('DD/MM/YYYY') + ' : ') : ' ')
                            + (this.state.search ? this.state.search : '')}
                        visible={this.state.metreReadingsModalVisible}
                        onOk={this.closeMetreReadingsModal}
                        // confirmLoading={confirmLoading}
                        onCancel={this.closeMetreReadingsModal}
                        width={800}
                    >
                        <table style={{ width: '100%' }}>
                            <thead className='ant-table-thead'>
                                <tr>
                                    <th style={{ padding: '0px' }}>#</th>
                                    <th>Boat Name</th>
                                    <th>Berth Holder</th>
                                    <th>Reading Date</th>
                                    <th style={{ textAlign: 'center', width: '100px' }}>Value (m)</th>
                                </tr>
                            </thead>
                            <tbody className='ant-table-tbody'>
                                {this.state.metreReadings.map((reading, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{reading.boat_name}</td>
                                        <td>{reading.first_name} {reading.surname}</td>
                                        <td>{reading.reading_date ? moment(reading.reading_date).format('DD/MM/YYYY') : ''}</td>
                                        <td style={{ textAlign: 'center' }}>{reading.metre_reading}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </Modal>

                </div >
            </PageHeader>
        );
    }
}

function mapStateToProps(state) {
    return {
        me: state.me,
        auth: state.auth,
    };
}
export default connect(mapStateToProps)(BerthHolder);