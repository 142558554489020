import {
    CILL_HEIGHTS_REQUEST,
    CILL_HEIGHTS_SUCCESS,
    CILL_HEIGHTS_FAILURE,
} from '../actions';

export const CILL_HEIGHTS_INITIAL_STATE = {
    isFetching: false,
    data: [],
    links: {},
    meta: {},
    error: null,
};

const cillHeightsReducers = {
    [CILL_HEIGHTS_REQUEST]: (state) => ({ ...state, isFetching: true, error: null }),
    [CILL_HEIGHTS_SUCCESS]: (state, { res }) => ({
        ...state, ...res, isFetching: false, error: null,
    }),
    [CILL_HEIGHTS_FAILURE]: (state, { error }) => ({ ...state, isFetching: false, error }),
};

export default function locks(state = CILL_HEIGHTS_INITIAL_STATE, action) {
    const reducer = cillHeightsReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
