import React, { useState } from 'react';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { DatePicker } from 'antd';
import PaginatedList from './PaginatedList';

dayjs.extend(customParse).extend(utc);

export default function CillHeights(props) {
    const {
        fetchData,
        cillHeights,
    } = props;
    const [query, setQuery] = useState('');

    const updateQuery = (val) => {
        setQuery(val ? val.format('DD/MM/YYYY') : '');
    };

    const columns = [{
        title: 'Day',
        dataIndex: 'day',
        width: "15%",
    }, {
        title: 'Date',
        dataIndex: 'date',
        render: (text) => dayjs(text).format('DD/MM/YYYY'),
        width: "15%",
    }, {
        title: 'Time',
        dataIndex: 'time',
        width: "10%",
    }, {
        title: 'Water over cill (m)',
        dataIndex: 'water_over_cill',
        width: "20%",
    }, {
        title: 'Water over cill (ft)',
        dataIndex: 'water_over_cill_ft',
        width: "20%",
    }, {
        title: 'Gate status',
        dataIndex: 'gate_status',
        width: "20%",
    }];

    const data = cillHeights.data.map(item => ({...item, children: item.times}))

    return (
        <>
            <div style={{
                float: 'right', marginBottom: 8, position: 'absolute', top: 16, right: 24,
            }}
            >
                <DatePicker format="DD/MM/YYYY" onChange={updateQuery} style={{ marginRight: 8 }} placeholder="Filter by date" />
            </div>
            <PaginatedList
                fetchData={fetchData}
                columns={columns}
                source={data}
                meta={cillHeights.meta}
                loading={cillHeights.isFetching}
                query={query}
                {...props}
            />
        </>
    );
}
