import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Button, DatePicker, Form, Row, Col, Input, InputNumber, Select, Option, Divider, Checkbox, Radio, Space, message, Switch, Typography, Collapse } from 'antd';
import { CheckCircleFilled, CheckCircleOutlined, CheckOutlined, ClockCircleFilled, ClockCircleOutlined, CompassFilled, CompassOutlined, PhoneFilled, PhoneOutlined, PlayCircleFilled, PlayCircleOutlined, SaveOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

const { Text } = Typography;
const { Panel } = Collapse;

class BookVisit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bookVisit: {},
            arriving_from: null,
            vessel_type: null,
            cost: null,
            hull_type: null,
            arrival_date: null,
            arrival_min_date: moment().add(2, 'days'),
            departure_date: null,
            departure_max_date: moment(new Date()).add(16, 'days'),
            length_unit: null,
            boat_length: null,
            number_of_days: null,
            home_port: null,
            title: null,
            isSaving: false,
            arrivalLockSchedules: [],
            departureLockSchedules: [],
            formRef: React.createRef(),
            isAgree: false
        };

        this.updateField = this.updateField.bind(this);
        this.selectArrivalFrom = this.selectArrivalFrom.bind(this);
        this.saveBookVisit = this.saveBookVisit.bind(this);
        this.calculateCost = this.calculateCost.bind(this);
        this.calculateCostForVessel = this.calculateCostForVessel.bind(this);
        this.getTides = this.getTides.bind(this);
        this.getVisitLockScehdules = this.getVisitLockScehdules.bind(this);
        this.checkAgree = this.checkAgree.bind(this);
    }

    selectArrivalFrom() {
        this.setState({ cost: 0 },  () => {
            console.log(this.state.cost + 'mmm')
        })
    }

    checkAgree(e) {
        this.setState({ isAgree: e.target.checked },
            () => {
                console.log(this.state.isAgree + 'mmm')
            })
    }

    updateField(name, e) {
        this.setState({ [name]: e }
            , () => {
                let number_of_days = null;
                if (this.state.departure_date && this.state.arrival_date) {
                    number_of_days = Math.round((this.state.departure_date - this.state.arrival_date) / 86400000);
                    number_of_days = number_of_days == 0 ? 1 : number_of_days;
                } else if (this.state.departure_date || this.state.arrival_date) {
                    number_of_days = 1;
                }
                this.setState({ number_of_days: number_of_days },
                    () => {
                        number_of_days = number_of_days <= 0 ? 1 : number_of_days;
                        if (this.state.boat_length && this.state.length_unit && this.state.hull_type && this.state.number_of_days) {
                            this.calculateCost();
                        } else if (this.state.vessel_type && this.state.number_of_days
                            && (((this.state.vessel_type == 'Dinghy' || this.state.vessel_type == 'Motor boat') && this.state.boat_length > 0) 
                            || this.state.vessel_type == 'Canoe' || this.state.vessel_type == 'Stand up paddle board') ) {
                                this.calculateCostForVessel();
                        }
                    });

                    if(name == 'arrival_date'){
                        this.setState({ departure_max_date: moment(this.state.arrival_date).add(14, 'days') });
                    }
            });
    }

    calculateCost() {
        return axios.post('/api/rates/calculate_visit_cost', {
            multi_hull: this.state.hull_type == 'multi_hull' ? 'yes' : 'no',
            berth_type: 'visiting',
            length: this.state.boat_length,
            unit: this.state.length_unit,
            days: this.state.number_of_days,
        }).then((
            response
        ) => {
            if (response.data) {
                this.setState({ cost: response.data.cost },
                    () => {
                        console.log(this.state.cost + 'mmm')
                    });
            }
        });
    }

    calculateCostForVessel() {
        return axios.post('/api/book_visit/calculate_book_visit_price', {
            length: this.state.boat_length,
            vessel_type: this.state.vessel_type
        }).then((
            response
        ) => {
            if (response.data) {
                this.setState({ cost: response.data.price },
                    () => {
                        console.log(this.state.cost + 'mmm')
                    });
            }
        });
    }

    getTides(date, type) {
        let tides = [];
        return axios.get('/api/house_diary/get_tides?date=' + (moment(date).format("YYYY-MM-DD"))).then((
            response
        ) => {
            if (response.data.length) {
                tides = response.data;
                this.getVisitLockScehdules(tides, type);
            }
        });
    }

    getVisitLockScehdules(tides, type) {
        let lockSchedules = [];
        return axios.post('/api/house_diary/get_book_visit_lock_scehdules', {
            tides: tides,
            does_visit_count: false
        }).then((
            response
        ) => {
            if (response.data.length) {
                lockSchedules = response.data;
            }
            if (type == 1) {
                this.setState({ arrivalLockSchedules: lockSchedules });
            } else {
                this.setState({ departureLockSchedules: lockSchedules });
            }


        });
    }


    saveBookVisit(val) {

        val.cost = this.state.cost;
        val.number_of_days = this.state.number_of_days;
        val.arrival_date = moment(val.arrival_date).format("YYYY-MM-DD");
        // val.arrival_lock = moment(val.arrival_lock).format("HH:mm");
        val.departure_date = moment(val.departure_date).format("YYYY-MM-DD");
        // val.departure_lock = moment(val.departure_lock).format("HH:mm")
        val.submitted_from = 'W';

        if (val.arriving_from == 'shore') {
            val.length_unit = 'metre';
            if (val.vessel_type == 'Canoe' || val.vessel_type == 'Stand up paddle board') {
                val.boat_length = 0;
            }
        }

        console.log(val);

        this.setState({
            isSaving: true,
        });

        axios
            .post('/api/book_visit/save_book_visit', val)
            .then(response => {
                console.log('from handle submit', response);
                response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                this.setState({
                    isSaving: false,
                });
                this.props.history.push('/book-visits');
            });
    }

    componentDidMount() {
        // this.getBookVisit();
        // this.formRef.current.setFieldsValue({
        //     cost: 100,
        //   });
    }

    render() {

        return (

            <div style={{
                marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10
            }}
            >
                <Form
                    name="edit-book-visit-form"
                    ref={this.state.formRef}
                    layout="horizontal"
                    onFinish={this.saveBookVisit}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                >
                    <Row gutter={[16, 16]}>

                        <Col lg={{ span: (this.state.number_of_days && (this.state.home_port || this.state.arriving_from == 'shore') ? 12 : 14), offset: (this.state.number_of_days && (this.state.home_port || this.state.arriving_from == 'shore') ? 0 : 5) }} xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} >
                            <h3 style={{ borderBottom: '2px solid #1890FF', color: '#1890FF', paddingBottom: '6px', textAlign: 'center' }}>
                                {this.state.arriving_from ? <CompassFilled style={{ fontSize: '30px' }} /> : <CompassOutlined style={{ fontSize: '30px' }} />}<br />
                                Arriving From</h3>
                            <div style={{ backgroundColor: '#f8f8f8', borderBottom: '3px solid #fff', color: '#000', padding: 10, marginBottom: '15px' }}>
                                <Form.Item label="Arriving From" name="arriving_from" rules={[{ required: true, message: 'Arriving from is required' }]}>
                                    <Radio.Group 
                                    onChange={(e) => { this.updateField("arriving_from", e.target.value), this.selectArrivalFrom() }}>
                                        <Space direction="horizontal">
                                            <Radio value="sea">Sea</Radio>
                                            <Radio value="river">River</Radio>
                                            <Radio value="shore">Shore</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </div>
                            {this.state.arriving_from ?
                                <div>
                                    <h3 style={{ borderBottom: '2px solid #1890FF', color: '#1890FF', paddingBottom: '6px', textAlign: 'center' }}>
                                        {this.state.length_unit ? <ClockCircleFilled style={{ fontSize: '30px' }} /> : <ClockCircleOutlined style={{ fontSize: '30px' }} />}
                                        <br /> Visit Info</h3>
                                    <div style={{ backgroundColor: '#f8f8f8', borderBottom: '3px solid #fff', color: '#000', padding: 10, marginBottom: '15px' }}>
                                        {this.state.arriving_from == 'shore' ?
                                            <Form.Item label="Type of Craft" name="vessel_type" rules={[{ required: true, message: 'Type of craft is required' }]}>
                                                <Radio.Group onChange={(e) => this.updateField("vessel_type", e.target.value)}>
                                                    <Space direction="vertical">
                                                        <Radio value="Canoe">Canoe</Radio>
                                                        <Radio value="Stand up paddle board">Stand up paddle board</Radio>
                                                        <Radio value="Dinghy">Dinghy</Radio>
                                                        <Radio value="Motor boat">Motor boat</Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                            : ''}

                                        {this.state.arriving_from == 'shore' && (this.state.vessel_type == 'Dinghy' || this.state.vessel_type == 'Motor boat') ?
                                            <Form.Item label="Vessel Length (m)" name="boat_length" rules={[{ required: true, message: 'Length is required' }]} hasFeedback>
                                                <InputNumber placeholder="Please enter vessel length: Minimum is 4.0 m" min={4} style={{ width: '100%' }} onChange={(e) => this.updateField("boat_length", e)} />
                                            </Form.Item>
                                            : ''}

                                        {this.state.arriving_from != 'shore' ?
                                            <div>
                                                <Form.Item label="Length Unit" name="length_unit" rules={[{ required: true, message: 'Length unit is required' }]} >
                                                    <Radio.Group onChange={(e) => this.updateField("length_unit", e.target.value)}>
                                                        <Space direction="horizontal">
                                                            <Radio value="metre">Metre</Radio>
                                                            <Radio value="feet">Feet</Radio>
                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item>
                                                <Form.Item label="Boat Length" name="boat_length" rules={[{ required: true, message: 'Length is required' }]} hasFeedback>
                                                    <InputNumber placeholder="Please enter length" style={{ width: '100%' }} onChange={(e) => this.updateField("boat_length", e)} />
                                                </Form.Item>
                                                <Form.Item label="Hull Type" name="hull_type" rules={[{ required: true, message: 'Hull Type is required' }]} >
                                                    <Radio.Group onChange={(e) => this.updateField("hull_type", e.target.value)}>
                                                        <Space direction="horizontal">
                                                            <Radio value="mono_hull">Monohull</Radio>
                                                            <Radio value="multi_hull">Multihull</Radio>
                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>
                                            : ''}

                                        <Form.Item label="Arrival Date" name="arrival_date" rules={[{ required: true, message: 'Arrival date is required' }]} >
                                            <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} onChange={(e) => { this.getTides(e, 1), this.updateField("arrival_date", e) }} 
                                            disabledDate={ d => d.isBefore(this.state.arrival_min_date)}/>
                                            {/* {moment(this.state.arrival_min_date).format("YYYY-MM-DD")}
                                            {moment(this.state.departure_max_date).format("YYYY-MM-DD")} */}
                                        </Form.Item>



                                        {this.state.arriving_from == 'sea' ?
                                            <Form.Item label="Arrival Lock" name="arrival_lock">
                                                {this.state.arrival_date ?
                                                    <Radio.Group>
                                                        {this.state.arrivalLockSchedules.map((schedule, index) => {
                                                            return <Space direction="vertical" key={index} >
                                                                <Radio value={schedule.lock_date1.arrival_time}>{schedule.lock_date1.arrival_time}</Radio>
                                                                <Radio value={schedule.lock_date2.arrival_time}>{schedule.lock_date2.arrival_time}</Radio>
                                                                <Radio value={schedule.lock_date3.arrival_time}>{schedule.lock_date3.arrival_time}</Radio>
                                                            </Space>
                                                        })}
                                                    </Radio.Group>
                                                    : <Text type="danger">Select arrival date to load arrival locks</Text>}
                                            </Form.Item>
                                            : ''}

                                        {this.state.arriving_from != 'shore' ?
                                            <Form.Item label="Departure Date" name="departure_date" rules={[{ required: true, message: 'Departure date is required' }]} >
                                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} onChange={(e) => { this.getTides(e, 2), this.updateField("departure_date", e) }}
                                                disabledDate={ d => d.isBefore(this.state.arrival_date) ||  d.isAfter(this.state.departure_max_date)} />
                                            </Form.Item>
                                            : ''}

                                        {this.state.arriving_from == 'sea' ?
                                            <Form.Item label="Departure Lock" name="departure_lock">
                                                {this.state.departure_date ?
                                                    <Radio.Group>
                                                        {this.state.departureLockSchedules.map((schedule, index) => {
                                                            return <Space direction="vertical" key={index} >
                                                                <Radio value={schedule.lock_date1.departure_time}>{schedule.lock_date1.departure_time}</Radio>
                                                                <Radio value={schedule.lock_date2.departure_time}>{schedule.lock_date2.departure_time}</Radio>
                                                                <Radio value={schedule.lock_date3.departure_time}>{schedule.lock_date3.departure_time}</Radio>
                                                            </Space>
                                                        })}
                                                    </Radio.Group>
                                                    : <Text type="danger">Select departure date to load departure locks</Text>}
                                            </Form.Item>
                                            : ''}

                                        <div style={{ marginTop: '15px', marginBottom: '30px', textAlign: 'center' }}>
                                            {this.state.departure_date || this.state.arrival_date ?
                                                <div style={{ border: '2px solid #1890ff' }}>
                                                    <span style={{ color: '#1890ff', fontSize: '20px', fontWeight: 'bold' }}>
                                                        <i style={{ color: '#000' }}>Days:</i> {this.state.number_of_days} &nbsp;&nbsp;
                                                        <i style={{ color: '#000' }}>Cost is:</i> £ {this.state.cost}
                                                    </span>
                                                </div>
                                                : <Text type="danger">Select arrival / departure date to load cost of visit</Text>}
                                        </div>


                                        {/* <Form.Item label="Cost (£)" name="cost" rules={[{ required: true, message: 'Cost is required' }]}>
                                    <InputNumber style={{ width: '100%' }} onChange={(e) => this.updateField("cost", e)}
                                        value={this.state.cost} />
                                </Form.Item>
                                <Form.Item label="Number of Days" name="number_of_days" rules={[{ required: true, message: 'Number of Days is required' }]}>
                                    <InputNumber style={{ width: '100%' }} onChange={(e) => this.updateField("cost", e)} />
                                </Form.Item> */}
                                        {/* <Form.Item label="Number of Nights" name="number_of_nights" rules={[{ required: true, message: 'Number of nights is required' }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item> */}
                                    </div>
                                </div>
                                : ''}

                            {this.state.arriving_from && this.state.arriving_from != 'shore' ?
                                <div>
                                    <h3 style={{ borderBottom: '2px solid #1890FF', color: '#1890FF', paddingBottom: '6px', textAlign: 'center' }}>
                                        {this.state.home_port ? <PlayCircleFilled style={{ fontSize: '30px' }} /> : <PlayCircleOutlined style={{ fontSize: '30px' }} />}
                                        <br /> Boat Info</h3>
                                    <div style={{ backgroundColor: '#f8f8f8', borderBottom: '3px solid #fff', color: '#000', padding: 10, marginBottom: '15px' }}>

                                        <Form.Item label="Air Draft Unit" name="air_draft_unit" rules={[{ required: true, message: 'Air draft unit is required' }]}>
                                            <Radio.Group>
                                                <Space direction="horizontal">
                                                    <Radio value="metre">Metre</Radio>
                                                    <Radio value="feet">Feet</Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item label="Air Draft" name="air_draft" rules={[{ required: true, message: 'Air draft is required' }]} hasFeedback>
                                            <InputNumber placeholder="Please enter air draft" style={{ width: '100%' }} />
                                        </Form.Item>
                                        <Form.Item label="Boat Name" name="boat_name" rules={[{ required: true, message: 'Boat name is required' }]} hasFeedback>
                                            <Input placeholder="Please enter boat name" />
                                        </Form.Item>
                                        <Form.Item label="Home Port" name="home_port" rules={[{ required: true, message: 'Home port is required' }]} hasFeedback >
                                            <Input placeholder="Please enter home_port" onChange={(e) => this.updateField("home_port", e.target.value)} />
                                        </Form.Item>
                                    </div>
                                </div>
                                : ''}
                        </Col>

                        {this.state.number_of_days && (this.state.arriving_from == 'shore' || this.state.home_port) ?
                            <Col lg={12} sm={24} xs={24}>
                                <h3 style={{ borderBottom: '2px solid #1890FF', color: '#1890FF', paddingBottom: '6px', textAlign: 'center' }}>
                                    {this.state.title ? <PhoneFilled style={{ fontSize: '30px' }} /> : <PhoneOutlined style={{ fontSize: '30px' }} />}
                                    <br /> Contact Info</h3>
                                <div style={{ backgroundColor: '#f8f8f8', borderBottom: '3px solid #fff', color: '#000', padding: 10, marginBottom: '15px' }}>

                                    <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Title is required' }]} >
                                        <Select placeholder="Please select title" onChange={(e) => this.updateField("title", e)}>
                                            <Select.Option value='Mr' key='0'>Mr</Select.Option>
                                            <Select.Option value='Mrs' key='1'>Mrs</Select.Option>
                                            <Select.Option value='Ms' key='2'>Ms</Select.Option>
                                            <Select.Option value='Dr' key='3'>Dr</Select.Option>
                                            <Select.Option value='Rev' key='4'>Rev</Select.Option>
                                            <Select.Option value='Sir' key='5'>Sir</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="First Name" name="first_name" rules={[{ required: true, message: 'First name is required' }]} hasFeedback>
                                        <Input placeholder="Please enter first name" />
                                    </Form.Item>
                                    <Form.Item label="Surname" name="surname" rules={[{ required: true, message: 'Surname is required' }]} hasFeedback>
                                        <Input placeholder="Please enter surname" />
                                    </Form.Item>
                                    <Form.Item label="Home Address" style={{ marginBottom: 0 }}>
                                        <Form.Item name="address_line1" rules={[{ required: true, message: 'Address line1 is required' }]}
                                            style={{ display: 'inline-block', width: 'calc(30% - 2px)' }} hasFeedback>
                                            <Input placeholder="Please enter address line1" />
                                        </Form.Item>
                                        <Form.Item name="address_line2" rules={[{ required: true, message: 'Address line2 is required' }]}
                                            style={{ display: 'inline-block', width: 'calc(70% - 2px)' }} hasFeedback>
                                            <Input placeholder="Please enter address line2" />
                                        </Form.Item>
                                        <Form.Item name="address_line3" hasFeedback>
                                            <Input placeholder="Please enter address line3" />
                                        </Form.Item>
                                    </Form.Item>
                                    <Form.Item label="Postcode" name="postcode" rules={[{ required: true, message: 'Postcode is required' }]} hasFeedback>
                                        <Input placeholder="Please enter postcode" />
                                    </Form.Item>
                                    <Form.Item label="Email" name="email" rules={[{ type: 'email', required: true, message: 'Email is required' }]} hasFeedback>
                                        <Input placeholder="Please enter email" />
                                    </Form.Item>
                                    <Form.Item label="Mobile No" name="mobile_no" rules={[{ required: true, message: 'Mobile no is required' }]} hasFeedback>
                                        <Input placeholder="Please enter mobile no" />
                                    </Form.Item>
                                </div>

                                <h3 style={{ borderBottom: '2px solid #1890FF', color: '#1890FF', paddingBottom: '6px', textAlign: 'center' }}>
                                    {this.state.isAgree ? <CheckCircleFilled style={{ fontSize: '30px' }} /> : <CheckCircleOutlined style={{ fontSize: '30px' }} />}
                                    <br /> Declaration & Confirmation</h3>
                                <div style={{ textAlign: 'justify' }}>
                                    <p style={{ color: '#666', fontSize: '15px' }}>Clicking "Confirm" below indicates that I am aware of the following: navigation in the City Docks is under the control of the Harbour Master.
                                        By being named above as responsible for the vessel I assume responsibility for ensuring that the vessel complies with all by-laws and
                                        directions of the Harbour Master and his staff.</p>
                                    <p style={{ color: '#666', fontSize: '15px' }}>I acknowledge that there is a speed limit of 6 knots in the Floating Harbour from Cumberland Basin to Totterdown Lock and
                                        4 knots in the Feeder Canal and River above Netham Weir. </p>
                                    <br />
                                    <Checkbox onChange={(e) => this.checkAgree(e)} >
                                        <strong>I have read and agree to <a href="https://live.myhomeport.info/privacy_policy" target="blank">Bristol City Councils Privacy Policy</a></strong>
                                    </Checkbox>
                                    <br />
                                    <br />
                                    <div className="site-button-ghost-wrapper">
                                        <Button disabled={!this.state.isAgree} type="primary" htmlType="submit" loading={this.state.isSaving} style={{ width: '100%', height: '40px' }}>
                                            <CheckOutlined /> Confirm
                                        </Button>
                                    </div>
                                </div>

                            </Col>
                            : ''}
                    </Row>
                </Form>

            </div >

        );
    }
}
export default BookVisit;