import React from 'react';
import { connect } from 'react-redux';
import { PageHeader } from 'antd';
import { fetchLocks } from '../../actions';
import Locks from '../shared/LockClosures';
import { permissionChecker } from '../../utils/permission';

function LocksContainer(props) {
    const { locks, fetchLocks, history, role, permissions } = props;

    const fetchData = (page, query) => {
        fetchLocks(page, query);
    };
    const hasPermission = permissionChecker(role, permissions);

    const prosToPass = {
        locks,
        fetchData,
        hasPermission,
        ...props,
    };

    return (
        <PageHeader title="Lock Closures" className="locks-page-header" onBack={() => history.goBack()}>
            <Locks {...prosToPass} />
        </PageHeader>
    );
}

const mapStateToProps = (state, ownProps) => {
    const {
        locks,
        me: { role, permissions, marina },
    } = state;

    return {
        ...ownProps,
        role,
        permissions,
        locks,
        marina,
    };
};

export default connect(mapStateToProps, {
    fetchLocks,
})(LocksContainer);
