import React, {
    useEffect, useRef,
} from 'react';
import Frame from 'react-frame-component';

const ddcOrigin = process.env.WP_DDC_ORIGIN;

const DDC = ({
    bin,
    jwt,
    action,
    onMessage,
}) => {
    const formRef = useRef();
    const timedOut = useRef(false);
    const timerRef = useRef();

    useEffect(() => {
        const timer = setTimeout(() => {
            timedOut.current = true;
            onMessage({});
        }, 10 * 1000); // 10s
        timerRef.current = timer;

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const msgHandler = (e) => {
            if (e.origin !== ddcOrigin || timedOut.current) {
                return;
            }

            clearTimeout(timerRef.current);
            onMessage(e.data || {});
        };
        window.addEventListener('message', msgHandler);
        return () => {
            window.removeEventListener('message', msgHandler);
        };
    }, []);

    const onFormRendered = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    };

    return (
        <Frame
            height={1}
            width={1}
            style={{
                display: 'none',
            }}
            contentDidMount={onFormRendered}
        >
            <form id="ddc-form" name="ddc" method="POST" action={action} ref={formRef}>
                <input type="hidden" name="Bin" value={bin} />
                <input type="hidden" name="JWT" value={jwt} />
            </form>
        </Frame>
    );
};

export default DDC;
