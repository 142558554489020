import React, {
    useRef,
} from 'react';
import Frame from 'react-frame-component';

const Challenge = ({
    jwt,
    action,
    token,
    transactionRef,
}) => {
    const formRef = useRef();

    const onFormRendered = () => {
        if (formRef.current) {
            formRef.current.submit();
        }
    };

    return (
        <Frame
            height={600}
            width={400}
            contentDidMount={onFormRendered}
        >
            <form id="challenge-form" name="challenge" method="POST" action={action} ref={formRef}>
                <input type="hidden" name="JWT" value={jwt} />
                <input type="hidden" name="MD" value={`token=${token}&transaction_ref=${transactionRef}`} />
            </form>
        </Frame>
    );
};

export default Challenge;
