import React from 'react';
import { connect } from 'react-redux';
import { PageHeader } from 'antd';
import dayjs from 'dayjs';
import {
    fetchCillHeights,
} from '../../actions';
import CillHeights from '../shared/CillHeights';
import { permissionChecker } from '../../utils/permission';

function CillHeightsContainer(props) {
    const {
        cillHeights,
        fetchCillHeights,
        history,
        role,
        permissions,
    } = props;

    const fetchData = (page, query) => {
        const from = dayjs().format('DD/MM/YYYY');
        fetchCillHeights(page, query, from);
    };
    const hasPermission = permissionChecker(role, permissions);

    const prosToPass = {
        cillHeights,
        fetchData,
        hasPermission,
        ...props,
    };

    return (
        <PageHeader
            title="Cill heights"
            className="locks-page-header"
            onBack={() => history.goBack()}
        >
            <CillHeights {...prosToPass} />
        </PageHeader>
    );
}

const mapStateToProps = (state, ownProps) => {
    const { cillHeights, me: { role, permissions } } = state;

    return {
        ...ownProps,
        role,
        permissions,
        cillHeights,
    };
};

export default connect(mapStateToProps, {
    fetchCillHeights,
})(CillHeightsContainer);
