import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Table, Menu, Drawer, Button, DatePicker, Form, Row, Col, Badge, Label, Input, Select, TimePicker, Divider, Checkbox, message, Switch, Typography, Collapse } from 'antd';
import { BarsOutlined, RiseOutlined, ClockCircleOutlined, FileDoneOutlined, PlusOutlined, SaveOutlined, FormOutlined, FieldTimeOutlined, CloseOutlined, CloseCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Link } from 'react-router-dom';


const { Text } = Typography;
const { Panel } = Collapse;

class HouseDiary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locks: [],
            tides: [],
            actualTides: [],
            bookVisits: [],
            lockScheduleBookVisits: [],
            lockScheduleExemptions: [],
            swingBookings: [],
            lockSchedules: [],
            exemptions: [],

            selectedDate: new Date(),
            // selectedDate: 'Thu Sept 30 2020 14:42:31 GMT+0530 (India Standard Time)',
            // selectedDate: 'Thu Apr 20 2022 14:42:31 GMT+0530 (India Standard Time)',
            selectedLockSchedule: null,
            selectedLockScheduleType: null,
            drawerVisible: false,
            actualTideDrawerVisible: false,
            selectedTide: null,
            isSaving: false,
            actualTideHeights: [],
            isSigmaSet: false,
            editedSwingType: null

        };

        this.getLocks = this.getLocks.bind(this);
        this.getTides = this.getTides.bind(this);
        this.getVisitLockScehdules = this.getVisitLockScehdules.bind(this);
        this.getBookVisits = this.getBookVisits.bind(this);
        this.getLockScheduleBookVisits = this.getLockScheduleBookVisits.bind(this);
        this.getLockScheduleExemptions = this.getLockScheduleExemptions.bind(this);
        this.getSwingBookings = this.getSwingBookings.bind(this);
        this.getExemptions = this.getExemptions.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.openDrawer = this.openDrawer.bind(this);
        this.filterRecords = this.filterRecords.bind(this);
        this.openActualTideDrawer = this.openActualTideDrawer.bind(this);
        this.closeActualTideDrawer = this.closeActualTideDrawer.bind(this);
        this.saveActualTide = this.saveActualTide.bind(this);
        this.checkSigmaSet = this.checkSigmaSet.bind(this);
        this.checkSigmaSetLine = this.checkSigmaSetLine.bind(this);
        this.clickEditBridgeSwingTime = this.clickEditBridgeSwingTime.bind(this);
        this.closeEditBridgeSwingTime = this.closeEditBridgeSwingTime.bind(this);
        this.updateBridgeSwingTime = this.updateBridgeSwingTime.bind(this);
    }

    closeDrawer() {
        this.setState({
            drawerVisible: false
        });
    };
    openDrawer(date, time, type) {
        this.setState({
            drawerVisible: true,
            selectedLockSchedule: time,
            selectedLockScheduleType: type
        });
        this.getLockScheduleBookVisits(date, time, type);
        this.getLockScheduleExemptions(date, time, type);
    };

    closeActualTideDrawer() {
        this.setState({
            actualTideDrawerVisible: false,
            selectedTide: null
        });
    };
    openActualTideDrawer(tide) {
        this.setState({
            actualTideDrawerVisible: true,
            selectedTide: null,
            selectedTide: tide
        });
        // this.getLockScheduleBookVisits(date, time, type);
    };

    clickEditBridgeSwingTime(tide, type) {
        this.setState({
            editedSwingType: type,
            selectedTide: tide
        });
    };

    closeEditBridgeSwingTime() {
        this.setState({
            editedSwingType: null,
            selectedTide: null
        });
    };

    checkSigmaSetLine(e, index) {
        let tides = this.state.tides
        tides[index].is_sigma_set = e.target.checked;
        this.setState({ tides: tides })
    }

    updateTideField(name, e, index) {
        let tides = this.state.tides
        tides[index][name] = e;
        this.setState({ tides: tides }, () => {
            console.log(this.state.tides)
        })
    }

    getLocks() {
        let locks = [];
        // return axios.get('/api/lock-closures?page=1&query=' + (moment(this.state.selectedDate).format("DD/MM/YYYY"))).then((
        return axios.get('/api/house_diary/get_lock_closures?date=' + (moment(this.state.selectedDate).format("YYYY-MM-DD"))).then((
            response
        ) => {
            if (response.data.length) {
                // locks = response.data.data;
                locks = response.data;
            }
            this.setState({
                locks: locks
            });
        });
    }

    getTides() {
        let tides = [];
        // return axios.post('/api/tides?page=1&station_id=345&query=' + (moment(this.state.selectedDate).format("DD/MM/YYYY"))).then((
        return axios.get('/api/house_diary/get_tides?date=' + (moment(this.state.selectedDate).format("YYYY-MM-DD"))).then((
            response
        ) => {
            if (response.data.length) {
                // tides = response.data.data;
                tides = response.data;
                this.getVisitLockScehdules(tides);
            }
            this.setState({
                tides: tides
            });
        });
    }

    getVisitLockScehdules(tides) {
        let lockSchedules = [];
        return axios.post('/api/house_diary/get_book_visit_lock_scehdules', {
            tides: tides
        }).then((
            response
        ) => {
            if (response.data.length) {
                lockSchedules = response.data;
            }
            this.setState({
                lockSchedules: lockSchedules
            });

        });
    }

    getBookVisits() {
        let bookVisits = [];
        return axios.post('/api/book_visit/get_book_visits', {
            day: moment(this.state.selectedDate).format("YYYY-MM-DD"),
            is_approved: true
        }).then((
            response
        ) => {
            if (response.data.length) {
                bookVisits = response.data;
            }
            this.setState({
                bookVisits: bookVisits
            });
        });
    }

    getLockScheduleBookVisits(date, time, type) {
        let lockScheduleBookVisits = [];
        return axios.post('/api/house_diary/get_book_visits', {
            date: moment(date).format("YYYY-MM-DD"),
            lock_time: time,
            type: type,
            is_approved: true
        }).then((
            response
        ) => {
            if (response.data.length) {
                lockScheduleBookVisits = response.data
            }
            this.setState({
                lockScheduleBookVisits: lockScheduleBookVisits
            });
        });
    }

    getLockScheduleExemptions(date, time, type) {
        let lockScheduleExemptions = [];
        return axios.post('/api/house_diary/get_harbour_master_exemptions', {
            date: moment(date).format("YYYY-MM-DD"),
            lock_time: time,
            type: type
        }).then((
            response
        ) => {
            if (response.data.length) {
                lockScheduleExemptions = response.data
            }
            this.setState({
                lockScheduleExemptions: lockScheduleExemptions
            });
        });
    }

    getSwingBookings() {
        let swingBookings = [];
        let columns = [];
        return axios.post('/api/swings/get_swing_bookings', {
            day: moment(this.state.selectedDate).format("YYYY-MM-DD")
        }).then((
            response
        ) => {
            if (response.data.length) {
                swingBookings = response.data;
            }
            this.setState({
                swingBookings: swingBookings,
            });
        });
    }

    getExemptions() {
        let exemptions = [];
        return axios.post('/api/house_diary/get_harbour_master_exemptions', {
            date: moment(this.state.selectedDate).format("YYYY-MM-DD")
        }).then((
            response
        ) => {
            if (response.data.length) {
                exemptions = response.data;
            }
            this.setState({
                exemptions: exemptions
            });
        });
    }

    filterRecords(e) {
        this.setState({
            selectedDate: e,

        }, () => {
            // alert(e)
            this.getLocks();
            this.getTides();
            this.getSwingBookings();
            this.getBookVisits();
            this.getExemptions();
        });

    };

    checkSigmaSet(e) {
        this.setState({ isSigmaSet: e.target.checked });
    }

    saveActualTide(val) {
        val.actual_tide_time = moment(val.actual_tide_time).format("HH:mm");
        val.actual_tide_date = moment(this.state.selectedTide.date).format("YYYY-MM-DD");
        val.tide_id = this.state.selectedTide.id;
        val.actual_tide_id = this.state.selectedTide.actual_tide_id;

        // val.is_sigma_set = this.state.isSigmaSet;
        // // val.is_sigma_set = val.is_sigma_set;

        // val.plymsol_bridge_swing1_open_time = val.plymsol_bridge_swing1_open_time ? moment(val.plymsol_bridge_swing1_open_time).format("HH:mm") : null;
        // val.plymsol_bridge_swing1_close_time = val.plymsol_bridge_swing1_close_time ? moment(val.plymsol_bridge_swing1_close_time).format("HH:mm") : null;
        // val.plymsol_bridge_swing2_open_time = val.plymsol_bridge_swing2_open_time ? moment(val.plymsol_bridge_swing2_open_time).format("HH:mm") : null;
        // val.plymsol_bridge_swing2_close_time = val.plymsol_bridge_swing2_close_time ? moment(val.plymsol_bridge_swing2_close_time).format("HH:mm") : null;
        // val.plymsol_bridge_swing3_open_time = val.plymsol_bridge_swing3_open_time ? moment(val.plymsol_bridge_swing3_open_time).format("HH:mm") : null;
        // val.plymsol_bridge_swing3_close_time = val.plymsol_bridge_swing3_close_time ? moment(val.plymsol_bridge_swing3_close_time).format("HH:mm") : null;

        // val.junction_bridge_swing1_open_time = val.junction_bridge_swing1_open_time ? moment(val.junction_bridge_swing1_open_time).format("HH:mm") : null;
        // val.junction_bridge_swing1_close_time = val.junction_bridge_swing1_close_time ? moment(val.junction_bridge_swing1_close_time).format("HH:mm") : null;
        // val.junction_bridge_swing2_open_time = val.junction_bridge_swing2_open_time ? moment(val.junction_bridge_swing2_open_time).format("HH:mm") : null;
        // val.junction_bridge_swing2_close_time = val.junction_bridge_swing2_close_time ? moment(val.junction_bridge_swing2_close_time).format("HH:mm") : null;
        // val.junction_bridge_swing3_open_time = val.junction_bridge_swing3_open_time ? moment(val.junction_bridge_swing3_open_time).format("HH:mm") : null;
        // val.junction_bridge_swing3_close_time = val.junction_bridge_swing3_close_time ? moment(val.junction_bridge_swing3_close_time).format("HH:mm") : null;


        this.setState({
            actualTide: val,
            isSaving: true,
        });
        console.log(val)
        axios
            .post('/api/house_diary/save_actual_tide', val)
            .then(response => {
                console.log('from handle submit', response);
                response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                this.setState({
                    isSaving: false,
                });
                this.closeActualTideDrawer();
                this.getTides();
            });
    }

    updateBridgeSwingTime(val, type) {

        val.is_sigma_set = val.is_sigma_set ? val.is_sigma_set : false;
        val.tide_id = this.state.selectedTide.id;
        val.plymsol_bridge_swing1_open_time = moment(val.plymsol_bridge_swing1_open_time).isValid() ? moment(val.plymsol_bridge_swing1_open_time).format("HH:mm") : val.plymsol_bridge_swing1_open_time;
        val.plymsol_bridge_swing1_close_time = moment(val.plymsol_bridge_swing1_close_time).isValid() ? moment(val.plymsol_bridge_swing1_close_time).format("HH:mm") : val.plymsol_bridge_swing1_close_time;
        val.plymsol_bridge_swing2_open_time = moment(val.plymsol_bridge_swing2_open_time).isValid() ? moment(val.plymsol_bridge_swing2_open_time).format("HH:mm") : val.plymsol_bridge_swing2_open_time;
        val.plymsol_bridge_swing2_close_time = moment(val.plymsol_bridge_swing2_close_time).isValid() ? moment(val.plymsol_bridge_swing2_close_time).format("HH:mm") : val.plymsol_bridge_swing2_close_time;
        val.plymsol_bridge_swing3_open_time = moment(val.plymsol_bridge_swing3_open_time).isValid() ? moment(val.plymsol_bridge_swing3_open_time).format("HH:mm") : val.plymsol_bridge_swing3_open_time;
        val.plymsol_bridge_swing3_close_time = moment(val.plymsol_bridge_swing3_close_time).isValid() ? moment(val.plymsol_bridge_swing3_close_time).format("HH:mm") : val.plymsol_bridge_swing3_close_time,

            val.junction_bridge_swing1_open_time = moment(val.junction_bridge_swing1_open_time).isValid() ? moment(val.junction_bridge_swing1_open_time).format("HH:mm") : val.junction_bridge_swing1_open_time;
        val.junction_bridge_swing1_close_time = moment(val.junction_bridge_swing1_close_time).isValid() ? moment(val.junction_bridge_swing1_close_time).format("HH:mm") : val.junction_bridge_swing1_close_time;
        val.junction_bridge_swing2_open_time = moment(val.junction_bridge_swing2_open_time).isValid() ? moment(val.junction_bridge_swing2_open_time).format("HH:mm") : val.junction_bridge_swing2_open_time;
        val.junction_bridge_swing2_close_time = moment(val.junction_bridge_swing2_close_time).isValid() ? moment(val.junction_bridge_swing2_close_time).format("HH:mm") : val.junction_bridge_swing2_close_time;
        val.junction_bridge_swing3_open_time = moment(val.junction_bridge_swing3_open_time).isValid() ? moment(val.junction_bridge_swing3_open_time).format("HH:mm") : val.junction_bridge_swing3_open_time;
        val.junction_bridge_swing3_close_time = moment(val.junction_bridge_swing3_close_time).isValid() ? moment(val.junction_bridge_swing3_close_time).format("HH:mm") : val.junction_bridge_swing3_close_time;


        this.setState({
            isSaving: true,
        });
        console.log(val)
        axios
            .post('/api/house_diary/update_bridge_swing_time', val)
            .then(response => {
                console.log('from handle submit', response);
                response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                this.setState({
                    isSaving: false,
                    selectedTide: null,
                    editedSwingType: null,
                });
                this.getTides();
            });
    }

    componentDidMount() {
        this.getLocks();
        this.getTides();
        this.getSwingBookings();
        this.getBookVisits();
        this.getExemptions();

        let actualTideHeights = [];
        for (let i = 6.5; i <= 15; (i += 0.1)) {
            actualTideHeights.push(i.toFixed(1));
        }
        // console.log(actualTideHeights)
        this.setState({
            actualTideHeights: actualTideHeights
        })
    }

    render() {

        return (

            <div style={{
                marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10
            }}
            >

                <Menu mode="horizontal" style={{ textAlign: 'center' }}>
                    <Menu.Item key="app0" >
                        <DatePicker value={moment(this.state.selectedDate)} format="YYYY-MM-DD" onChange={this.filterRecords} />
                    </Menu.Item>
                    <Menu.Item key="app1" >
                        {moment(this.state.selectedDate).format("dddd")}
                    </Menu.Item>

                    {/* <Menu.Item key="app1" icon={<BarsOutlined />} onClick={this.getLocks}>
                        Lock Closures
                    </Menu.Item>
                    <Menu.Item key="app2" icon={<RiseOutlined />} onClick={this.getTides}>
                        Tides Times
                    </Menu.Item>
                    <Menu.Item key="app3" icon={<ClockCircleOutlined />} onClick={this.getSwingBookings}>
                        Swing Bookings
                    </Menu.Item>
                    <Menu.Item key="app4" icon={<FileDoneOutlined />} onClick={this.getBookVisits}>
                        Book Visits
                    </Menu.Item> */}
                </Menu>

                <Collapse defaultActiveKey={['1', '2', '3', '4', '5', '6']} >
                    <Panel header={"Predicted Tidal Information: " + moment(this.state.selectedDate).format('dddd') + " " + moment(this.state.selectedDate).format("YYYY-MM-DD")} key="2">
                        {/* <RiseOutlined style={{ fontSize: 30 }} /> */}
                        {this.state.tides.length ?
                            <Row >
                                <Col lg={24} xs={24} sm={24} >
                                    <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                {this.state.tides.map((tide, index2) => {
                                                    return <tr key={index2}>
                                                        <td style={{ minWidth: '120px' }}>{index2 + 1}. {tide.event_type === 'HighWater' ? 'High Water' : 'Low Water'}</td>
                                                        <td style={{ color: '#000000', width: '120px' }}>Time: {tide.time}</td>
                                                        <td style={{ color: '#1890ff', width: '120px' }}>Height: {tide.tide_height}</td>
                                                        <td style={{ color: '#1890ff', width: '40px' }}></td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                            : <Text type="danger">No predicted tides available for selected date</Text>}
                    </Panel>
                    <Panel header={"Enter Actual Tidal Information: " + moment(this.state.selectedDate).format('dddd') + " " + moment(this.state.selectedDate).format("YYYY-MM-DD")} key="5">
                        {/* <RiseOutlined style={{ fontSize: 30 }} /> */}
                        {this.state.tides.length ?
                            <Row >
                                <Col lg={24} xs={24} sm={24} >
                                    <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                        <table style={{ width: '100%' }}>

                                            {this.state.tides.map((tide, index2) => {
                                                return <tbody key={index2} style={{ borderBottom: '15px solid #fff', paddingBottom: 10 }}>
                                                    <tr style={{ color: '#000000' }}>
                                                        <td style={{ verticalAlign: 'top', minWidth: 120 }}>
                                                            {index2 + 1}. {tide.event_type === 'HighWater' ? 'High Water' : 'Low Water'}
                                                        </td>
                                                        <td style={{ color: '#000000', width: '120px', verticalAlign: 'top' }}>Time: {tide.actual_tide_time}</td>
                                                        <td style={{ color: '#1890ff', width: '120px', verticalAlign: 'top' }}>Height: {tide.actual_tide_height}</td>
                                                        <td style={{ color: '#1890ff', width: '40px', verticalAlign: 'top' }}>
                                                            <Button type="text" danger shape="circle" icon={<FormOutlined />}
                                                                onClick={() => this.openActualTideDrawer(tide)} />
                                                        </td>
                                                    </tr>
                                                    <tr >
                                                        <td colSpan={4} style={{ paddingTop: 10, backgroundColor: '#fff', padding: 10 }}>
                                                            <label style={{ color: '#1890ff', fontStyle: 'italic' }}><Checkbox onChange={(e) => this.checkSigmaSetLine(e, index2)}
                                                                checked={tide.is_sigma_set} /> Sigma Set</label>
                                                            <Row style={{ color: '#777', fontSize: '12px', marginTop: 15 }}>

                                                                <Col lg={12} xs={24} sm={24}  >
                                                                    <h4 style={{ color: '#1890ff', fontStyle: 'italic' }}>Plymsol Bridge Swing Times</h4>
                                                                    {this.state.editedSwingType == 1 ?
                                                                        <div>
                                                                            <TimePicker style={{ width: 100 }} inputReadOnly value={tide.plymsol_bridge_swing1_open_time ? moment(tide.plymsol_bridge_swing1_open_time, 'HH:mm') : ''}
                                                                                onChange={(e) => this.updateTideField("plymsol_bridge_swing1_open_time", e, index2)} placeholder='Swing1 Open' format={'HH:mm'} />
                                                                            <TimePicker style={{ width: 100 }} inputReadOnly value={tide.plymsol_bridge_swing1_close_time ? moment(tide.plymsol_bridge_swing1_close_time, 'HH:mm') : ''}
                                                                                onChange={(e) => this.updateTideField("plymsol_bridge_swing1_close_time", e, index2)} placeholder='Swing1 Close' format={'HH:mm'} />
                                                                            <Button type="text" danger shape="circle" icon={<SaveOutlined />}
                                                                                onClick={() => this.updateBridgeSwingTime(tide, 1)} />
                                                                            <Button type="text" shape="circle" icon={<CloseCircleOutlined />}
                                                                                onClick={() => this.closeEditBridgeSwingTime()} />
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            Swing1 &nbsp; Open: {tide.plymsol_bridge_swing1_open_time} - Close: {tide.plymsol_bridge_swing1_close_time}
                                                                            <Button type="text" danger shape="circle" icon={<FormOutlined />}
                                                                                onClick={() => this.clickEditBridgeSwingTime(tide, 1)} />
                                                                        </div>
                                                                    }
                                                                    {this.state.editedSwingType == 2 ?
                                                                        <div>
                                                                            <TimePicker style={{ width: 100 }} inputReadOnly value={tide.plymsol_bridge_swing2_open_time ? moment(tide.plymsol_bridge_swing2_open_time, 'HH:mm') : ''}
                                                                                onChange={(e) => this.updateTideField("plymsol_bridge_swing2_open_time", e, index2)} placeholder=' Swing2 Open' format={'HH:mm'} />
                                                                            <TimePicker style={{ width: 100 }} inputReadOnly value={tide.plymsol_bridge_swing2_close_time ? moment(tide.plymsol_bridge_swing2_close_time, 'HH:mm') : ''}
                                                                                onChange={(e) => this.updateTideField("plymsol_bridge_swing2_close_time", e, index2)} placeholder=' Swing2 Close' format={'HH:mm'} />
                                                                            <Button type="text" danger shape="circle" icon={<SaveOutlined />}
                                                                                onClick={() => this.updateBridgeSwingTime(tide, 2)} />
                                                                            <Button type="text" shape="circle" icon={<CloseCircleOutlined />}
                                                                                onClick={() => this.closeEditBridgeSwingTime()} />
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            Swing2 &nbsp; Open: {tide.plymsol_bridge_swing2_open_time} - Close: {tide.plymsol_bridge_swing2_close_time}
                                                                            <Button type="text" danger shape="circle" icon={<FormOutlined />}
                                                                                onClick={() => this.clickEditBridgeSwingTime(tide, 2)} />
                                                                        </div>
                                                                    }
                                                                    {this.state.editedSwingType == 3 ?
                                                                        <div>
                                                                            <TimePicker style={{ width: 100 }} inputReadOnly value={tide.plymsol_bridge_swing3_open_time ? moment(tide.plymsol_bridge_swing3_open_time, 'HH:mm') : ''}
                                                                                onChange={(e) => this.updateTideField("plymsol_bridge_swing3_open_time", e, index2)} placeholder=' Swing3 Open' format={'HH:mm'} />
                                                                            <TimePicker style={{ width: 100 }} inputReadOnly value={tide.plymsol_bridge_swing3_close_time ? moment(tide.plymsol_bridge_swing3_close_time, 'HH:mm') : ''}
                                                                                onChange={(e) => this.updateTideField("plymsol_bridge_swing3_close_time", e, index2)} placeholder='Swing3 Close' format={'HH:mm'} />
                                                                            <Button type="text" danger shape="circle" icon={<SaveOutlined />}
                                                                                onClick={() => this.updateBridgeSwingTime(tide, 3)} />
                                                                            <Button type="text" shape="circle" icon={<CloseCircleOutlined />}
                                                                                onClick={() => this.closeEditBridgeSwingTime()} />
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            Swing3 &nbsp; Open: {tide.plymsol_bridge_swing3_open_time} - Close: {tide.plymsol_bridge_swing3_close_time}
                                                                            <Button type="text" danger shape="circle" icon={<FormOutlined />}
                                                                                onClick={() => this.clickEditBridgeSwingTime(tide, 3)} />
                                                                        </div>
                                                                    }
                                                                </Col>
                                                                <Col lg={12} xs={24} sm={24}  >
                                                                    <h4 style={{ color: '#1890ff', fontStyle: 'italic' }}>Junction Bridge Swing Times</h4>
                                                                    {this.state.editedSwingType == 4 ?
                                                                        <div>
                                                                            <TimePicker style={{ width: 100 }} inputReadOnly value={tide.junction_bridge_swing1_open_time ? moment(tide.junction_bridge_swing1_open_time, 'HH:mm') : ''}
                                                                                onChange={(e) => this.updateTideField("junction_bridge_swing1_open_time", e, index2)} placeholder=' Swing1 Open' format={'HH:mm'} />
                                                                            <TimePicker style={{ width: 100 }} inputReadOnly value={tide.junction_bridge_swing1_close_time ? moment(tide.junction_bridge_swing1_close_time, 'HH:mm') : ''}
                                                                                onChange={(e) => this.updateTideField("junction_bridge_swing1_close_time", e, index2)} placeholder=' Swing1 Close' format={'HH:mm'} />
                                                                            <Button type="text" danger shape="circle" icon={<SaveOutlined />}
                                                                                onClick={() => this.updateBridgeSwingTime(tide, 4)} />
                                                                            <Button type="text" shape="circle" icon={<CloseCircleOutlined />}
                                                                                onClick={() => this.closeEditBridgeSwingTime()} />
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            Swing1 &nbsp; Open: {tide.junction_bridge_swing1_open_time} - Close: {tide.junction_bridge_swing1_close_time}
                                                                            <Button type="text" danger shape="circle" icon={<FormOutlined />}
                                                                                onClick={() => this.clickEditBridgeSwingTime(tide, 4)} />
                                                                        </div>
                                                                    }
                                                                    {this.state.editedSwingType == 5 ?
                                                                        <div>
                                                                            <TimePicker style={{ width: 100 }} inputReadOnly value={tide.junction_bridge_swing2_open_time ? moment(tide.junction_bridge_swing2_open_time, 'HH:mm') : ''}
                                                                                onChange={(e) => this.updateTideField("junction_bridge_swing2_open_time", e, index2)} placeholder=' Swing2 Open' format={'HH:mm'} />
                                                                            <TimePicker style={{ width: 100 }} inputReadOnly value={tide.junction_bridge_swing2_close_time ? moment(tide.junction_bridge_swing2_close_time, 'HH:mm') : ''}
                                                                                onChange={(e) => this.updateTideField("junction_bridge_swing2_close_time", e, index2)} placeholder=' Swing2 Close' format={'HH:mm'} />
                                                                            <Button type="text" danger shape="circle" icon={<SaveOutlined />}
                                                                                onClick={() => this.updateBridgeSwingTime(tide, 5)} />
                                                                            <Button type="text" shape="circle" icon={<CloseCircleOutlined />}
                                                                                onClick={() => this.closeEditBridgeSwingTime()} />
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            Swing2 &nbsp; Open: {tide.junction_bridge_swing2_open_time} - Close: {tide.junction_bridge_swing2_close_time}
                                                                            <Button type="text" danger shape="circle" icon={<FormOutlined />}
                                                                                onClick={() => this.clickEditBridgeSwingTime(tide, 5)} />
                                                                        </div>
                                                                    }
                                                                    {this.state.editedSwingType == 6 ?
                                                                        <div>
                                                                            <TimePicker style={{ width: 100 }} inputReadOnly value={tide.junction_bridge_swing3_open_time ? moment(tide.junction_bridge_swing3_open_time, 'HH:mm') : ''}
                                                                                onChange={(e) => this.updateTideField("junction_bridge_swing3_open_time", e, index2)} placeholder=' Swing3 Open' format={'HH:mm'} />
                                                                            <TimePicker style={{ width: 100 }} inputReadOnly value={tide.junction_bridge_swing3_close_time ? moment(tide.junction_bridge_swing3_close_time, 'HH:mm') : ''}
                                                                                onChange={(e) => this.updateTideField("junction_bridge_swing3_close_time", e, index2)} placeholder=' Swing3 Close' format={'HH:mm'} />
                                                                            <Button type="text" danger shape="circle" icon={<SaveOutlined />}
                                                                                onClick={() => this.updateBridgeSwingTime(tide, 6)} />
                                                                            <Button type="text" shape="circle" icon={<CloseCircleOutlined />}
                                                                                onClick={() => this.closeEditBridgeSwingTime()} />
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            Swing3 &nbsp; Open: {tide.junction_bridge_swing3_open_time} - Close: {tide.junction_bridge_swing3_close_time}
                                                                            <Button type="text" danger shape="circle" icon={<FormOutlined />}
                                                                                onClick={() => this.clickEditBridgeSwingTime(tide, 6)} />
                                                                        </div>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            })}

                                        </table>
                                    </div>
                                </Col>
                            </Row>
                            : <Text type="danger">No actual tides available for selected date</Text>}
                    </Panel>
                    <Panel header={"Locks: " + moment(this.state.selectedDate).format('dddd') + " " + moment(this.state.selectedDate).format("YYYY-MM-DD")} key="1">
                        {/* <BarsOutlined style={{ fontSize: 30 }} /> */}
                        <Row>
                            <Col span={24} >
                                {this.state.locks.length ?
                                    <Row>
                                        <Col lg={12} xs={24} sm={24}  >
                                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <h3 style={{ color: '#1890ff', borderBottom: '1px solid #1890ff', paddingBottom: '5px' }}>Lock Activity</h3>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        {this.state.locks.map((lock, index1) => {
                                                            return <tr key={index1}>
                                                                <td>{index1 + 1}. Commences: {lock.lock_open}</td>
                                                                <td>Ceases: {lock.lock_close}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                        <Col lg={12} xs={24} sm={24} >
                                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <h3 style={{ color: '#1890ff', borderBottom: '1px solid #1890ff', paddingBottom: '5px' }}>Stop Gate</h3>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        {this.state.locks.map((lock, index1) => {
                                                            return <tr key={index1}>
                                                                <td>{index1 + 1}. Starts: {lock.lock_1_open}</td>
                                                                <td>Ends: {lock.lock_1_close}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                        {/* {this.state.locks.map((lock, index1) => {
                                        return <Col lg={12} xl={8}  >
                                            <div key={index1} style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <span style={{ position: 'absolute', top: '0px', left: '0px', fontSize: '10px', backgroundColor: '#1890ff', color: '#fff', padding: '2px 3px' }}>{index1 + 1}</span>
                                                <table>
                                                    <tr>
                                                        <td>Date: </td>
                                                        <td>{lock.day} {lock.date}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>Lock Activity: </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lock Open/Close: </td>
                                                        <td>{lock.lock_open} - {lock.lock_close}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Stop Gate Open/Close: </td>
                                                        <td>{lock.lock_1_open} - {lock.lock_1_close}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </Col>
                                    })} */}
                                    </Row>
                                    : <Text type="danger">No locks available for selected date</Text>}

                                {this.state.lockSchedules.length ?
                                    <Row>
                                        <Col lg={12} xs={24} sm={12}  >
                                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <h3 style={{ color: '#1890ff', borderBottom: '1px solid #1890ff', paddingBottom: '5px' }}>Departure Lock Schedules</h3>
                                                <table style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '50%', padding: '6px 0px' }}>Lock Time</th>
                                                            <th style={{ textAlign: 'center', width: '50%' }}>Departure Count</th>
                                                        </tr>
                                                    </thead>

                                                    {this.state.lockSchedules.map((schedule, index) => {
                                                        return <tbody key={index} style={{ width: '100%' }}>
                                                            <tr>
                                                                <td style={{ width: '50%', padding: '6px 0px' }}>{schedule.lock_date1.departure_time}</td>
                                                                <td style={{ width: '50%', textAlign: 'center', cursor: 'pointer' }} onClick={() => this.openDrawer(this.state.selectedDate, schedule.lock_date1.departure_time, 2)} >
                                                                    <Badge count={schedule.lock_date1.departure_lock_total_count ? schedule.lock_date1.departure_lock_total_count : '-'}
                                                                        style={schedule.lock_date1.departure_lock_total_count ? { backgroundColor: '#52c41a' } : { backgroundColor: '#FF4D4F' }} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: '50%', padding: '6px 0px' }}>{schedule.lock_date2.departure_time}</td>
                                                                <td style={{ width: '50%', textAlign: 'center', cursor: 'pointer' }} onClick={() => this.openDrawer(this.state.selectedDate, schedule.lock_date2.departure_time, 2)} >
                                                                    <Badge count={schedule.lock_date2.departure_lock_total_count ? schedule.lock_date2.departure_lock_total_count : '-'}
                                                                        style={schedule.lock_date2.departure_lock_total_count ? { backgroundColor: '#52c41a' } : { backgroundColor: '#FF4D4F' }} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: '50%', padding: '6px 0px' }}>{schedule.lock_date3.departure_time}</td>
                                                                <td style={{ width: '50%', textAlign: 'center', cursor: 'pointer' }} onClick={() => this.openDrawer(this.state.selectedDate, schedule.lock_date3.departure_time, 2)} >
                                                                    <Badge count={schedule.lock_date3.departure_lock_total_count ? schedule.lock_date3.departure_lock_total_count : '-'}
                                                                        style={schedule.lock_date3.departure_lock_total_count ? { backgroundColor: '#52c41a' } : { backgroundColor: '#FF4D4F' }} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    })}
                                                </table>
                                            </div>
                                        </Col>
                                        <Col lg={12} xs={24} sm={12}  >
                                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <h3 style={{ color: '#1890ff', borderBottom: '1px solid #1890ff', paddingBottom: '5px' }}>Arrival Lock Schedules</h3>
                                                <table style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '50%', padding: '6px 0px' }}>Lock Time</th>
                                                            <th style={{ textAlign: 'center', width: '50%' }}>Arrival Count</th>
                                                        </tr>
                                                    </thead>

                                                    {this.state.lockSchedules.map((schedule, index) => {
                                                        return <tbody key={index} style={{ width: '100%' }}>
                                                            <tr>
                                                                <td style={{ width: '50%', padding: '6px 0px' }}>{schedule.lock_date1.arrival_time}</td>
                                                                <td style={{ width: '50%', textAlign: 'center', cursor: 'pointer' }} onClick={() => this.openDrawer(this.state.selectedDate, schedule.lock_date1.arrival_time, 1)} >
                                                                    <Badge count={schedule.lock_date1.arrival_lock_total_count ? schedule.lock_date1.arrival_lock_total_count : '-'}
                                                                        style={schedule.lock_date1.arrival_lock_total_count ? { backgroundColor: '#52c41a' } : { backgroundColor: '#FF4D4F' }} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: '50%', padding: '6px 0px' }}>{schedule.lock_date2.arrival_time}</td>
                                                                <td style={{ width: '50%', textAlign: 'center', cursor: 'pointer' }} onClick={() => this.openDrawer(this.state.selectedDate, schedule.lock_date2.arrival_time, 1)} >
                                                                    <Badge count={schedule.lock_date2.arrival_lock_total_count ? schedule.lock_date2.arrival_lock_total_count : '-'}
                                                                        style={schedule.lock_date2.arrival_lock_total_count ? { backgroundColor: '#52c41a' } : { backgroundColor: '#FF4D4F' }} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ width: '50%', padding: '6px 0px' }}>{schedule.lock_date3.arrival_time}</td>
                                                                <td style={{ width: '50%', textAlign: 'center', cursor: 'pointer' }} onClick={() => this.openDrawer(this.state.selectedDate, schedule.lock_date3.arrival_time, 1)} >
                                                                    <Badge count={schedule.lock_date3.arrival_lock_total_count ? schedule.lock_date3.arrival_lock_total_count : '-'}
                                                                        style={schedule.lock_date3.arrival_lock_total_count ? { backgroundColor: '#52c41a' } : { backgroundColor: '#FF4D4F' }} />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    })}
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                    : <Text type="danger">No lock schedules available for selected date</Text>}
                            </Col>
                        </Row>
                    </Panel>

                    <Panel header={"Prince Street Swings Bookings: " + moment(this.state.selectedDate).format('dddd') + " " + moment(this.state.selectedDate).format("YYYY-MM-DD")} key="3">
                        {/* <ClockCircleOutlined style={{ fontSize: 30 }} /> */}
                        <Row >
                            <Col span={24} >
                                <Row>
                                    {!this.state.swingBookings.length ? <Text type="danger">No swing booking available for selected date</Text> : ''}
                                    {this.state.swingBookings.map((swingBooking, index3) => {
                                        return <Col lg={12} xl={8} xs={24} sm={24} key={index3} >
                                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <span style={{ position: 'absolute', top: '0px', left: '0px', fontSize: '10px', backgroundColor: '#1890ff', color: '#fff', padding: '2px 3px' }}>{index3 + 1}</span>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td>Date: </td>
                                                            <td>{swingBooking.day} @ {swingBooking.opened_time}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Travel Direction: </td>
                                                            <td>{swingBooking.travel_direction}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Vessel Name: </td>
                                                            <td>{swingBooking.vessel_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phone: </td>
                                                            <td>{swingBooking.phone}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </Col>
                                    })}
                                </Row>
                            </Col>
                        </Row>

                    </Panel>

                    <Panel header={"Booked Visits: " + moment(this.state.selectedDate).format('dddd') + " " + moment(this.state.selectedDate).format("YYYY-MM-DD")} key="4">
                        {/* <FileDoneOutlined style={{ fontSize: 30 }} /> */}

                        <Row >
                            <Col span={24} >
                                <Button type="primary" shape="circle" style={{ position: 'absolute', right: '0px', zIndex: 1, height: '30px', width: '30px' }}>
                                    <Link to={"/book-visit"}><PlusOutlined /></Link>
                                </Button>
                                {!this.state.bookVisits.length ? <Text type="danger">No booked visits available for selected date</Text> : ''}
                                <Row>

                                    {this.state.bookVisits.map((bookVisit, index4) => {
                                        return <Col lg={12} xl={8} xs={24} sm={24} key={index4}>
                                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <span style={{ position: 'absolute', top: '0px', left: '0px', fontSize: '10px', backgroundColor: '#1890ff', color: '#fff', padding: '2px 3px' }}>{index4 + 1}</span>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td>Boat Name: </td>
                                                            <td>{bookVisit.boat_name ? bookVisit.boat_name : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Name: </td>
                                                            <td>{bookVisit.title + '. ' + bookVisit.first_name + ' ' + bookVisit.surname}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phone: </td>
                                                            <td>{bookVisit.phone}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Email: </td>
                                                            <td>{bookVisit.email}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4} style={{ height: 15 }}></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Boat Length: </td>
                                                            <td>{bookVisit.boat_length ? (bookVisit.boat_length + ' ' + bookVisit.length_unit) : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Air Draft: </td>
                                                            <td>{bookVisit.air_draft ? (bookVisit.air_draft + ' ' + bookVisit.air_draft_unit) : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Arriving From: </td>
                                                            <td>{bookVisit.arriving_from}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Arrival Date: </td>
                                                            <td>{bookVisit.arrival_date ? (moment(bookVisit.arrival_date).format("YYYY-MM-DD") + ', ' + moment(bookVisit.arrival_date).format('dddd')) : 'TBC'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Arrival Lock: </td>
                                                            <td>{bookVisit.arrival_lock ? bookVisit.arrival_lock : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Departure Date: </td>
                                                            <td>{bookVisit.departure_date ? moment(bookVisit.departure_date).format("YYYY-MM-DD") + ', ' + moment(bookVisit.departure_date).format('dddd') : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Departure Lock: </td>
                                                            <td>{bookVisit.departure_lock ? bookVisit.departure_lock : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4} style={{ height: 15 }}></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Home Port: </td>
                                                            <td>{bookVisit.home_port ? bookVisit.home_port : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>No of Days: </td>
                                                            <td>{bookVisit.number_of_days}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cost: </td>
                                                            <td>{bookVisit.cost}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Payment Status: </td>
                                                            <td>
                                                                {bookVisit.payment_status  ? (<Text type={bookVisit.payment_status == 'sentForSettlement' ? 'success' : 'danger'}>{bookVisit.payment_status} @ {moment(bookVisit.created_at).format("YYYY-MM-DD")} (Ref No: {bookVisit.transaction_ref})</Text>)
                                                                : <Text type="danger">Not Paid</Text>}
                                                                </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4} style={{ height: 15 }}></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Booked Status: </td>
                                                            <td>
                                                                {bookVisit.is_approved && bookVisit.payment_status == 'sentForSettlement' ? <Text type="success">Approved</Text> : ( bookVisit.payment_status == 'refused' ? <Text type="danger">Refused</Text>
                                                                    : (bookVisit.is_approved && !bookVisit.payment_status ? <Text type="warning">Pending</Text> : <Text type="danger">Cancelled</Text>))}
                                                            
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Booked From: </td>
                                                            <td>{bookVisit.submitted_from == 'W' ? 'Web' : 'Mobile App'}</td>
                                                        </tr>
                                                        {bookVisit.is_berth_holder ? (
                                                            <tr>
                                                                <td>Is Berth Holder: </td>
                                                                <td>Yes</td>
                                                            </tr>
                                                        ) : ''}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </Col>
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Panel>

                    <Panel header={"Harbour Master Exemptions: " + moment(this.state.selectedDate).format('dddd') + " " + moment(this.state.selectedDate).format("YYYY-MM-DD")} key="6">
                        {/* <FileDoneOutlined style={{ fontSize: 30 }} /> */}

                        <Row >
                            <Col span={24} >
                                {!this.state.exemptions.length ? <Text type="danger">No no harbour master exemptions  available for selected date</Text> : ''}
                                <Row>

                                    {this.state.exemptions.map((exemption, index4) => {
                                        return <Col lg={12} xl={8} xs={24} sm={24} key={index4}>
                                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }} >
                                                <span style={{ position: 'absolute', top: '0px', left: '0px', fontSize: '10px', backgroundColor: '#1890ff', color: '#fff', padding: '2px 3px' }}>{index4 + 1}</span>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>Boat Name: </th>
                                                            <td >{exemption.boat_name ? exemption.boat_name : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th >Contact Name: </th>
                                                            <td >{exemption.contact_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Phone: </th>
                                                            <td>{exemption.phone}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Arrival Lock: </th>
                                                            <td>{exemption.arrival_lock ? exemption.arrival_lock : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Departure Lock: </th>
                                                            <td>{exemption.departure_lock ? exemption.departure_lock : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Exemption Value: </th>
                                                            <td>{exemption.value}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Air Draft: </th>
                                                            <td>{exemption.air_draft}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </Col>
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Panel>

                </Collapse>,
                {/* <Table dataSource={this.state.dataSource} columns={this.state.columns} />; */}

                <Drawer
                    title={'Schedules on ' + moment(this.state.selectedDate).format("YYYY-MM-DD") + (this.state.selectedLockScheduleType == 1 ? ', Arrival Lock @ ' : ' Departure Lock @ ') + this.state.selectedLockSchedule}
                    width={650}
                    onClose={this.closeDrawer}
                    visible={this.state.drawerVisible}
                    maskClosable={true}
                    destroyOnClose>
                    {this.state.lockScheduleBookVisits.length ? (
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <h3 style={{ color: '#1890ff' }}>Book Visits</h3>
                                <Collapse bordered={false} className="site-collapse-custom-collapse ">

                                    {this.state.lockScheduleBookVisits.map((booking, index) => {
                                        return <Panel header={(booking.arriving_from == 'shore' ? booking.arriving_from : (booking.boat_name ? booking.boat_name : ''))
                                            + ' (' + booking.number_of_days + ' days '
                                            + (booking.arrival_date ? (' from: ' + booking.arrival_date) : ' ')
                                            + (booking.departure_date ? ' to: ' + booking.departure_date : ' ') + '), '
                                            + (booking.is_approved && booking.payment_status ? 'Approved' : (booking.is_approved && !booking.payment_status ? 'Pending' : 'Cancelled'))}
                                            key={index} style={{ backgroundColor: '#f8f8f8', borderBottom: '3px solid #fff', color: '#000', padding: 0, margin: 0 }}>

                                            <div style={{ display: 'block', color: '#888', padding: 3, margin: 2, backgroundColor: '#fff' }}>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: '110px' }}>Boat Name: </th>
                                                            <td style={{ minWidth: '160px' }}>{booking.boat_name ? booking.boat_name : 'N/A'}</td>
                                                            <th style={{ width: '115px', paddingLeft: '6px' }}>Contact Name: </th>
                                                            <td style={{ minWidth: '160px' }}>{booking.title} {booking.first_name} {booking.surname}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Phone: </th>
                                                            <td>{booking.phone}</td>
                                                            <th style={{ paddingLeft: '6px' }}>Email: </th>
                                                            <td>{booking.email}</td>
                                                        </tr>
                                                        <tr >
                                                            <th style={{ verticalAlign: 'top' }}>Address: </th>
                                                            <td colSpan={3} >
                                                                {booking.address_line1}, {booking.address_line2}, {booking.address_line3}, {booking.postcode}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4} style={{ height: 15 }}></td>
                                                        </tr>
                                                        <tr>
                                                            <th>Boat Length: </th>
                                                            <td>{booking.boat_length ? (booking.boat_length + ' ' + booking.length_unit) : 'N/A'}</td>
                                                            <th style={{ paddingLeft: '6px' }}>Air Draft: </th>
                                                            <td>{booking.air_draft ? (booking.air_draft + ' ' + booking.air_draft_unit) : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Arrival From: </th>
                                                            <td colSpan={3}>{booking.arriving_from}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Arrival: </th>
                                                            <td>{booking.arrival_date ? booking.arrival_date : 'TBC'}</td>
                                                            <th style={{ paddingLeft: '6px' }}>Arrival Lock: </th>
                                                            <td>{booking.arrival_lock ? booking.arrival_lock : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Departure: </th>
                                                            <td>{booking.departure_date ? booking.departure_date : 'N/A'}</td>
                                                            <th style={{ paddingLeft: '6px' }}>Departure Lock: </th>
                                                            <td>{booking.departure_lock ? booking.departure_lock : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4} style={{ height: 15 }}></td>
                                                        </tr>
                                                        <tr>
                                                            <th>Number of Days: </th>
                                                            <td>{booking.number_of_days}</td>
                                                            <th style={{ paddingLeft: '6px' }}>Home Port: </th>
                                                            <td>{booking.home_port ? booking.home_port : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Cost: </th>
                                                            <td>£ {booking.cost}</td>
                                                            <th style={{ paddingLeft: '6px', verticalAlign: 'top' }}>Payment Status: </th>
                                                            <td >{booking.payment_status  ? (<Text type={booking.payment_status == 'sentForSettlement' ? 'success' : 'danger'}>{booking.payment_status} @ {moment(booking.created_at).format("YYYY-MM-DD")} (Ref No: {booking.transaction_ref})</Text>)
                                                                : <Text type="danger">Not Paid</Text>}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Hull Type: </th>
                                                            <td>{booking.hull_type ? booking.hull_type : 'N/A'}</td>
                                                            <th style={{ paddingLeft: '6px' }}>Vessel Type: </th>
                                                            <td>{booking.vessel_type ? booking.vessel_type : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4} style={{ height: 15 }}></td>
                                                        </tr>
                                                        <tr>
                                                            <th>Booked Status: </th>
                                                            <td>
                                                                {booking.is_approved && booking.payment_status == 'sentForSettlement' ? <Text type="success">Approved</Text> : ( booking.payment_status == 'refused' ? <Text type="danger">Refused</Text>
                                                                    : (booking.is_approved && !booking.payment_status ? <Text type="warning">Pending</Text> : <Text type="danger">Cancelled</Text>))}
                                                            
                                                            </td>
                                                            <th style={{ paddingLeft: '6px' }}>Booked From: </th>
                                                            <td>{booking.submitted_from == 'W' ? 'Web' : 'Mobile App'}</td>
                                                        </tr>
                                                        {booking.is_berth_holder ? (
                                                            <tr>
                                                                <th>Is Berth Holder: </th>
                                                                <td colSpan={3}>Yes</td>
                                                            </tr>
                                                        ) : ''}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </Panel>
                                    })}
                                </Collapse>
                            </Col>



                        </Row>
                    ) : <Text type="danger">There are no book visits yet</Text>}
                    <Divider />

                    {this.state.lockScheduleExemptions.length ? (
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <h3 style={{ color: '#1890ff' }}>Harbour Master Exemptions</h3>
                                <Collapse bordered={false} className="site-collapse-custom-collapse ">

                                    {this.state.lockScheduleExemptions.map((exemption, index) => {
                                        return <Panel header={(exemption.boat_name ? exemption.boat_name + ' - ' : '')
                                            + (exemption.contact_name ? exemption.contact_name + ' - ' : '') + (exemption.value ? exemption.value : '')}
                                            key={index} style={{
                                                backgroundColor: '#f8f8f8', borderBottom: '3px solid #fff', color: '#000', padding: 0, margin: 0
                                            }}>
                                            <div style={{ display: 'block', color: '#888', padding: 3, margin: 2, backgroundColor: '#fff' }}>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th>Boat Name: </th>
                                                            <td >{exemption.boat_name ? exemption.boat_name : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th >Contact Name: </th>
                                                            <td >{exemption.contact_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Phone: </th>
                                                            <td>{exemption.phone}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Arrival Lock: </th>
                                                            <td>{exemption.arrival_lock ? exemption.arrival_lock : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Departure Lock: </th>
                                                            <td>{exemption.departure_lock ? exemption.departure_lock : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Exemption Value: </th>
                                                            <td>{exemption.value}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Air Draft: </th>
                                                            <td>{exemption.air_draft}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </Panel>
                                    })}
                                </Collapse>
                            </Col>
                        </Row>
                    ) : <Text type="danger">There are no harbour master exemptions yet</Text>}

                </Drawer>

                <Drawer
                    title={'Update Actual Tide Information'}
                    width={500}
                    onClose={this.closeActualTideDrawer}
                    visible={this.state.actualTideDrawerVisible}
                    maskClosable={true}
                    destroyOnClose>

                    <Form
                        name="actual-tide-form"
                        layout="horizontal"
                        onFinish={this.saveActualTide}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Form.Item label="Tide Time" name="actual_tide_time"
                                    rules={[{ required: true, message: 'Tide time is required' }]} hasFeedback>
                                    <TimePicker format={'HH:mm'} />
                                </Form.Item>
                                <Form.Item label="Tide Height" name="actual_tide_height"
                                    rules={[{ required: true, message: 'Tide height is required' }]} hasFeedback>
                                    <Select >
                                        {this.state.actualTideHeights.map((height, index) => {
                                            return <Select.Option value={height} key={index}>{height}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                                {/* <Form.Item label="Sigma Set" name="is_sigma_set" >
                                    <Checkbox onChange={this.checkSigmaSet} />
                                </Form.Item>
                                {this.state.isSigmaSet ?
                                    <div>
                                        <h4 style={{ textAlign: 'center' }}>Plymsol Bridge Swing Times</h4>
                                        <Form.Item label="Swing1" style={{ marginBottom: '0px' }}>
                                            <Form.Item name="plymsol_bridge_swing1_open_time"
                                                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                                                <TimePicker placeholder='Open Time' format={'HH:mm'} />
                                            </Form.Item>
                                            <Form.Item name="plymsol_bridge_swing1_close_time"
                                                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                                                <TimePicker placeholder='Close Time' format={'HH:mm'} />
                                            </Form.Item>
                                        </Form.Item>

                                        <Form.Item label="Swing2" style={{ marginBottom: '0px' }}>
                                            <Form.Item name="plymsol_bridge_swing2_open_time"
                                                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                                                <TimePicker placeholder='Open Time' format={'HH:mm'} />
                                            </Form.Item>
                                            <Form.Item name="plymsol_bridge_swing2_close_time"
                                                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                                                <TimePicker placeholder='Close Time' format={'HH:mm'} />
                                            </Form.Item>
                                        </Form.Item>

                                        <Form.Item label="Swing3" style={{ marginBottom: '0px' }}>
                                            <Form.Item name="plymsol_bridge_swing3_open_time"
                                                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                                                <TimePicker placeholder='Open Time' format={'HH:mm'} />
                                            </Form.Item>
                                            <Form.Item name="plymsol_bridge_swing3_close_time"
                                                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                                                <TimePicker placeholder='Close Time' format={'HH:mm'} />
                                            </Form.Item>
                                        </Form.Item>

                                        <h4 style={{ textAlign: 'center' }}>Junction Bridge Swing Times</h4>
                                        <Form.Item label="Swing1" style={{ marginBottom: '0px' }}>
                                            <Form.Item name="junction_bridge_swing1_open_time"
                                                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                                                <TimePicker placeholder='Open Time' format={'HH:mm'} />
                                            </Form.Item>
                                            <Form.Item name="junction_bridge_swing1_close_time"
                                                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                                                <TimePicker placeholder='Close Time' format={'HH:mm'} />
                                            </Form.Item>
                                        </Form.Item>

                                        <Form.Item label="Swing2" style={{ marginBottom: '0px' }}>
                                            <Form.Item name="junction_bridge_swing2_open_time"
                                                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                                                <TimePicker placeholder='Open Time' format={'HH:mm'} />
                                            </Form.Item>
                                            <Form.Item name="junction_bridge_swing2_close_time"
                                                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                                                <TimePicker placeholder='Close Time' format={'HH:mm'} />
                                            </Form.Item>
                                        </Form.Item>

                                        <Form.Item label="Swing3" style={{ marginBottom: '0px' }}>
                                            <Form.Item name="junction_bridge_swing3_open_time"
                                                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                                                <TimePicker placeholder='Open Time' format={'HH:mm'} />
                                            </Form.Item>
                                            <Form.Item name="junction_bridge_swing3_close_time"
                                                style={{ display: 'inline-block', width: 'calc(50% - 5px)' }}>
                                                <TimePicker placeholder='Close Time' format={'HH:mm'} />
                                            </Form.Item>
                                        </Form.Item>
                                    </div>
                                    : ''} */}
                            </Col>
                        </Row>

                        <div style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit" loading={this.state.isSaving}>
                                <SaveOutlined /> Save
                            </Button>
                        </div>
                    </Form>
                </Drawer>

            </div >

        );
    }
}
export default HouseDiary;