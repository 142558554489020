/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';
import { Button, PageHeader, Table } from 'antd';
import { fetchBusinesses } from '../../actions';

function Adverts({ match, fetchAdverts, adverts, loading, history }) {
    useEffect(() => {
        fetchAdverts(match.params.marina);
    }, []);

    const columns = [
        {
            title: '#',
            key: 'id',
            dataIndex: 'id',
            width: '2%',
        },
        {
            title: 'Business Name',
            dataIndex: 'business_name',
            key: 'business_name',
            render: (text, record) => <Link to={`${match.url}/${record.id}`}>{text}</Link>,
        },
        {
            title: 'Business Type',
            key: 'business_type',
            render: (text, record) => record.business_type.name,
        },
        {
            title: 'Contact Name',
            dataIndex: 'contact_name',
            key: 'contact_name',
        },
        {
            title: 'Contact Phone',
            dataIndex: 'contact_phone',
            key: 'contact_phone',
        },
        {
            title: 'Contact Email',
            dataIndex: 'contact_email',
            key: 'contact_email',
        },
    ];

    return (
        <PageHeader
            title="Everything Marina"
            className="site-page-header"
            onBack={() => history.goBack()}
            subTitle=""
            extra={[
                <Button key="1" type="primary">
                    <Link to={`${match.url}/new`}>
                        <PlusOutlined /> Add New Business
                    </Link>
                </Button>,
            ]}
        >
            <Table
                columns={columns}
                dataSource={adverts}
                loading={loading}
                rowKey={(record) => record.id}
                size="small"
                pagination={false}
            />
        </PageHeader>
    );
}

function mapStateToProps(state) {
    return {
        adverts: state.marina.adverts.data,
        loading: state.marina.adverts.isFetching,
    };
}
export default connect(mapStateToProps, { fetchAdverts: fetchBusinesses })(Adverts);
