import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Button, Form, Row, Col, Input, Typography, message, Divider, Modal } from 'antd';
import { UserOutlined, LockOutlined, CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Text } = Typography;
const { confirm } = Modal;

class CancelSwingBooking extends Component {

    constructor(props) {
        super(props);


        this.state = {
            swingBookings: []
        };
        this.getSwingBookings = this.getSwingBookings.bind(this);
        this.cancelSwingBooking = this.cancelSwingBooking.bind(this);
    }

    getSwingBookings(val) {
        axios
            .post('/api/swings/get_swing_booking', val)
            .then(response => {
                if (response.data.length) {
                    this.setState({
                        swingBookings: response.data
                    });
                } else {
                    message.error('There are no bookings found for entered email and code');
                }
            });
    }

    cancelSwingBooking(swingBooking) {
        confirm({
            title: 'Are you sure you want to cancel swing booking?',
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                axios
                    .post('/api/swings/cancel_swing_booking_link', {
                        id: swingBooking.id, email: swingBooking.email,
                        code: swingBooking.unique_code
                    })
                    .then(response => {
                        response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);
                        this.getSwingBookings({ 'email': swingBooking.email, 'unique_code': swingBooking.unique_code })

                    });
            },
            onCancel() {
                // console.log('Cancel');
            },
        });

    }

    componentDidMount() {

    }

    render() {
        return (

            <div>
                {!this.state.swingBookings.length ?
                    <Form
                        name="cancel_swing_booking"
                        className="login-form"
                        // initialValues={{ remember: true }}
                        onFinish={this.getSwingBookings}
                        style={{ paddingTop: '15px' }}
                    >
                        <h2 style={{ textAlign: 'center', lineHeight: '25px', marginTop: '0px', marginBottom: '40px' }}>
                            <img src='img/mhp-logo.png' style={{ height: '80px' }} /><br />
                            <span style={{ color: '#323673', 'fontSize': '32px', verticalAlign: 'bottom' }}>myhome</span>
                            <span style={{ color: '#4FB9E2', 'fontSize': '32px', verticalAlign: 'bottom' }}>port</span>
                        </h2>

                        <Form.Item name="email" rules={[{ required: true, message: 'Please enter email!' }]}>
                            <Input type='email' prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>
                        <Form.Item name="unique_code" rules={[{ required: true, message: 'Please enter cancellation code' }]} >
                            <Input type='password' prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Cancellation Code" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
                                <CheckOutlined /> Verify the Booking
                            </Button>
                        </Form.Item>
                    </Form>
                    :
                    <Row justify="center">
                        <Col lg={10} xl={10} xs={22} sm={18}  >
                            <div style={{ boxShadow: '0 0 100px rgb(0 0 0 / 8%)', verticalAlign: 'center', marginTop: '60px', padding: '15px' }}>

                                <h2 style={{ textAlign: 'center', lineHeight: '22px', marginTop: '0px', marginBottom: '20px' }}>
                                    <img src='img/mhp-logo.png' style={{ height: '80px' }} /><br />
                                    <span style={{ color: '#323673', 'fontSize': '28px', verticalAlign: 'bottom' }}>myhome</span>
                                    <span style={{ color: '#4FB9E2', 'fontSize': '28px', verticalAlign: 'bottom' }}>port</span><br /><br />
                                    <span style={{ color: '#323673', textAlign: 'center', fontSize: '18px', color: '#888' }}>Prince Street Bridge Swing Booking - Cancellation</span>
                                </h2>
                                <Divider />

                                <table style={{ width: '100%', border: 'none', lineHeight: '30px', color: '#788791' }}>
                                    <tbody className='ant-table-tbod'>
                                        <tr>
                                            <td>Vessel Name: </td>
                                            <td>{this.state.swingBookings[0].vessel_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone: </td>
                                            <td>{this.state.swingBookings[0].phone}</td>
                                        </tr>
                                        <tr>
                                            <td>Email: </td>
                                            <td>{this.state.swingBookings[0].email}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table style={{ width: '100%', border: 'none', lineHeight: '30px', color: '#788791', marginTop: '15px' }}>
                                    <thead className='ant-table-thead' >
                                        <tr >
                                            <th style={{ padding: '3px'}}>Date</th>
                                            <th style={{ padding: '3px'}}>Time</th>
                                            <th style={{ padding: '3px'}}>Travel Direction</th>
                                            <th style={{ padding: '3px'}}>Status</th>
                                            <th style={{ padding: '3px'}}></th>
                                        </tr>
                                    </thead>
                                    <tbody className='ant-table-tbody'>
                                        {this.state.swingBookings.map((swingBooking, index) => {
                                            return <tr key={index}>
                                                <td style={{ padding: '3px'}}>{swingBooking.day} </td>
                                                <td style={{ padding: '3px'}}>
                                                    <span style={{ color: swingBooking.is_approved ? '#1890FF' : '#FF4D4F' }}>{swingBooking.opened_time}</span>
                                                </td>
                                                <td style={{ padding: '3px'}}>{swingBooking.travel_direction}</td>
                                                <td style={{ padding: '3px'}}>
                                                    <span>{swingBooking.is_approved ? <Text type="success">Approved</Text>
                                                        : <Text type="danger">Cancelled</Text>}</span>
                                                </td>
                                                <td style={{ padding: '3px'}}>
                                                    {swingBooking.is_approved ?
                                                        <div>
                                                            <Button type='text' danger size='large' style={{ width: '100%' }} onClick={() => this.cancelSwingBooking(swingBooking)} ><CloseOutlined /> Cancel</Button>
                                                        </div>
                                                        : ''}
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>}
            </div>
        );
    }
}


export default CancelSwingBooking;