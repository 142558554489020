import React, { useMemo, useState } from 'react';
import { Menu, Layout } from 'antd';
import { Link } from 'react-router-dom';
import {
    RiseOutlined,
    AccountBookOutlined,
    BarsOutlined,
    ProfileOutlined,
    TeamOutlined,
    NotificationOutlined,
    ClockCircleOutlined,
    FileDoneOutlined,
    ScheduleOutlined,
    CalendarOutlined,
    UsergroupAddOutlined,
    IssuesCloseOutlined,
    FlagOutlined
} from '@ant-design/icons';
import { permissionChecker } from '../../utils/permission';

const { Sider } = Layout;

export default function Sidebar(props) {
    const [collapsed, setCollapsed] = useState(false);

    const {
        auth: { isAuthenticated },
        location: { pathname },
        me: { role, permissions, marina_name: marina, email },
    } = props;
    console.log(props)
    const isBristol = useMemo(() => marina === 'bristol', [marina]);
    const cillHeights = useMemo(() => marina === 'neyland' || marina === 'conwy', [marina]);
    // const isTesting = useMemo(() => email === 'inaamulh@gmail.com', [email]);

    if (!isAuthenticated) {
        return <div />;
    }

    let menuItems = [
        {
            key: 'marina.profile',
            label: 'Profile',
            icon: ProfileOutlined,
            to: '/',
        },
        {
            key: 'users',
            label: 'Users',
            icon: TeamOutlined,
            to: '/users',
        },
        {
            key: 'rates',
            label: 'Rates',
            icon: AccountBookOutlined,
            to: '/rates',
        },
        {
            key: cillHeights ? 'cills' : 'locks',
            label: cillHeights ? 'Cill Heights' : 'Lock Closures',
            icon: BarsOutlined,
            to: cillHeights ? '/cill-heights' : '/locks',
        },
        {
            key: 'tides',
            label: 'Tide Times',
            icon: RiseOutlined,
            to: '/tides',
        },
        {
            key: 'notifications',
            label: 'Notifications',
            icon: NotificationOutlined,
            to: '/notifications',
        },
    ];

    if (isBristol) {
        menuItems = menuItems.concat([
            {
                key: 'swings',
                label: 'PrinceStreetSwings',
                icon: ClockCircleOutlined,
                to: '/swings',
            },
            {
                key: 'book_visits',
                label: 'Booked Visits',
                icon: FileDoneOutlined,
                to: '/book-visits',
            },
            {
                key: 'harbour_master_exemptions',
                label: 'HM Exemptions',
                icon: IssuesCloseOutlined,
                to: '/harbour-master-exemptions',
            },
            {
                key: 'house_diary',
                label: 'Watch House Diary',
                icon: ScheduleOutlined,
                to: '/house-diary',
            },
            {
                key: 'berth_holders',
                label: 'Berth Holders',
                icon: UsergroupAddOutlined,
                to: '/berth-holders',
            },
            {
                key: 'harbour_festivals',
                label: 'Harbour Festivals',
                icon: FlagOutlined,
                to: '/harbour-festivals',
            },
        ]);
    }
    // if (isBristol) {
    //     menuItems = menuItems.concat([
    //         {
    //             key: 'berth_holders',
    //             label: 'Berth Holders',
    //             icon: UsergroupAddOutlined,
    //             to: '/berth-holders',
    //         },
    //     ]);
    // }
    // if (isTesting) {
    //     menuItems = menuItems.concat([
    //         {
    //             key: 'harbour_festivals',
    //             label: 'Harbour Festivals',
    //             icon: FlagOutlined,
    //             to: '/harbour-festivals',
    //         },
    //     ]);
    // }

    let availableMenuItems = menuItems;

    const hasPermission = permissionChecker(role, permissions);
    availableMenuItems = menuItems.filter(
        (m) =>
            hasPermission(`view.${m.key}`) ||
            hasPermission(`add.${m.key}`) ||
            hasPermission(`edit.${m.key}`) ||
            hasPermission(`delete.${m.key}`) ||
            hasPermission(`calculate.${m.key}`),
    );

    let selectedKeys = ['marina.profile'];
    if (/^\/rates.*/.test(pathname)) {
        selectedKeys = ['rates'];
    } else if (/^\/locks.*/.test(pathname)) {
        selectedKeys = ['locks'];
    } else if (/^\/tides.*/.test(pathname)) {
        selectedKeys = ['tides'];
    } else if (/^\/users.*/.test(pathname)) {
        selectedKeys = ['users'];
    } else if (/^\/notifications.*/.test(pathname)) {
        selectedKeys = ['notifications'];
    } else if (/^\/swings.*/.test(pathname)) {
        selectedKeys = ['swings'];
    } else if (/^\/book-visits.*/.test(pathname)) {
        selectedKeys = ['book_visits'];
    } else if (/^\/house-diary.*/.test(pathname)) {
        selectedKeys = ['house_diary'];
    } else if (/^\/berth-holders.*/.test(pathname)) {
        selectedKeys = ['berth_holders'];
    }  else if (/^\/harbour-festivals.*/.test(pathname)) {
        selectedKeys = ['harbour_festivals'];
    } else if (/^\/harbour-master-exemptions.*/.test(pathname)) {
        selectedKeys = ['harbour_master_exemptions'];
    } else if (/^\/cill-heights.*/.test(pathname)) {
        selectedKeys = ['cills'];
    }

    return (
        <Sider id="sider" collapsible collapsed={collapsed} onCollapse={setCollapsed} breakpoint="sm" theme="light">
            <Menu selectedKeys={selectedKeys} style={{ height: '100%', paddingTop: '24px' }} mode="inline">
                {availableMenuItems.map((i) => (
                    <Menu.Item key={i.key} style={{ overflow: 'hidden', whiteSpace: 'normal' }}>
                        <Link to={i.to} style={{ display: 'inline-block', width: '100%' }}>
                            <i.icon style={{ marginRight: 14 }} /> {i.label}
                        </Link>
                    </Menu.Item>
                ))}
            </Menu>
        </Sider>
    );
}
