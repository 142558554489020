import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated ? (
                <Component {...props} {...rest} />
            ) : (
                <Redirect
                    to={{
                        pathname: !isAuthenticated ? '/login' : '/',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

function mapStateToProps(state, ownProps) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        role: state.me.role,
        scope: state.me.scope,
        ...ownProps,
    };
}

export default connect(mapStateToProps)(PrivateRoute);
