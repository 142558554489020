import { VERIFY_CUSTOMER_REQUEST, VERIFY_CUSTOMER_SUCCESS, VERIFY_CUSTOMER_FAILURE } from '../actions';

export const VERIFY_CUSTOMER_INITIAL_STATE = {
    isFetching: false,
    error: null,
};

const verifyCustomerReducers = {
    [VERIFY_CUSTOMER_REQUEST]: (state) => ({ isFetching: true, error: null }),
    [VERIFY_CUSTOMER_SUCCESS]: (state, { res }) => ({
        ...state,
        ...res,
        isFetching: false,
    }),
    [VERIFY_CUSTOMER_FAILURE]: (state, { error: { res } }) => ({
        ...state,
        isFetching: false,
        error: res,
    }),
};

export default function verifyCustomer(state = VERIFY_CUSTOMER_INITIAL_STATE, action) {
    const reducer = verifyCustomerReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
