import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
    Switch,
} from 'react-router-dom';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import PrivateRoute from '../PrivateRoute';
import Layout from '../Layout';
import Sidebar from './Sidebar';
import Profile from './Profile';
import Edit from './Edit';
import Tides from './Tides';
import Users from '../shared/Users';
import User from '../shared/User';
import Locks from './LocksContainer';
import CillHeights from './CillHeightsContainer';
import Rates from './RatesContainer';
import Notifications from './Notifications';
/** hassan edit */
import Swings from './Swings';
import SwingBooking from './SwingBooking';
import BookVisits from './BookVisits'; /** book visits calendar */
import BookVisit from './BookVisit'; /** new book visit */
import HouseDiary from './HouseDiary';
import BerthHolder from './BerthHolder';
import HarbourFestival from './HarbourFestival';
import HarbourMasterExemptions from './HarbourMasterExemptions';
/** end hassan edit */
import { permissionChecker } from '../../utils/permission';


function Root(props) {
    const { me: { role, permissions, marina_name: marina } } = props;

    const isBristol = useMemo(() => marina === 'bristol', [marina]);
    const isNeyland = useMemo(() => marina === 'neyland', [marina]);

    const renderRoutes = ({ match }) => {
        const hasPermission = permissionChecker(role, permissions);
        return (
            <Switch>
                {(
                    hasPermission('view.rates')
                    || hasPermission('calculate.rates')
                    || hasPermission('edit.rates')
                ) ? <PrivateRoute path="/rates" component={Rates} /> : null}
                {hasPermission('view.locks') ? <PrivateRoute path="/locks" component={Locks} /> : null}
                {hasPermission('view.cills') ? <PrivateRoute path="/cill-heights" component={CillHeights} /> : null}
                {hasPermission('view.tides') ? <PrivateRoute path="/tides" component={Tides} /> : null}
                {hasPermission('view.users') ? <PrivateRoute path="/users/:user" component={User} /> : null}
                {hasPermission('view.users') ? <PrivateRoute path="/users" component={Users} /> : null}
                {hasPermission('view.notifications') ? <PrivateRoute path="/notifications" component={Notifications} /> : null}

                {hasPermission('view.swings') && isBristol ? <PrivateRoute path="/swings" component={Swings} /> : null}
                {hasPermission('view.swings') && isBristol ? <PrivateRoute path="/swing-booking" component={SwingBooking} /> : null}
                {hasPermission('view.book_visits') && isBristol ? <PrivateRoute path="/book-visits" component={BookVisits} /> : null}
                {hasPermission('view.book_visits') && isBristol ? <PrivateRoute path="/book-visit" component={BookVisit} /> : null}
                {hasPermission('view.harbour_master_exemptions') && isBristol ? <PrivateRoute path="/harbour-master-exemptions" component={HarbourMasterExemptions} /> : null}
                {hasPermission('view.house_diary') && isBristol ? <PrivateRoute path="/house-diary" component={HouseDiary} /> : null}
                {hasPermission('view.berth_holders') && (isBristol) ? <PrivateRoute path="/berth-holders" component={BerthHolder} /> : null}
                {hasPermission('view.harbour_festivals') && (isBristol) ? <PrivateRoute path="/harbour-festivals" component={HarbourFestival} /> : null}

                {hasPermission('edit.marina.profile') ? <PrivateRoute path="/profile/edit" component={Edit} /> : null}
                {hasPermission('view.marina.profile') ? <PrivateRoute path="/" component={Profile} /> : null}
            </Switch>
        );
    };

    const renderLoading = () => {
        const icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
            }}
            >
                <Spin size="large" tip="LOADING" indicator={icon} />
            </div>
        );
    };

    const { me: { isFetching } } = props;
    const renderSidebar = (props) => <Sidebar {...props} />;

    return (
        <Layout renderSidebar={renderSidebar} renderFooter={false}>
            {isFetching ? renderLoading() : renderRoutes(props)}
        </Layout>
    );
}

function mapStateToProps(state) {
    return {
        me: state.me,
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(Root);
