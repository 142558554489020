import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Button, DatePicker, Form, Row, Col, Input, InputNumber, Select, Option, Divider, Checkbox, Radio, Space, message, Switch, Typography, Collapse } from 'antd';
import { CheckCircleFilled, CheckCircleOutlined, CheckOutlined, ClockCircleFilled, ClockCircleOutlined, CompassFilled, CompassOutlined, DeleteOutlined, FileDoneOutlined, FileMarkdownFilled, MailFilled, MailOutlined, PhoneFilled, PhoneOutlined, PlayCircleFilled, PlayCircleOutlined, RedEnvelopeOutlined, RestOutlined, SaveOutlined, SendOutlined, SlidersOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

const { Text } = Typography;
const { Panel } = Collapse;

class SwingBooking extends Component {

    constructor(props) {
        super(props);

        this.state = {
            swingBooking: {},
            isSaving: false,
            doesSend: false,
            swingTimeSlots: [],
            selectedSwingTimeSlots: [],
            days: [],
            passcode: null,
            formRef: React.createRef(),
            isAgree: false
        };

        this.getDays = this.getDays.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.updateField = this.updateField.bind(this);
        this.sendPasscode = this.sendPasscode.bind(this);
        this.saveSwingBooking = this.saveSwingBooking.bind(this);
        this.getSwingTimeSlots = this.getSwingTimeSlots.bind(this);
        this.addSwingTimeSlot = this.addSwingTimeSlot.bind(this);
        this.checkAgree = this.checkAgree.bind(this);
    }

    checkAgree(e) {
        this.setState({ isAgree: e.target.checked },
            () => {
                console.log(this.state.isAgree + 'mmm')
            })
    }

    updateField(name, e) {
        this.setState({ [name]: e }
            , () => {

            });
    }

    clearSelection() {
        this.setState({ selectedSwingTimeSlots: [] });
    }

    getDays() {
        let startDate = new Date();
        let endDate = moment().add(8, 'days');
        let days = [];
        for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
            days.push(new Date(day));
        }

        this.setState({ days: days });

    }

    getSwingTimeSlots(day) {
        let swingTimeSlots = [];
        this.setState({
            swingTimeSlots: swingTimeSlots,
        })
        return axios.post('/api/swings/get_swing_time_slots', {
            day: moment(day).format("YYYY-MM-DD")
        }).then((
            response
        ) => {
            if (response.data.length) {
                swingTimeSlots = response.data;
            }
            this.setState({
                swingTimeSlots: swingTimeSlots,
            })
        });
    }


    addSwingTimeSlot(stream, slot, day) {
        let selectedSwingTimeSlots = this.state.selectedSwingTimeSlots;
        slot.stream = stream;
        slot.day = day;
        selectedSwingTimeSlots.push(slot);
        this.setState({
            selectedSwingTimeSlots: selectedSwingTimeSlots,
        }, () =>
            console.log(this.state.selectedSwingTimeSlots)
        );
    }

    sendPasscode(val) {
        this.setState({
            doesSend: false,
        });
        if (this.state.selectedSwingTimeSlots.length) {
            let saveData = {
                "email": val.email
            }
            axios
                .post('/api/swings/validate_email', saveData)
                .then(response => {
                    response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);
                    this.setState({
                        doesSend: true,
                    });
                });
        } else {
            message.error("Please select at least one time slot");
        }
    }
    saveSwingBooking(val) {
        if (this.state.selectedSwingTimeSlots.length) {
            let saveData = {
                "vessel_name": val.vessel_name,
                "email": val.email,
                "phone": val.phone,
                "passcode": val.passcode,
                "bookings": []
            }

            for (let i = 0; i < this.state.selectedSwingTimeSlots.length; i++) {
                saveData.bookings.push({
                    "swing_id": this.state.selectedSwingTimeSlots[i].id,
                    "travel_direction": this.state.selectedSwingTimeSlots[i].stream
                });
            }

            this.setState({
                isSaving: true,
            });

            axios
                .post('/api/swings/save_swing_booking', saveData)
                .then(response => {
                    console.log('from handle submit', response);
                    response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                    this.setState({
                        isSaving: false,
                        doesSend: false
                    });
                    this.props.history.push('/swings');
                });
        } else {
            message.error("Please select at least one time slot");
        }
    }

    componentDidMount() {
        this.getDays();
    }

    render() {

        return (

            <div style={{
                marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10
            }}
            >
                <Form
                    name="edit-swing-booking-form"
                    ref={this.state.formRef}
                    layout="horizontal"
                    onFinish={this.state.doesSend ? this.saveSwingBooking : this.sendPasscode}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}  >
                            <h3 style={{ color: '#1890FF', fontSize: '20px', textAlign: 'center' }}>
                                <FileDoneOutlined style={{ fontSize: '30px' }} /><br />
                                Prince Street Swing Booking
                            </h3>
                            <div style={{ color: '#1890FF', fontSize: '16px', margin: '10px 0px', textAlign: 'center' }}>
                                <Text type="danger">Note: If your air draft is less than 2.2 metres you do not  need to book a bridge swing!</Text>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} >
                            <Collapse bordered={false} className="site-collapse-custom-collapse " >
                                {this.state.days.map((day, index) => {
                                    return <Panel header={
                                        <div onClick={() => this.getSwingTimeSlots(day)} style={{ color: '#1890FF' }}>
                                            {moment(day).format("YYYY-MM-DD")}, {moment(day).format("dddd")}
                                        </div>
                                    }
                                        key={index} style={{ backgroundColor: '#fdfdfd', color: '#1890FF', borderBottom: '1px solid #1890FF', padding: '0px', marginBottom: '3px' }}>
                                        {this.state.swingTimeSlots.length ?
                                            <div >
                                                {this.state.swingTimeSlots.map((slot, index2) => {
                                                    return <div key={index2} style={{ height: '30px', backgroundColor: '#f8f8f8', display: 'block', color: '#888', padding: '3px', margin: '3px' }}>
                                                        <Text type={slot.is_opened ? 'success' : 'secondary'}>{slot.opened_time && slot.opened_time.substring(0, 5)} {slot.is_opened ? 'Available' : 'Not Available'}</Text>
                                                        {slot.is_opened ?
                                                            <Radio.Group buttonStyle="solid" size='small' onChange={(e) => this.addSwingTimeSlot(e.target.value, slot, day)}
                                                                style={{ float: 'right', padding: '0px' }}>
                                                                <Radio.Button value="Upstream">Upstream</Radio.Button>
                                                                <Radio.Button value="Downstream">Downstream</Radio.Button>
                                                            </Radio.Group>
                                                            : ''}
                                                    </div>
                                                })}
                                            </div>
                                            : <Text type='danger'>No slots available</Text>}
                                    </Panel>
                                })}
                            </Collapse>
                        </Col>
                        <Col xs={24} sm={12} md={12}  >
                            <div style={{ backgroundColor: '#f8f8f8', borderBottom: '3px solid #fff', color: '#000', padding: 10, marginBottom: '15px' }}>
                                <h4 style={{ color: '#1890FF', fontSize: '16px' }}>Swings</h4>
                                {this.state.selectedSwingTimeSlots.length ?
                                    <div>
                                        <table>
                                            <tbody>
                                                {this.state.selectedSwingTimeSlots.map((slot, index) => {
                                                    return <tr key={index}>
                                                        <td><CheckCircleFilled style={{ color: '#98C41A' }} /></td>
                                                        <td style={{ padding: '3px 10px' }}>{moment(slot.day).format("YYYY-MM-DD")} </td>
                                                        <td style={{ padding: '3px 10px' }}>{slot.stream}</td>
                                                        <td style={{ padding: '3px 10px' }}>{slot.opened_time && slot.opened_time.substring(0, 5)}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                        <Button type='text' danger onClick={this.clearSelection}><DeleteOutlined /> Clear</Button>
                                    </div>
                                    : ''}
                                {!this.state.selectedSwingTimeSlots.length ? <Text style={{ textAlign: 'center' }} type='secondary'> Please select time slots for swing booking</Text> : ''}
                            </div>
                            <div style={{ backgroundColor: '#f8f8f8', borderBottom: '3px solid #fff', color: '#000', padding: 10, marginBottom: '15px' }}>
                                <Form.Item label="Vessel Name" name="vessel_name" rules={[{ required: true, message: 'Vessel name is required' }]} hasFeedback >
                                    <Input placeholder="Please enter vessel name" />
                                </Form.Item>
                                <Form.Item label="Email" name="email" rules={[{ type: 'email', required: true, message: 'Email is required' }]} hasFeedback>
                                    <Input placeholder="Please enter email" />
                                </Form.Item>
                                <Form.Item label="Mobile No" name="phone" rules={[{ required: true, message: 'Mobile no is required' }]} hasFeedback >
                                    <Input placeholder="Please enter mobile no" />
                                </Form.Item>
                            </div>

                            <Divider />

                            {this.state.doesSend ?
                                <div style={{ textAlign: 'justify' }}>
                                    <Form.Item label="Passcode" name="passcode" onChange={(e) => this.updateField("passcode", e.target.value)} 
                                     rules={[{ required: true, message: 'Passcode is required' }]} hasFeedback 
                                     style={{ border: '3px solid #1890FF', padding: '10px' }}>
                                        <Input placeholder="Please enter passcode which sent to your email" />
                                    </Form.Item>
                                    <br />
                                    <br />
                                    <div className="site-button-ghost-wrapper">
                                        <Button disabled={!this.state.passcode} type="primary" htmlType="submit" loading={this.state.isSaving} style={{ width: '100%', height: '40px' }}>
                                            <CheckOutlined /> Confirm Booking
                                        </Button>
                                    </div>
                                </div>
                                :
                                <div style={{ textAlign: 'justify' }}>
                                    <Checkbox onChange={(e) => this.checkAgree(e)} >
                                        <strong>I have read and agree to <a href="https://live.myhomeport.info/privacy_policy" target="blank">Bristol City Councils Privacy Policy</a></strong>
                                    </Checkbox>
                                    <br />
                                    <br />
                                    <div className="site-button-ghost-wrapper">
                                        <Button disabled={!this.state.isAgree} type="primary" htmlType="submit" loading={this.state.doesSend} style={{ width: '100%', height: '40px' }}>
                                            <MailOutlined /> Send Passcode
                                        </Button>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                </Form>

            </div >

        );
    }
}
export default SwingBooking;