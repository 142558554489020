import { AUTH_CUSTOMER_REQUEST, AUTH_CUSTOMER_SUCCESS, AUTH_CUSTOMER_FAILURE } from '../actions';

export const AUTH_CUSTOMER_INITIAL_STATE = {
    isFetching: false,
    error: null,
};

const authCustomerReducers = {
    [AUTH_CUSTOMER_REQUEST]: (state) => ({ isFetching: true, error: null }),
    [AUTH_CUSTOMER_SUCCESS]: (state, { res }) => ({
        ...state,
        ...res,
        isFetching: false,
    }),
    [AUTH_CUSTOMER_FAILURE]: (state, { error: { res } }) => ({
        ...state,
        isFetching: false,
        error: res,
    }),
};

export default function authenticateCustomer(state = AUTH_CUSTOMER_INITIAL_STATE, action) {
    const reducer = authCustomerReducers[action.type] || ((state) => state);
    return reducer(state, action);
}
