import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { PageHeader, Drawer, Button, DatePicker, Form, Row, Col, Input, Select, Option, Divider, Badge, Checkbox, Modal, message, Switch, Typography, Collapse, InputNumber } from 'antd';
import { BoldOutlined, PlusOutlined, SaveOutlined, EyeOutlined, DownloadOutlined, EyeFilled, SearchOutlined, CheckCircleOutlined, CheckOutlined, CloseOutlined, EditOutlined, MobileFilled, MobileOutlined, FlagOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
const { Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;

class HarbourFestival extends Component {

    constructor(props) {
        super(props);
        console.log(props)
        console.log(props.me.marina_name)

        this.state = {
            festivalAppConfig: {},
            clubBerthingInformations: [],
            nonClubBerthingInformations: [],
            bookVisitCounts: [],
            berthHolderBookVisitCount: 0,
            clubBookVisitCount: 0,
            nonClubBookVisitCount: 0,
            berthHolders: [],
            drawerVisible: false,
            isSaving: false,
            isEditAppConfig: false,
            selectedInfo: {},
            isNeyland: props.me.marina_name == 'neyland' ? true : false,
        };


        this.getFestivalAppConfig = this.getFestivalAppConfig.bind(this);
        this.saveFestivalAppConfig = this.saveFestivalAppConfig.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.openDrawer = this.openDrawer.bind(this);
        this.editAppConfig = this.editAppConfig.bind(this);
        this.clickActivate = this.clickActivate.bind(this);
        this.getBerthingInformations = this.getBerthingInformations.bind(this);
        this.saveBerthingInformation = this.saveBerthingInformation.bind(this);
        this.getBerthHolders = this.getBerthHolders.bind(this);
        this.updateBerthHolderStatus = this.updateBerthHolderStatus.bind(this);
        this.getBookVisitCounts = this.getBookVisitCounts.bind(this);

    }

    closeDrawer() {
        this.setState({
            drawerVisible: false,
            selectedInfo: null
        });
    };
    openDrawer(info, type) {
        this.state.selectedInfo = {};
        this.state.selectedInfo.type = type;
        this.state.selectedInfo.is_approved = 1;
        this.setState({
            drawerVisible: true,
            selectedInfo: info ? info : this.state.selectedInfo
        });
    };
    editAppConfig(e) {
        this.setState({
            isEditAppConfig: e,
        });
    };

    updateField(name, e) {
        this.setState({ [name]: e });
    }

    getFestivalAppConfig() {
        return axios.get('/api/festival/get_festival_app_config').then((
            response
        ) => {
            if (response.data) {
                this.setState({
                    festivalAppConfig: response.data
                });
            }
        });
    }

    clickHideScreen(isChecked) {
        // console.log(e)
        // var isChecked = e.target.checked;
        this.state.festivalAppConfig.does_hide_screen = isChecked;
        console.log(isChecked)
        this.setState({
            festivalAppConfig: this.state.festivalAppConfig
        });
    }
    clickHideHarbourFestivalButton(isChecked) {
        this.state.festivalAppConfig.does_hide_harbour_festival_button = isChecked;
        this.setState({
            festivalAppConfig: this.state.festivalAppConfig
        });
    }
    clickPrivateIndividualScreen(isChecked) {
        this.state.festivalAppConfig.does_display_to_private_individual = isChecked;
        this.setState({
            festivalAppConfig: this.state.festivalAppConfig
        });
    }

    saveFestivalAppConfig(val) {
        val.id = this.state.festivalAppConfig.id;
        val.does_hide_screen = this.state.festivalAppConfig.does_hide_screen;
        val.festival_start_date = moment(val.festival_start_date).format("YYYY-MM-DD");
        val.festival_end_date = moment(val.festival_end_date).format("YYYY-MM-DD");
        val.year = moment(val.festival_start_date).format("YYYY");
        this.setState({
            festivalAppConfig: val,
            isSaving: true,
        });
        console.log(val)
        axios
            .post('/api/festival/save_festival_app_config', val)
            .then(response => {
                console.log('from handle submit', response);
                response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                this.setState({
                    isSaving: false,
                    isEditAppConfig: false,
                });
                this.getFestivalAppConfig();
                this.getBerthingInformations();
            });
    }

    getBerthingInformations() {
        let clubBerthingInformations = [];
        let nonClubBerthingInformations = [];
        return axios.get('/api/festival/get_berthing_informations').then((
            response
        ) => {
            if (response.data.length) {
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].type == 1) {
                        clubBerthingInformations.push(response.data[i]);
                    } else {
                        nonClubBerthingInformations.push(response.data[i]);
                    }
                }
                this.setState({
                    clubBerthingInformations: clubBerthingInformations,
                    nonClubBerthingInformations: nonClubBerthingInformations,
                });
            }

        });
    }

    clickActivate(isChecked) {
        this.state.selectedInfo.is_approved = isChecked;
        this.setState({
            selectedInfo: this.state.selectedInfo
        });
    }

    saveBerthingInformation(val) {
        val.id = this.state.selectedInfo.id;
        val.is_approved = this.state.selectedInfo.is_approved;
        val.type = this.state.selectedInfo.type;
        val.year = this.state.festivalAppConfig.year;
        val.member_rate = this.state.selectedInfo.member_rate;
        this.setState({
            selectedInfo: val,
            isSaving: true,
        });
        console.log(val)
        axios
            .post('/api/festival/save_berthing_information', val)
            .then(response => {
                console.log('from handle submit', response);
                response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                this.setState({
                    isSaving: false,
                    drawerVisible: false
                });
                this.getBerthingInformations();
            })
        // .catch(error => {
        //     console.log(error);
        //   });
    }

    getBerthHolders() {
        return axios.get('/api/berth_holder/get_berth_holders').then((
            response
        ) => {
            if (response.data.length) {
                this.setState({
                    berthHolders: response.data
                });
            }
        });
    }

    getBookVisitCounts() {
        return axios.get('/api/festival/count_book_visits').then((
            response
        ) => {
            if (response.data) {
                let berthHolderBookVisitCount = 0;
                let clubBookVisitCount = 0;
                let nonClubBookVisitCount = 0;
                for (let i = 0; i < response.data.length; i++) {
                    switch (response.data[i].visitor_type) {
                        case "1":
                            berthHolderBookVisitCount = response.data[i].total;
                            break;
                        case "2":
                            clubBookVisitCount = response.data[i].total;
                            break;
                        case "3":
                            nonClubBookVisitCount = response.data[i].total;
                            break;
                        default:
                    }
                }
                this.setState({
                    bookVisitCounts: response.data,
                    berthHolderBookVisitCount: berthHolderBookVisitCount,
                    clubBookVisitCount: clubBookVisitCount,
                    nonClubBookVisitCount: nonClubBookVisitCount,
                }, () => {
                    console.log(this.state.berthHolderBookVisitCount + "mmm")
                });
            }
        });
    }

    updateBerthHolderStatus(id, val) {
        if (confirm("Are you sure want to " + (val ? "activate" : "disable") + " berth holder") == true) {
            axios
                .post('/api/berth_holder/update_berth_holder_status', { 'id': id, 'is_approved': val })
                .then(response => {
                    response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);
                    this.getBerthHolders();
                });
        }
    }


    getMemeberRate(e) {
        let selectedInfo = this.state.selectedInfo;
        if (e == '2') {
            selectedInfo.member_rate = this.state.festivalAppConfig.non_club_member_rate;
        } else {
            selectedInfo.member_rate = this.state.festivalAppConfig.club_member_rate;
        }
        this.setState({
            selectedInfo: this.state.selectedInfo
        });
    }

    componentDidMount() {
        this.getFestivalAppConfig();
        this.getBerthingInformations();
        this.getBerthHolders();
        this.getBookVisitCounts();
    }

    render() {
        return (

            <PageHeader
                title="Harbour Festivals"
                className="locks-page-header"
                onBack={() => window.history.back()}>
                <div style={{ float: 'right', marginBottom: 8, position: 'absolute', top: 16, right: 24, }}>
                    {!this.state.isEditAppConfig ?
                        <Button type="primary" onClick={() => this.editAppConfig(true)}>
                            {this.state.festivalAppConfig && this.state.festivalAppConfig.id ? <><EditOutlined /> Edit App Info</> : <><PlusOutlined /> New App Info</>}
                        </Button>
                        :
                        <Button type="text" onClick={() => this.editAppConfig(false)}>
                            <EyeOutlined /> View App Info
                        </Button>
                    }
                </div>

                <div>
                    {this.state.isEditAppConfig ?
                        <>
                            <Form
                                name="berth-holder-form"
                                layout="horizontal"
                                onFinish={this.saveFestivalAppConfig}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{
                                    festival_start_date: this.state.festivalAppConfig.festival_start_date ? moment(this.state.festivalAppConfig.festival_start_date) : '',
                                    festival_end_date: this.state.festivalAppConfig.festival_end_date ? moment(this.state.festivalAppConfig.festival_end_date) : '',
                                    berth_holder_rate: this.state.festivalAppConfig.berth_holder_rate ? this.state.festivalAppConfig.berth_holder_rate : '',
                                    club_member_rate: this.state.festivalAppConfig.club_member_rate ? this.state.festivalAppConfig.club_member_rate : '',
                                    non_club_member_rate: this.state.festivalAppConfig.non_club_member_rate ? this.state.festivalAppConfig.non_club_member_rate : '',
                                    does_hide_screen: this.state.festivalAppConfig.does_hide_screen,
                                    does_display_to_private_individual: this.state.festivalAppConfig.does_display_to_private_individual,
                                    private_individual_message: this.state.festivalAppConfig.private_individual_message
                                }}
                            >
                                <Row gutter={[16, 16]}>

                                    <Col lg={{ span: 14, offset: 5 }} xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} >
                                        <h3 style={{ borderBottom: '1px solid #1890FF', color: '#1890FF', paddingBottom: '6px', textAlign: 'center' }}>
                                            {<MobileOutlined style={{ fontSize: '30px' }} />}<br />
                                            Harbour Festival App Info</h3>
                                        <br />
                                        <div>
                                            <Form.Item label="Festival Start Date" name="festival_start_date"
                                                rules={[{ required: true, message: 'Festival start date is required' }]} hasFeedback>
                                                <DatePicker placeholder="Please select festival start date" format="DD/MM/YYYY" style={{ width: '100%' }} />
                                            </Form.Item>
                                            <Form.Item label="Festival End Date" name="festival_end_date"
                                                rules={[{ required: true, message: 'Festival end date is required' }]} hasFeedback>
                                                <DatePicker placeholder="Please select festival end date" format="DD/MM/YYYY" style={{ width: '100%' }} />
                                            </Form.Item>
                                            <Form.Item label="Berth holder rate (£)" name="berth_holder_rate" rules={[{ required: true, message: 'Berth holder rate is required' }]} hasFeedback>
                                                <InputNumber placeholder="Please enter berth holder rate" style={{ width: '100%' }} />
                                            </Form.Item>
                                            <Form.Item label="Club member rate (£)" name="club_member_rate" rules={[{ required: true, message: 'Club member rate is required' }]} hasFeedback>
                                                <InputNumber placeholder="Please enter club member rate" style={{ width: '100%' }} />
                                            </Form.Item>
                                            <Form.Item label="Non club member rate (£)" name="non_club_member_rate" rules={[{ required: true, message: 'Non club member rate is required' }]} hasFeedback>
                                                <InputNumber placeholder="Please enter non club member rate" style={{ width: '100%' }} />
                                            </Form.Item>
                                            <Form.Item label="Hide Coming Soon Screen" name="does_hide_screen" hasFeedback>
                                                <Switch value={this.state.festivalAppConfig.does_hide_screen} checked={this.state.festivalAppConfig.does_hide_screen}
                                                    onChange={() => { this.clickHideScreen(!this.state.festivalAppConfig.does_hide_screen) }}
                                                    checkedChildren="Yes" unCheckedChildren="No">
                                                </Switch>
                                            </Form.Item>
                                            <Form.Item label="Hide Harbour Festival Button" name="does_hide_harbour_festival_button" hasFeedback>
                                                <Switch value={this.state.festivalAppConfig.does_hide_harbour_festival_button} checked={this.state.festivalAppConfig.does_hide_harbour_festival_button}
                                                    onChange={() => { this.clickHideHarbourFestivalButton(!this.state.festivalAppConfig.does_hide_harbour_festival_button) }}
                                                    checkedChildren="Yes" unCheckedChildren="No">
                                                </Switch>
                                            </Form.Item>
                                            <Form.Item label="Display to Private Individual" name="does_display_to_private_individual" hasFeedback>
                                                <Switch value={this.state.festivalAppConfig.does_display_to_private_individual} checked={this.state.festivalAppConfig.does_display_to_private_individual}
                                                    onChange={() => { this.clickPrivateIndividualScreen(!this.state.festivalAppConfig.does_display_to_private_individual) }}
                                                    checkedChildren="Yes" unCheckedChildren="No">
                                                </Switch>
                                            </Form.Item>
                                            {this.state.festivalAppConfig.does_display_to_private_individual == 0 ?
                                                <Form.Item label="Message" name="private_individual_message" >
                                                    <TextArea placeholder="Please enter message" rows={3} />
                                                </Form.Item>
                                                : ''}
                                        </div>
                                        <br />
                                        <div className="site-button-ghost-wrapper">
                                            <Button type="primary" htmlType="submit" loading={this.state.isSaving} style={{ width: '100%', height: '40px' }}>
                                                <SaveOutlined /> Save
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                        :
                        <Row gutter={[16, 16]}>

                            <Col lg={{ span: 14, offset: 5 }} xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} >
                                <h3 style={{ borderBottom: '1px solid #1890FF', color: '#1890FF', paddingBottom: '6px', textAlign: 'center' }}>
                                    {<FlagOutlined style={{ fontSize: '30px' }} />}<br />
                                    Harbour Festival App Info</h3>
                                {this.state.festivalAppConfig && this.state.festivalAppConfig.id ?
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '240px' }}>Festival Start Date: </td>
                                                <td>{this.state.festivalAppConfig.festival_start_date}</td>
                                            </tr>
                                            <tr>
                                                <td>Festival End Date: </td>
                                                <td>{this.state.festivalAppConfig.festival_end_date}</td>
                                            </tr>
                                            <tr>
                                                <td>Festival Year: </td>
                                                <td>{this.state.festivalAppConfig.year}</td>
                                            </tr>
                                            <tr>
                                                <td>Berth Holder Rate (£): </td>
                                                <td>{this.state.festivalAppConfig.berth_holder_rate}</td>
                                            </tr>
                                            <tr>
                                                <td>Club Member Rate (£): </td>
                                                <td>{this.state.festivalAppConfig.club_member_rate}</td>
                                            </tr>
                                            <tr>
                                                <td>Non Club Member Rate (£): </td>
                                                <td>{this.state.festivalAppConfig.non_club_member_rate}</td>
                                            </tr>
                                            <tr>
                                                <td>Does Hide Coming Soon Screen: </td>
                                                <td>{this.state.festivalAppConfig.does_hide_screen ?
                                                    <Text type="success">Yes</Text> :
                                                    <Text type="danger">No</Text>}</td>
                                            </tr>
                                            <tr>
                                                <td>Does Hide Harbour Festival Button: </td>
                                                <td>{this.state.festivalAppConfig.does_hide_harbour_festival_button ?
                                                    <Text type="success">Yes</Text> :
                                                    <Text type="danger">No</Text>}</td>
                                            </tr>
                                            <tr>
                                                <td>Does Display to Private Individual: </td>
                                                <td>{this.state.festivalAppConfig.does_display_to_private_individual ?
                                                    <Text type="success">Yes</Text> :
                                                    <>
                                                        <Text type="danger">No</Text>
                                                        &nbsp;(<small>{this.state.festivalAppConfig.private_individual_message}</small>)
                                                    </>
                                                }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    : <Text type="danger">There is no harbour festival info updated yet. Please create new.</Text>}
                            </Col>
                        </Row>
                    }

                    <Row gutter={[16, 16]}>
                        <Col lg={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ marginTop: '15px' }}>
                            <h3 style={{ color: '#1890FF', paddingBottom: '6px', textAlign: 'center' }}>
                                Club Berthing Information
                                &nbsp;&nbsp;<Badge count={this.state.clubBookVisitCount ? this.state.clubBookVisitCount : '-'} style={{ backgroundColor: '#52c41a' }} />
                                <Button type="primary" shape="circle" style={{ position: 'absolute', right: '0px', zIndex: 1, height: '30px', width: '30px' }}
                                    onClick={() => this.openDrawer(null, 1)}>
                                    <PlusOutlined />
                                </Button>
                            </h3>
                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }}>
                                {this.state.clubBerthingInformations.length ?
                                    <table style={{ width: '100%' }}>
                                        <thead >
                                            <tr>
                                                <th style={{ paddingTop: '8px', paddingBottom: '8px' }}>#</th>
                                                <th>Visitor</th>
                                                <th>Club Code</th>
                                                <th style={{ textAlign: 'center' }}>Number of Berths Under 30ft</th>
                                                <th style={{ textAlign: 'center' }}>Pontoon Number</th>
                                                <th style={{ textAlign: 'center' }}>Number of Berths Over 30ft</th>
                                                <th style={{ textAlign: 'center' }}>Pontoon Number</th>
                                                <th style={{ textAlign: 'center' }}>Member Rate</th>
                                                <th style={{ textAlign: 'center' }}>Visits</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {this.state.clubBerthingInformations.map((club, index) => {
                                                return <tr key={index} >
                                                    <td style={{ paddingTop: '8px', paddingBottom: '8px' }}>{index + 1}</td>
                                                    <td>{club.club_name}</td>
                                                    <td>{club.code}</td>
                                                    <td style={{ textAlign: 'center' }}>{club.number_of_berth_under}</td>
                                                    <td style={{ textAlign: 'center' }}>{club.pontoon_number1}</td>
                                                    <td style={{ textAlign: 'center' }}>{club.number_of_berth_over}</td>
                                                    <td style={{ textAlign: 'center' }}>{club.pontoon_number2}</td>
                                                    <td style={{ textAlign: 'center' }}>{club.member_rate}</td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <Badge count={club.book_visit_count ? club.book_visit_count : '-'} style={{ backgroundColor: '#F8F8F8', border: '1px solid #52c41a', color: '#52c41a' }} />
                                                    </td>
                                                    <td>
                                                        {club.is_approved ? <Text type="success">Activated</Text> : <Text type="danger">Cancelled</Text>}
                                                    </td>
                                                    <td>
                                                        <Button type="text" onClick={() => this.openDrawer(club, 1)}>
                                                            <EditOutlined />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                    : <Text type="danger">No club berthing information available</Text>}
                            </div>
                        </Col>
                        <Col lg={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ marginTop: '15px' }}>
                            <h3 style={{ color: '#1890FF', paddingBottom: '6px', textAlign: 'center' }}>
                                Non Club Berthing Information
                                &nbsp;&nbsp;<Badge count={this.state.nonClubBookVisitCount ? this.state.nonClubBookVisitCount : '-'} style={{ backgroundColor: '#52c41a' }} />
                                {/* <Button type="primary" shape="circle" style={{ position: 'absolute', right: '0px', zIndex: 1, height: '30px', width: '30px' }}
                                    onClick={() => this.openDrawer(null, 2)}>
                                    <PlusOutlined />
                                </Button> */}
                            </h3>
                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }}>
                                {this.state.nonClubBerthingInformations.length ?
                                    <table style={{ width: '100%' }}>
                                        <thead >
                                            <tr>
                                                <th style={{ paddingTop: '8px', paddingBottom: '8px' }}>#</th>
                                                <th>Visitor</th>
                                                <th style={{ textAlign: 'center' }}>Number of Berths Under 30ft</th>
                                                <th style={{ textAlign: 'center' }}>Pontoon Number</th>
                                                <th style={{ textAlign: 'center' }}>Number of Berths Over 30ft</th>
                                                <th style={{ textAlign: 'center' }}>Pontoon Number</th>
                                                <th style={{ textAlign: 'center' }}>Visits</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {this.state.nonClubBerthingInformations.map((club, index) => {
                                                return <tr key={index} >
                                                    <td style={{ paddingTop: '8px', paddingBottom: '8px' }}>{index + 1}</td>
                                                    <td>{club.club_name}</td>
                                                    <td style={{ textAlign: 'center' }}>{club.number_of_berth_under}</td>
                                                    <td style={{ textAlign: 'center' }}>{club.pontoon_number1}</td>
                                                    <td style={{ textAlign: 'center' }}>{club.number_of_berth_over}</td>
                                                    <td style={{ textAlign: 'center' }}>{club.pontoon_number2}</td>
                                                    <td  style={{ textAlign: 'center' }}>
                                                        <Badge count={club.book_visit_count ? club.book_visit_count : '-'} style={{ backgroundColor: '#F8F8F8', border: '1px solid #52c41a', color: '#52c41a' }} />
                                                    </td>
                                                    <td>
                                                        {club.is_approved ? <Text type="success">Activated</Text> : <Text type="danger">Cancelled</Text>}
                                                    </td>
                                                    <td>
                                                        <Button type="text" onClick={() => this.openDrawer(club, 2)}>
                                                            <EditOutlined />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                    : <Text type="danger">No non club berthing information available</Text>}
                            </div>
                        </Col>


                        {/* {display list of berth holders} */}
                        <Col lg={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ marginTop: '15px' }}>
                            <h3 style={{ color: '#1890FF', paddingBottom: '6px', textAlign: 'center' }}>
                                Berth Holders
                                &nbsp;&nbsp;<Badge count={this.state.berthHolderBookVisitCount ? this.state.berthHolderBookVisitCount : '-'} style={{ backgroundColor: '#52c41a' }} />
                                <Button type="primary" shape="circle" style={{ position: 'absolute', right: '0px', zIndex: 1, height: '30px', width: '30px' }}>
                                    <Link to={"/berth-holders"}><PlusOutlined /></Link>
                                </Button>
                            </h3>
                            <div style={{ paddingTop: 15, backgroundColor: '#f8f8f8', color: 'rgba(0, 0, 0, 0.60)', margin: '5px', padding: '10px' }}>

                                {this.state.berthHolders.length ?
                                    <table style={{ width: '100%' }} size='small'>
                                        <thead >
                                            <tr>
                                                <th style={{ paddingTop: '8px', paddingBottom: '8px' }}>#</th>
                                                <th >Boat Name</th>
                                                <th>Berth Holder</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                {this.state.isNeyland ? (
                                                    <>
                                                        <th style={{ textAlign: 'center' }}>Last Meter Reading (m)</th>
                                                        <th>Reading Date</th>
                                                    </>
                                                ) :
                                                    <th style={{ textAlign: 'center' }}>Air Draft (m)</th>
                                                }
                                                <th style={{ textAlign: 'center' }}>Length (m)</th>
                                                <th>Pin</th>
                                                {!this.state.isNeyland ?
                                                    <th>Licence Expiry</th>
                                                    :
                                                    <>
                                                        <th>Active</th>
                                                        <th></th>
                                                    </>}
                                                    <th  style={{ textAlign: 'center' }}>Visits</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {this.state.berthHolders.map((berthHolder, index) => {
                                                return <tr key={index}>
                                                    <td style={{ paddingTop: '8px', paddingBottom: '8px' }}>{index + 1}</td>
                                                    <td >{berthHolder.boat_name}</td>
                                                    <td>{berthHolder.title}. {berthHolder.first_name} {berthHolder.surname}</td>
                                                    <td style={{ color: '#1890ff' }}>{berthHolder.email}</td>
                                                    <td>{berthHolder.phone}</td>
                                                    {this.state.isNeyland ? (
                                                        <>
                                                            <td style={{ textAlign: 'center' }}>
                                                                {berthHolder.last_metre_reading}
                                                                {berthHolder.last_metre_reading ?
                                                                    <EyeFilled title='View Meter Readings History' style={{ padding: '10px 10px 0px 10px', marginLeft: '5px', fontSize: '18px', color: '#1890ff', cursor: 'pointer' }}
                                                                        onClick={() => this.openMetreReadingsDrawer(berthHolder)} />
                                                                    : ''}
                                                            </td>
                                                            <td>{berthHolder.last_metre_reading_date ? moment(berthHolder.last_metre_reading_date).format('DD/MM/YYYY') : ''}</td>
                                                        </>
                                                    ) :
                                                        <td style={{ textAlign: 'center' }}>{berthHolder.air_draft}</td>
                                                    }
                                                    <td style={{ textAlign: 'center' }}>{berthHolder.length}</td>
                                                    <td>{berthHolder.unique_code}</td>
                                                    {!this.state.isNeyland ?
                                                        <td>{berthHolder.licence_expiry_date ? moment(berthHolder.licence_expiry_date).format('DD/MM/YYYY') : ''}</td>
                                                        :
                                                        <>
                                                            <td>{berthHolder.is_approved == 1 ?
                                                                <Text type='success'>Yes</Text>
                                                                :
                                                                <Text type='danger'>No</Text>}</td>
                                                            <td><Button type="link" onClick={() => this.updateBerthHolderStatus(berthHolder.id, (berthHolder.is_approved == 1 ? 0 : 1))}>
                                                                {berthHolder.is_approved == 1 ?
                                                                    <div ><CloseOutlined /> Disable</div>
                                                                    :
                                                                    <div ><CheckOutlined /> Activate</div>}
                                                            </Button></td>
                                                        </>}
                                                        <td  style={{ textAlign: 'center' }}>
                                                        <Badge count={berthHolder.book_visit_count ? berthHolder.book_visit_count : '-'} style={{ backgroundColor: '#F8F8F8', border: '1px solid #52c41a', color: '#52c41a' }} />
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                    : <Text type="danger">No berth holders available</Text>}
                            </div>
                            {/* {end display list of berth holders} */}
                        </Col>
                    </Row>

                    <Drawer
                        title={'Update Berthing Information'}
                        width={550}
                        onClose={this.closeDrawer}
                        visible={this.state.drawerVisible}
                        maskClosable={true}
                        destroyOnClose>

                        <Form
                            name="berth-holder-form"
                            layout="horizontal"
                            onFinish={this.saveBerthingInformation}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{
                                club_name: this.state.selectedInfo ? this.state.selectedInfo.club_name : '',
                                number_of_berth_under: this.state.selectedInfo ? this.state.selectedInfo.number_of_berth_under : '',
                                pontoon_number1: this.state.selectedInfo ? this.state.selectedInfo.pontoon_number1 : '',
                                number_of_berth_over: this.state.selectedInfo ? this.state.selectedInfo.number_of_berth_over : '',
                                pontoon_number2: this.state.selectedInfo ? this.state.selectedInfo.pontoon_number2 : '',
                                member_rate: this.state.selectedInfo ? this.state.selectedInfo.member_rate : '',
                                rate_type: this.state.selectedInfo ? this.state.selectedInfo.rate_type : '',
                                is_approved: this.state.selectedInfo ? this.state.selectedInfo.is_approved : 1,
                            }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Form.Item label="Club Name" name="club_name"
                                        rules={[{ required: true, message: 'Club name is required' }]} hasFeedback>
                                        <Input placeholder="Please enter club name" />
                                    </Form.Item>
                                    <Form.Item label="No.of Berths Under 30ft" name="number_of_berth_under"
                                        rules={[{ required: true, message: 'This is required' }]} hasFeedback>
                                        <InputNumber placeholder="Please enter number of berths under 30ft" style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item label="Pontoon Number" name="pontoon_number1"
                                        rules={[{ required: true, message: 'Pontoon number is required' }]} hasFeedback>
                                        <Input placeholder="Please enter club name" />
                                    </Form.Item>
                                    <Form.Item label="No.of Berths Over 30ft" name="number_of_berth_over"
                                        rules={[{ required: true, message: 'This is required' }]} hasFeedback>
                                        <InputNumber placeholder="Please enter number of berths over 30ft" style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item label="Pontoon Number" name="pontoon_number2"
                                        rules={[{ required: true, message: 'Pontoon number is required' }]} hasFeedback>
                                        <Input placeholder="Please enter club name" />
                                    </Form.Item>
                                    <Form.Item label="Rate Type" name="rate_type" rules={[{ required: true, message: 'Rate type is required' }]} >
                                        <Select placeholder="Please select rate type" onChange={(e) => this.getMemeberRate(e)}>
                                            <Select.Option value='1' key='0'>Club memebr rate</Select.Option>
                                            <Select.Option value='2' key='1'>Non club memebr rate</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    {this.state.selectedInfo && this.state.selectedInfo.member_rate ?
                                        <Text type="success" style={{ textAlign: 'center', float: 'right' }} >
                                            Memeber rate is {this.state.selectedInfo.member_rate}
                                        </Text>
                                        : ''}
                                    {this.state.selectedInfo ?
                                        <Form.Item label="Status" name="is_approved" hasFeedback>
                                            <Switch value={this.state.selectedInfo.is_approved} checked={this.state.selectedInfo.is_approved}
                                                onChange={() => { this.clickActivate(!this.state.selectedInfo.is_approved) }}
                                                checkedChildren="Active" unCheckedChildren="Cancelled">
                                            </Switch>
                                        </Form.Item>
                                        : ''}
                                </Col>
                            </Row>

                            <div style={{ textAlign: 'right' }}>
                                <Button type="primary" htmlType="submit" >
                                    {/* loading={this.state.isSaving} */}
                                    <SaveOutlined /> Save
                                </Button>
                            </div>
                        </Form>
                    </Drawer>

                </div >
            </PageHeader>
        );
    }
}

function mapStateToProps(state) {
    return {
        me: state.me,
        auth: state.auth,
    };
}
export default connect(mapStateToProps)(HarbourFestival);