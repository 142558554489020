/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Button, Form, Row, Col, Input, message, PageHeader, Switch, Divider, Select,
} from 'antd';
import { SaveOutlined, LockOutlined, SendOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParse from 'dayjs/plugin/customParseFormat';
import {
    updateUser, fetchUser, resendEmail,
} from '../../actions';
import AccessToken from './AccessToken';
import AccessTokens from './AccessTokens';

import useConfirm from './hooks/useConfirm';

const { Option } = Select;

dayjs.extend(customParse);

function EditUser(props) {
    const {
        userId,
        user,
        updateUser,
        fetchUser,
        resend,
        resendEmail,
        history,
        availablePermissions,
        me,
    } = props;
    const {
        id, error, isFetching, permissions, scope, role,
    } = user;
    const [values, setValues] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [addedNew, setAddedNew] = useState(false);
    const [form] = Form.useForm();

    const hasPermissions = () => scope === 'marina' && role === 'user';
    const isHomeportAdmin = () => me.scope === 'homeport' && me.role === 'admin';
    const confirmResend = useConfirm(resend, () => message.success('Signup email sent.'));

    useEffect(() => {
        if (!error) return;

        const { res: { errors: serverErrors, message: msg } } = error;
        message.error(msg);

        if (!serverErrors) return;

        const fields = [];
        Object.keys(serverErrors).forEach((name) => {
            const fieldErrors = serverErrors[name];
            fields.push({
                name,
                value: values[name],
                errors: fieldErrors,
            });
        });
        form.setFields(fields);
    }, [error]);

    useEffect(() => {
        if (!submitted || isFetching) return;
        if (!error) {
            setSubmitted(false);
            form.setFields(['password', 'password_confirmation'].map((name) => ({
                name,
                value: '',
            })));
            message.success('Successfully updated');
        }
    }, [isFetching]);

    useEffect(() => {
        if (!id || id !== userId) {
            fetchUser(userId);
        }
    }, []);

    const getInitialValues = () => ({
        ...user,
        active: !!user.active,
        permissions: hasPermissions() ? permissions.split(',') : [],
    });

    const makeProperCase = (text) => text.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');

    const onFinish = (formValues) => {
        setSubmitted(true);
        setValues(formValues);
        const payload = { ...formValues };
        if (hasPermissions()) {
            payload.permissions = payload.permissions.join(',');
        }

        updateUser(userId, payload);
    };
    const handleResend = (e) => {
        e.preventDefault();

        confirmResend(
            'Do you want to re-send signup email to this user?',
            () => {
                resendEmail(userId);
            },
        );
    };

    if (!user.id || user.id !== userId) return null;

    const CreateForm = (
        <Form
            layout="horizontal"
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            initialValues={getInitialValues()}
            colon={false}
        >
            <Form.Item label="First Name" name="first_name" rules={[{ required: true, message: 'First Name is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                <Input placeholder="Please enter First Name" />
            </Form.Item>
            <Form.Item label="Last Name" name="last_name" rules={[{ required: true, message: 'Last Name is required', whitespace: true }]} normalize={makeProperCase} hasFeedback>
                <Input placeholder="Please enter Last Name" />
            </Form.Item>
            <Form.Item label="Status" name="active" rules={[{ required: true, message: 'Status is required' }]} valuePropName="checked">
                <Switch />
            </Form.Item>
            {hasPermissions() ? (
                <Form.Item
                    hasFeedback
                    name="permissions"
                    label="Permissions"
                    rules={[{
                        required: true, message: 'Permissions are required', whitespace: true, type: 'array',
                    }]}
                >
                    <Select
                        mode="multiple"
                        placeholder="Please select"
                    >
                        {Object.keys(availablePermissions).map((k) => <Option key={k} value={k}>{availablePermissions[k]}</Option>)}
                    </Select>
                </Form.Item>
            ) : null}
            <Form.Item
                label="Email"
                name="email"
            >
                <Input disabled />
            </Form.Item>
            <Divider>Credentials (Leaving it empty would keep it unchanged)</Divider>
            <Form.Item
                label="Password"
                name="password"
                hasFeedback
                rules={[
                    { message: 'At least 8 characters!', min: 8 },
                ]}
            >
                <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Password" />
            </Form.Item>
            <Form.Item
                label="Confirm Password"
                name="password_confirmation"
                dependencies={['password']}
                hasFeedback
                rules={[
                    { message: 'At least 8 characters!', min: 8 },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('Passwords do not match!');
                        },
                    }),
                ]}
            >
                <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Confirm Password" />
            </Form.Item>
            <Button type="primary" htmlType="submit" loading={user.isFetching} style={{ float: 'right' }}>
                {user.isFetching ? null : <SaveOutlined />}
                {' '}
                Update
            </Button>
        </Form>

    );

    const extra = [];
    if (user.can_resend_email) {
        extra.push(
            <Button type="primary" key="1" onClick={handleResend}>
                <SendOutlined />
                {' '}
                Resend Signup Email
            </Button>,
        );
    }

    return (
        <PageHeader
            title="Edit Profile"
            className="site-page-header"
            onBack={() => history.goBack()}
            extra={extra}
        >
            <Row gutter={[24, 24]}>
                <Col xl={12} lg={24}>
                    {CreateForm}
                </Col>
                {isHomeportAdmin() ? (
                    <Col xl={12} lg={24}>
                        <Divider orientation="left" style={{ marginTop: 0 }}>Create New Access Token</Divider>
                        <AccessToken userId={userId} onAddNew={setAddedNew} />
                        <Divider orientation="left">Existing Tokens</Divider>
                        <AccessTokens userId={userId} addedNew={addedNew} />
                    </Col>
                ) : null}
            </Row>
        </PageHeader>
    );
}

const mapStateToProps = (state, ownProps) => {
    const {
        user: {
            profile,
            permissions: { permissions: availablePermissions },
            resendEmail: resend,
        },
        me,
    } = state;
    const { match: { params: { user: userId } } } = ownProps;

    return {
        ...ownProps,
        resend,
        user: profile,
        userId: +userId,
        availablePermissions,
        me,
    };
};

export default connect(mapStateToProps, {
    updateUser, fetchUser, resendEmail,
})(EditUser);
