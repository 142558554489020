import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Drawer, Button, DatePicker, Form, Row, Col, Input, Divider, Checkbox, Radio, Space, message, Switch, Typography, Collapse, TimePicker } from 'antd';
import { BoldOutlined, PlusOutlined, SaveOutlined, EyeOutlined, DownloadOutlined, EditOutlined, ForkOutlined, FormOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Link } from 'react-router-dom';

const localizer = momentLocalizer(moment);
const { Text } = Typography;
const { Panel } = Collapse;

class BookVisits extends Component {

    constructor(props) {
        super(props);

        this.state = {
            day: '',
            name: '',
            bookVisits: [],
            countBookVisits: [],
            events: [],
            drawerVisible: false,
            editVisible: false,
            drawerReportVisible: false,
            selectedDate: new Date(),
            currentDate: new Date(),
            startDate: moment().subtract(1, 'months'),
            endDate: new Date(),
            currentView: 'month',
            displayedDateRange: {},

            drawerEditBookVisitVisible: false,
            isSaving: false,
            selectedBookVisit: null,
            arrivalLockSchedules: [],
            departureLockSchedules: [],
        };

        this.getBookVisits = this.getBookVisits.bind(this);
        this.getCountBookVisits = this.getCountBookVisits.bind(this);

        this.showEdit = this.showEdit.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.openDrawer = this.openDrawer.bind(this);

        this.closeEditBookVisitDrawer = this.closeEditBookVisitDrawer.bind(this);
        this.openEditBookVisitDrawer = this.openEditBookVisitDrawer.bind(this);
        this.saveBookVisit = this.saveBookVisit.bind(this);
        this.cancelBookVisit = this.cancelBookVisit.bind(this);

        this.setCurrentDate = this.setCurrentDate.bind(this);
        this.setCurrentView = this.setCurrentView.bind(this);

        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.downloadBookVisitsReport = this.downloadBookVisitsReport.bind(this);

        this.getTides = this.getTides.bind(this);
        this.getVisitLockScehdules = this.getVisitLockScehdules.bind(this);
        this.splitCapitalizeWord = this.splitCapitalizeWord.bind(this);

    }

    splitCapitalizeWord(val) {
        const arr = val.split("_");
        let text = '';
        for (let i = 0; i < arr.length; i++) {
            text += (arr[i].charAt(0).toUpperCase() + arr[i].slice(1) + ' ');
        }
        return text;
    }

    setStartDate(e) {
        this.setState({
            startDate: e,
        });
    };
    setEndDate(e) {
        this.setState({
            endDate: e,
        });
    };
    downloadBookVisitsReport() {
        return axios.get('/api/book_visit/download_book_visits_report_csv?start_date=' + moment(this.state.startDate).format("YYYY-MM-DD") +
            '&end_date=' + moment(this.state.endDate).format("YYYY-MM-DD"), {
            start_date: moment(this.state.startDate).format("YYYY-MM-DD"),
            end_date: moment(this.state.endDate).format("YYYY-MM-DD")
        }).then((
            response
        ) => {
            if (response.data.length) {

            }
        });
    }

    showEdit(e) {
        e.preventDefault();
        this.setState({
            editVisible: true,
            drawerVisible: true
        });
    };
    closeDrawer() {
        this.setState({
            drawerVisible: false
        });
        if (this.state.editVisible) {
            this.setState({
                editVisible: false,
                drawerVisible: false
            });
        }
    };
    openDrawer(e) {
        this.setState({
            drawerVisible: true,
            selectAll: false,
            selectedDate: moment(e.start).format("YYYY-MM-DD")

        });
        console.log(this.state.selectedDate);
        this.getBookVisits();
    };


    getCountBookVisits() {

        let start = moment(this.state.currentDate).startOf(this.state.currentView);
        let end = moment(this.state.currentDate).endOf(this.state.currentView);
        let group_by = null;
        if (this.state.currentView == 'month') {
            start = start.startOf('week');
            end = end.endOf('week');
        }
        start = start.format("YYYY-MM-DD");
        end = end.format("YYYY-MM-DD");
        group_by = 'mhp_book_visits.vessel_type';
        console.log(this.state.currentDate);
        console.log(start);

        return axios.post('/api/book_visit/count_book_visits', {
            start: start,
            end: end,
            // group_by: group_by,
        }).then((
            response
        ) => {
            let countBookVisits = [];
            let i = 0;

            for (var key in response.data) {
                countBookVisits[i] = {};
                // if (response.data[key]) {
                //     countBookVisits[i].title = '';
                //     for (var x in response.data[key]) {
                //         countBookVisits[i].title += ((response.data[key][x].vessel_type ? (response.data[key][x].vessel_type.substr(0, 3) + ':') : 'NM:') + response.data[key][x].total + ', ');
                //     }
                // } else {
                //     countBookVisits[i].title = '';
                // }
                countBookVisits[i].title = (response.data[key] ? response.data[key] + ' bookings' : '');
                countBookVisits[i].allDay = false;
                countBookVisits[i].start = new Date(key);
                countBookVisits[i].end = new Date(key);
                i++;
            }
            this.setState({
                countBookVisits: [...countBookVisits]
            });
        });
    }

    getBookVisits() {
        let bookVisits = [];
        return axios.post('/api/book_visit/get_book_visits', {
            day: moment(this.state.selectedDate).format("YYYY-MM-DD")
        }).then((
            response
        ) => {
            if (response.data.length) {
                bookVisits = response.data;
            }
            this.setState({
                bookVisits: bookVisits
            });
        });
    }


    closeEditBookVisitDrawer() {
        this.setState({
            drawerEditBookVisitVisible: false,
            selectedBookVisit: null,
            arrivalLockSchedules: [],
            departureLockSchedules: [],
        });
    };
    openEditBookVisitDrawer(bookVisit) {
        this.setState({
            drawerEditBookVisitVisible: true,
            selectedBookVisit: null,
            arrivalLockSchedules: [],
            departureLockSchedules: [],
            selectedBookVisit: bookVisit
        }, () => {
            this.getTides(bookVisit.arrival_date, 1);
            this.getTides(bookVisit.departure_date, 2);
        });
    };

    getTides(date, type) {
        let tides = [];
        return axios.get('/api/house_diary/get_tides?date=' + (moment(date).format("YYYY-MM-DD"))).then((
            response
        ) => {
            if (response.data.length) {
                tides = response.data;
                this.getVisitLockScehdules(tides, type);
            }
        });
    }

    getVisitLockScehdules(tides, type) {
        let lockSchedules = [];
        return axios.post('/api/house_diary/get_book_visit_lock_scehdules', {
            tides: tides,
            does_visit_count: false
        }).then((
            response
        ) => {
            if (response.data.length) {
                lockSchedules = response.data;
            }
            if (type == 1) {
                this.setState({ arrivalLockSchedules: lockSchedules });
            } else {
                this.setState({ departureLockSchedules: lockSchedules });
            }


        });
    }

    saveBookVisit(val) {
        val.id = this.state.selectedBookVisit.id;
        // val.arrival_date = moment(val.arrival_date).format("YYYY-MM-DD");
        // val.arrival_lock = moment(val.arrival_lock).format("HH:mm");
        // val.departure_date = moment(val.departure_date).format("YYYY-MM-DD");
        // val.departure_lock = moment(val.departure_lock).format("HH:mm");

        console.log(val);

        this.setState({
            isSaving: true,
        });

        axios
            .post('/api/book_visit/update_book_visit', val)
            .then(response => {
                console.log('from handle submit', response);
                response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                this.setState({
                    isSaving: false,
                    drawerEditBookVisitVisible: false,
                });
                this.getBookVisits();
                this.getCountBookVisits();
            });
    }

    cancelBookVisit(val) {
        if (confirm('Are you sure you want to cancel booked visit?')) {
            axios
                .post('/api/book_visit/cancel_book_visit', { id: val })
                .then(response => {
                    response.data.status == true ? message.success(response.data.message) : message.error(response.data.message);

                    this.setState({
                        drawerVisible: false,
                    });
                    this.getBookVisits();
                    this.getCountBookVisits();
                });
        }
    }


    eventStyleGetter(event, start, end, isSelected) {
        var style = {
            background: 'rgba(255,255,255,0)',
            borderRadius: '0px',
            // color: '#1890FF',
            color: '#0166bf',
            border: '0px',
            display: 'block',
            fontSize: 12,
            // whiteSpace: 'pre-line',
            whiteSpace: 'pre',
            marginTop: 15,
            cursor: "auto"
        };
        return {
            style: style
        };
    }

    /** for navigation **/
    async setCurrentDate(date) {
        await this.setState({ currentDate: date });
        this.computeDisplayedDateRange();
        this.getCountBookVisits();
    }
    async setCurrentView(view) {
        await this.setState({ currentView: view });
        this.computeDisplayedDateRange();
    }

    computeDisplayedDateRange() {
        const { currentDate, currentView } = this.state;
        let start = moment(currentDate).startOf(currentView);
        let end = moment(currentDate).endOf(currentView);
        if (currentView == 'month') {
            start = start.startOf('week');
            end = end.endOf('week');
        }
        this.setState({ displayedDateRange: { start: start.toString(), end: end.toString() } })
    }
    /** end for navigation **/

    componentDidMount() {
        this.computeDisplayedDateRange();
        this.getCountBookVisits();
    }

    render() {
        const { currentDate, currentView, displayedDateRange } = this.state;
        return (

            <div style={{
                marginTop: 10, marginBottom: 10, paddingLeft: 10, paddingRight: 10
            }}
            >
                <div>
                    <div style={{ position: 'absolute', top: 10, right: 10 }}>
                        <DatePicker value={moment(this.state.startDate)} format="YYYY-MM-DD" onChange={this.setStartDate} />&nbsp;
                        <DatePicker value={moment(this.state.endDate)} format="YYYY-MM-DD" onChange={this.setEndDate} />&nbsp;
                        <Button type="primary" onClick={this.downloadBookVisitsReport}>
                            <a href={"/api/book_visit/download_book_visits_report_csv?start_date=" + moment(this.state.startDate).format("YYYY-MM-DD") + "&end_date=" + moment(this.state.endDate).format("YYYY-MM-DD")}><DownloadOutlined />  Download</a>
                        </Button>&nbsp;
                        <Button type="primary" >
                            <Link to={"/book-visit"}><PlusOutlined />  Book Visit</Link>
                        </Button>
                    </div>
                    <Calendar
                        localizer={localizer}
                        events={this.state.countBookVisits}
                        style={{ height: 600, fontSize: 16, whiteSpace: "pre-line", cursor: "pointer" }}
                        views={['month']}
                        onSelectSlot={this.openDrawer}
                        selectable={true}
                        eventPropGetter={(this.eventStyleGetter)}
                        startAccessor="start"
                        endAccessor="end"
                        date={currentDate}
                        view={currentView}
                        onNavigate={this.setCurrentDate}
                        onView={this.setCurrentView}
                        html={true}

                    />
                </div>

                <Drawer
                    title={'Booked Visits on ' + this.state.selectedDate}
                    width={650}
                    onClose={this.closeDrawer}
                    visible={this.state.drawerVisible}
                    maskClosable={true}
                    destroyOnClose>
                    {this.state.bookVisits.length ? (
                        <Row gutter={[16, 16]}>
                            <Col span={24}>

                                <Collapse bordered={false} className="site-collapse-custom-collapse " >
                                    {this.state.bookVisits.map((booking, index) => {
                                        return <Panel header={(booking.arriving_from == 'shore' ? booking.arriving_from : (booking.boat_name ? booking.boat_name : (booking.berth_holder_boat_name ? booking.berth_holder_boat_name : '')))
                                            + ' (' + booking.number_of_days + ' days '
                                            + (booking.arrival_date ? (' from: ' + booking.arrival_date) : ' ')
                                            + (booking.departure_date ? ' to: ' + booking.departure_date : ' ') + '), '
                                            + (booking.is_approved && booking.payment_status == 'sentForSettlement' ? 'Approved' : (booking.payment_status == 'refused' ? 'Refused' : (booking.is_approved && !booking.payment_status ? 'Pending' : 'Cancelled')))
                                            + (booking.booking_type == 3 ? ' 🚩 ' : '')}
                                            key={index} style={{
                                                backgroundColor: '#f8f8f8', borderBottom: '3px solid #fff', color: '#000', padding: 0, margin: 0,
                                                borderLeft: booking.is_approved ? '3px solid #1890FF' : '3px solid #FF4D4F'
                                            }}>
                                            <div style={{ display: 'block', color: '#888', padding: 3, margin: 2, backgroundColor: '#fff' }}>
                                                <h4 style={{ textAlign: 'center', color: '#888888', fontSize: '15px', borderBottom: '0.5px solid #eee' }}>{booking.booking_type == 3 ? 'Festival' : (booking.booking_type == 2 ? 'Berth Holder' : 'Regular')} Booking</h4>
                                                <table style={{ width: '100%' }}>
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: '110px' }}>Boat Name: </th>
                                                            <td style={{ minWidth: '160px' }}>{booking.boat_name ? booking.boat_name : (booking.berth_holder_boat_name ? booking.berth_holder_boat_name : 'N/A')}</td>
                                                            <th style={{ width: '115px' }}>Contact Name: </th>
                                                            <td style={{ minWidth: '160px' }}>{booking.title} {booking.first_name} {booking.surname}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Phone: </th>
                                                            <td>{booking.phone}</td>
                                                            <th >Email: </th>
                                                            <td>{booking.email}</td>
                                                        </tr>
                                                        <tr >
                                                            <th style={{ verticalAlign: 'top' }}>Address: </th>
                                                            <td colSpan={3} >
                                                                {booking.address_line1 || booking.address_line2 || booking.address_line3 || booking.postcode ?
                                                                    <> {booking.address_line1}, {booking.address_line2}, {booking.address_line3}, {booking.postcode}</>
                                                                    : 'N/A'}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4} style={{ height: 10, borderTop: '0.5px solid #eee' }}></td>
                                                        </tr>
                                                        <tr>
                                                            <th>Boat Length: </th>
                                                            <td>{booking.boat_length ? (booking.boat_length + ' ' + booking.length_unit) : 'N/A'}</td>
                                                            <th >Air Draft: </th>
                                                            <td>{booking.air_draft ? (booking.air_draft + ' ' + (booking.air_draft_unit ? booking.air_draft_unit : 'metre')) : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Hull Type: </th>
                                                            <td>{booking.hull_type ? this.splitCapitalizeWord(booking.hull_type) : 'N/A'}</td>
                                                            <th >Home Port: </th>
                                                            <td>{booking.home_port ? booking.home_port : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Vessel Type: </th>
                                                            <td>{booking.vessel_type ? this.splitCapitalizeWord(booking.vessel_type) : 'N/A'}</td>
                                                            {booking.visitor_type ? (
                                                                <>
                                                                    <th>Visitor Type: </th>
                                                                    <td colSpan={3}>{booking.visitor_type == 1 || booking.is_berth_holder ? 'Berth Holder'
                                                                        : (booking.visitor_type == 2 ? 'Club' : 'Private')}</td>
                                                                </>
                                                            ) : ''}
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4} style={{ height: 10, borderTop: '0.5px solid #eee' }}></td>
                                                        </tr>
                                                        <tr>
                                                            <th>Number of Days: </th>
                                                            <td>{booking.number_of_days}</td>
                                                            <th>Arrival From: </th>
                                                            <td >{booking.arriving_from ? booking.arriving_from : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Arrival: </th>
                                                            <td>{booking.arrival_date ? booking.arrival_date : 'TBC'}</td>
                                                            <th >Arrival Lock: </th>
                                                            <td>{booking.arrival_lock ? booking.arrival_lock : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Departure: </th>
                                                            <td>{booking.departure_date ? booking.departure_date : 'N/A'}</td>
                                                            <th >Departure Lock: </th>
                                                            <td>{booking.departure_lock ? booking.departure_lock : 'N/A'}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Cost: </th>
                                                            <td>£ {booking.cost.toFixed(2)}</td>
                                                            <th>Payment Status: </th>
                                                            <td>{booking.payment_status ? (<Text type={booking.payment_status == 'sentForSettlement' ? 'success' : 'danger'}>{booking.payment_status} @ {moment(booking.created_at).format("YYYY-MM-DD")} (Ref No: {booking.transaction_ref})</Text>)
                                                                : <Text type="danger">Not Paid</Text>}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4} style={{ height: 10, borderTop: '0.5px solid #eee' }}></td>
                                                        </tr>


                                                        <tr>
                                                            <th>Booking Status: </th>
                                                            <td>
                                                                {booking.is_approved && booking.payment_status == 'sentForSettlement' ? <Text type="success">Approved</Text> : (booking.payment_status == 'refused' ? <Text type="danger">Refused</Text>
                                                                    : (booking.is_approved && !booking.payment_status ? <Text type="warning">Pending</Text> : <Text type="danger">Cancelled</Text>))}
                                                            </td>
                                                            <th >Booked From: </th>
                                                            <td>{booking.submitted_from == 'W' ? 'Web' : 'Mobile App'}</td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan={4} style={{ height: 10, borderTop: '0.5px solid #eee' }}></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {booking.insurance_data_type ?
                                                    <>
                                                        {booking.insurance_data_type == 1 ?
                                                            <div style={{ textAlign: 'center' }}>
                                                                <strong >Insurance Details</strong><br />
                                                                <a href={"https://live.myhomeport.info/img/insurance_image/" + booking.insurance_image} target='blank'><img src={"img/insurance_image/" + booking.insurance_image} style={{ width: '150px' }} /></a>
                                                            </div>
                                                            :
                                                            <div >
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th style={{ width: '110px' }}>Insurance Name: </th>
                                                                            <td style={{ width: '160px' }}>{booking.insurance_name}</td>
                                                                            <th style={{ width: '115px' }}>Policy Number: </th>
                                                                            <td style={{ width: '160px' }}>{booking.insurance_policy_number}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>Insurance Expiry Date: </th>
                                                                            <td>{booking.insurance_expire_date}</td>
                                                                            <th>Third Party Cover: </th>
                                                                            <td>{booking.insurance_third_party_cover && !isNaN(booking.insurance_third_party_cover) ? ('£ ' + (parseFloat(booking.insurance_third_party_cover).toFixed(2))) : ''}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        }
                                                    </>
                                                    : ''}

                                                {booking.is_approved ? (
                                                    <div style={{ display: 'block', float: 'right', marginTop: '15px' }}>
                                                        <Button type="text" danger
                                                            icon={<FormOutlined />} onClick={() => this.openEditBookVisitDrawer(booking)} > Edit</Button>
                                                        <Button type="text"
                                                            icon={<CloseOutlined />} onClick={() => this.cancelBookVisit(booking.id)} > Cancel</Button>
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </Panel>
                                    })}
                                </Collapse>
                            </Col>



                        </Row>
                    ) : <Text type="danger">There are no book visits yet</Text>}
                    <Divider />

                </Drawer>

                <Drawer
                    title={'Update Booked Visit Information'}
                    width={500}
                    onClose={this.closeEditBookVisitDrawer}
                    visible={this.state.drawerEditBookVisitVisible}
                    destroyOnClose>

                    <Form
                        name="edit-book-visit-form"
                        layout="horizontal"
                        onFinish={this.saveBookVisit}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{
                            // ["arrival_date"]: moment((this.state.selectedBookVisit ? this.state.selectedBookVisit.arrival_date : ''), 'YYYY-MM-DD'),
                            // ["arrival_lock"]: moment((this.state.selectedBookVisit ? this.state.selectedBookVisit.arrival_lock : '00:00'), 'HH:mm'),
                            // ["departure_date"]: moment((this.state.selectedBookVisit ? this.state.selectedBookVisit.departure_date : ''), 'YYYY-MM-DD'),
                            // ["departure_lock"]: moment((this.state.selectedBookVisit ? this.state.selectedBookVisit.departure_lock : '00:00'), 'HH:mm')
                            ["arrival_lock"]: this.state.selectedBookVisit ? this.state.selectedBookVisit.arrival_lock : '00:00',
                            ["departure_lock"]: this.state.selectedBookVisit ? this.state.selectedBookVisit.departure_lock : '00:00',

                        }}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={24}>

                                <Form.Item label="Arrival Lock" name="arrival_lock">
                                    <Radio.Group>
                                        {this.state.arrivalLockSchedules.map((schedule, index) => {
                                            return <Space direction="vertical" key={index} >
                                                <Radio value={schedule.lock_date1.arrival_time}>{schedule.lock_date1.arrival_time}</Radio>
                                                <Radio value={schedule.lock_date2.arrival_time}>{schedule.lock_date2.arrival_time}</Radio>
                                                <Radio value={schedule.lock_date3.arrival_time}>{schedule.lock_date3.arrival_time}</Radio>
                                            </Space>
                                        })}
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item label="Departure Lock" name="departure_lock">
                                    <Radio.Group>
                                        {this.state.departureLockSchedules.map((schedule, index) => {
                                            return <Space direction="vertical" key={index} >
                                                <Radio value={schedule.lock_date1.departure_time}>{schedule.lock_date1.departure_time}</Radio>
                                                <Radio value={schedule.lock_date2.departure_time}>{schedule.lock_date2.departure_time}</Radio>
                                                <Radio value={schedule.lock_date3.departure_time}>{schedule.lock_date3.departure_time}</Radio>
                                            </Space>
                                        })}
                                    </Radio.Group>
                                </Form.Item>

                                {/* <Form.Item label="Arrival" style={{ marginBottom: 0 }}>
                                    <Form.Item name="arrival_date"
                                        rules={[{ required: true, message: 'Arrival date required' }]}
                                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                    >
                                        <DatePicker format={'YYYY-MM-DD'} />
                                    </Form.Item>
                                    <Form.Item name="arrival_lock"
                                        rules={[{ required: true, message: 'Arrival lock required' }]}
                                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                                    >
                                        <TimePicker format={'HH:mm'} />
                                    </Form.Item>
                                </Form.Item>

                                <Form.Item label="Departure" style={{ marginBottom: 0 }}>
                                    <Form.Item name="departure_date"
                                        rules={[{ required: true, message: 'Departure date required' }]}
                                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                    >
                                        <DatePicker format={'YYYY-MM-DD'} />
                                    </Form.Item>
                                    <Form.Item name="departure_lock"
                                        rules={[{ required: true, message: 'Departure lock required' }]}
                                        style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                                    >
                                        <TimePicker format={'HH:mm'} />
                                    </Form.Item>
                                </Form.Item> */}
                            </Col>
                        </Row>

                        <div style={{ textAlign: 'right' }}>
                            <Button type="primary" htmlType="submit" loading={this.state.isSaving}>
                                <SaveOutlined /> Save
                            </Button>
                        </div>
                    </Form>
                </Drawer>


            </div >

        );
    }
}
export default BookVisits;